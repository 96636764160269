@charset "UTF-8";
/*
    
	=========================
	Template Name 	 : CryptoZone
	Author			 : DexignZone
	Version			 : 1.0
	Author Portfolio : https://themeforest.net/user/dexignzone/portfolio
	=========================
	
	[Table of Contents] 

	=== SCSS Start ===
	
	1. Abstract
        1.1 _abstract.scss
        1.2 _mixins.scss
        1.3 _variable.scss

    2. Bootstrap scss
    
	3. Base
        3.1 _base.scss
        3.2 _custom-grid.scss
        3.3 _fonts.scss
        3.4 _preloader.scss
        3.5 _reset.scss
        3.6 _shortcode.scss

    4. Layout
		4.1 header
			4.1.1 _default.scss
			4.1.2 _header.scss
			
		4.2 banner
			4.2.1 _default.scss
			4.2.2 _banner.scss
			
		4.3 footer
			4.3.1 _default.scss
			4.3.2 _footer.scss
	
    5. Components
        5.1 section-head    
        5.1 _components.scss (all scss file include)

    6. Pages
        6.1 _pages.scss
        6.2 _index.scss
        6.3 _blog-details.scss
		
	=== SCSS End ===
	
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("../icons/font-awesome/css/all.min.css");
:root {
  --gradient: linear-gradient( to right, rgb(255,64,62) 0%, rgb(255,129,27) 100%);
  --gradient-sec: linear-gradient( to right, rgb(57,26,166) 0%, rgb(6,163,245) 100%);
  --primary: #9467FE;
  --secondary: #362465;
  --primary-hover: #7034fe;
  --primary-dark: #3d01cb;
  --rgba-primary-1: rgba(148, 103, 254, 0.1);
  --rgba-primary-2: rgba(148, 103, 254, 0.2);
  --rgba-primary-3: rgba(148, 103, 254, 0.3);
  --rgba-primary-4: rgba(148, 103, 254, 0.4);
  --rgba-primary-5: rgba(148, 103, 254, 0.5);
  --rgba-primary-6: rgba(148, 103, 254, 0.6);
  --rgba-primary-7: rgba(148, 103, 254, 0.7);
  --rgba-primary-8: rgba(148, 103, 254, 0.8);
  --rgba-primary-9: rgba(148, 103, 254, 0.9);
  --body-color: #7E87BF;
  --border-color: #CFDBED;
  --body-bg: #F0F5FF;
  --font-family-base: Poppins, sans-serif;
  --grey: #F4F6FA;
  --bg-image: '';
  --input-bg: #fff;
  --card-bg: #fff;
  --border-radius-base: 8px;
  --font-family-title: Poppins, sans-serif;
  --title: #1C2E9E;
  --headings-font-weight:600;
  --headings-font-weight2:400;
  --headings-font-weight3:300; }

/* Editable */
#loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  overflow: visible;
  background: #f0f5ff url(../images/loader.gif) no-repeat center center; }

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #9467FE;
  --bs-secondary: #362465;
  --bs-success: #11B011;
  --bs-info: #00aeff;
  --bs-warning: #FFBB69;
  --bs-danger: #EB5757;
  --bs-light: #F0EAFF;
  --bs-dark: #212529;
  --bs-primary-rgb: 148, 103, 254;
  --bs-secondary-rgb: 54, 36, 101;
  --bs-success-rgb: 17, 176, 17;
  --bs-info-rgb: 0, 174, 255;
  --bs-warning-rgb: 255, 187, 105;
  --bs-danger-rgb: 235, 87, 87;
  --bs-light-rgb: 240, 234, 255;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 126, 135, 191;
  --bs-body-bg-rgb: 240, 245, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #7E87BF;
  --bs-body-bg: #F0F5FF; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1C2E9E; }

h1, .h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 3rem; } }

h2, .h2 {
  font-size: calc(1.3625rem + 1.35vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.375rem; } }

h3, .h3 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.875rem; } }

h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4, .comment-reply-title,
    .wp-block-search .wp-block-search__label {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #9467FE;
  text-decoration: underline; }
  a:hover {
    color: #7652cb; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.75rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(151, 151, 159, 0.39);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F0F5FF;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #7E87BF;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #7E87BF;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #7E87BF;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #7E87BF;
  vertical-align: top;
  border-color: #CFDBED; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #eae1ff;
  --bs-table-striped-bg: #ded6f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d3cbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d8d0ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d3cbe6; }

.table-secondary {
  --bs-table-bg: #d7d3e0;
  --bs-table-striped-bg: #ccc8d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2beca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7c3cf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2beca; }

.table-success {
  --bs-table-bg: #cfefcf;
  --bs-table-striped-bg: #c5e3c5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bad7ba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfddbf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bad7ba; }

.table-info {
  --bs-table-bg: #ccefff;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e6; }

.table-warning {
  --bs-table-bg: #fff1e1;
  --bs-table-striped-bg: #f2e5d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d9cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdfd0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d9cb; }

.table-danger {
  --bs-table-bg: #fbdddd;
  --bs-table-striped-bg: #eed2d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2c7c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8cccc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2c7c7; }

.table-light {
  --bs-table-bg: #F0EAFF;
  --bs-table-striped-bg: #e4def2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d8d3e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ded8ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d8d3e6; }

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(151, 151, 159, 0.39); }

.form-control, .wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7E87BF;
  background-color: #F0F5FF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .wp-block-categories-dropdown select,
    .wp-block-archives-dropdown select {
      transition: none; } }
  .form-control[type="file"], .wp-block-categories-dropdown select[type="file"],
  .wp-block-archives-dropdown select[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]), .wp-block-categories-dropdown select[type="file"]:not(:disabled):not([readonly]),
    .wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus, .wp-block-categories-dropdown select:focus,
  .wp-block-archives-dropdown select:focus {
    color: #7E87BF;
    background-color: #F0F5FF;
    border-color: #cab3ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }
  .form-control::-webkit-date-and-time-value, .wp-block-categories-dropdown select::-webkit-date-and-time-value,
  .wp-block-archives-dropdown select::-webkit-date-and-time-value {
    height: 1.6em; }
  .form-control::placeholder, .wp-block-categories-dropdown select::placeholder,
  .wp-block-archives-dropdown select::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .wp-block-categories-dropdown select:disabled,
  .wp-block-archives-dropdown select:disabled, .form-control[readonly], .wp-block-categories-dropdown select[readonly],
  .wp-block-archives-dropdown select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button, .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #7E87BF;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button, .wp-block-categories-dropdown select::file-selector-button,
      .wp-block-archives-dropdown select::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button, .wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
  .wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button, .wp-block-categories-dropdown select::-webkit-file-upload-button,
  .wp-block-archives-dropdown select::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #7E87BF;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button, .wp-block-categories-dropdown select::-webkit-file-upload-button,
      .wp-block-archives-dropdown select::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
  .wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #7E87BF;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.6em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.6em;
    border-radius: 0.375rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.6em;
    border-radius: 0.375rem; }

.form-select, select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7E87BF;
  background-color: #F0F5FF;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select, select {
      transition: none; } }
  .form-select:focus, select:focus {
    border-color: #cab3ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }
  .form-select[multiple], select[multiple], .form-select[size]:not([size="1"]), select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled, select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring, select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #7E87BF; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #F0F5FF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #cab3ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }
  .form-check-input:checked {
    background-color: #9467FE;
    border-color: #9467FE; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #9467FE;
    border-color: #9467FE;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23cab3ff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #F0F5FF, 0 0 0 0rem rgba(148, 103, 254, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #F0F5FF, 0 0 0 0rem rgba(148, 103, 254, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #9467FE;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #dfd1ff; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #9467FE;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #dfd1ff; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control, .wp-block-categories-dropdown .form-floating > select,
  .wp-block-archives-dropdown .form-floating > select,
  .form-floating > .form-select,
  .form-floating > select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control, .wp-block-categories-dropdown .form-floating > select,
  .wp-block-archives-dropdown .form-floating > select {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder, .wp-block-categories-dropdown .form-floating > select::placeholder,
    .wp-block-archives-dropdown .form-floating > select::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .wp-block-categories-dropdown .form-floating > select:focus,
    .wp-block-archives-dropdown .form-floating > select:focus, .form-floating > .form-control:not(:placeholder-shown), .wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
    .wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill, .wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
    .wp-block-archives-dropdown .form-floating > select:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  
  .form-floating > .form-select,
  .form-floating > select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label, .wp-block-categories-dropdown .form-floating > select:focus ~ label, .wp-block-archives-dropdown .form-floating > select:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
  .wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label,
  .form-floating > select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label, .wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label, .wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .wp-block-categories-dropdown .input-group > select,
  .wp-block-archives-dropdown .input-group > select,
  .input-group > .form-select,
  .input-group > select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus, .wp-block-categories-dropdown .input-group > select:focus,
  .wp-block-archives-dropdown .input-group > select:focus,
  .input-group > .form-select:focus,
  .input-group > select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7E87BF;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem; }

.input-group-lg > .form-control, .wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.input-group-sm > .form-control, .wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.input-group-sm > .form-select,
.input-group-sm > select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }


.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #11B011; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(17, 176, 17, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .wp-block-categories-dropdown select:valid, .wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid, .wp-block-archives-dropdown .was-validated select:valid, .form-control.is-valid, .wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid {
  border-color: #11B011;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2311B011' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .wp-block-categories-dropdown select:valid:focus, .wp-block-categories-dropdown .was-validated select:valid:focus,
  .was-validated .wp-block-archives-dropdown select:valid:focus, .wp-block-archives-dropdown .was-validated select:valid:focus, .form-control.is-valid:focus, .wp-block-categories-dropdown select.is-valid:focus,
  .wp-block-archives-dropdown select.is-valid:focus {
    border-color: #11B011;
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .form-select:valid, .was-validated select:valid, .form-select.is-valid, select.is-valid {
  border-color: #11B011; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2311B011' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-select:valid:focus, .was-validated select:valid:focus, .form-select.is-valid:focus, select.is-valid:focus {
    border-color: #11B011;
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #11B011; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #11B011; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #11B011; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .was-validated .input-group .wp-block-categories-dropdown select:valid, .wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid, .wp-block-archives-dropdown .was-validated .input-group select:valid, .input-group .form-control.is-valid, .input-group .wp-block-categories-dropdown select.is-valid, .wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid, .wp-block-archives-dropdown .input-group select.is-valid, .was-validated .input-group .form-select:valid, .was-validated .input-group select:valid,
.input-group .form-select.is-valid,
.input-group select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .wp-block-categories-dropdown select:valid:focus, .wp-block-categories-dropdown .was-validated .input-group select:valid:focus,
  .was-validated .input-group .wp-block-archives-dropdown select:valid:focus, .wp-block-archives-dropdown .was-validated .input-group select:valid:focus, .input-group .form-control.is-valid:focus, .input-group .wp-block-categories-dropdown select.is-valid:focus, .wp-block-categories-dropdown .input-group select.is-valid:focus,
  .input-group .wp-block-archives-dropdown select.is-valid:focus, .wp-block-archives-dropdown .input-group select.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .was-validated .input-group select:valid:focus,
  .input-group .form-select.is-valid:focus,
  .input-group select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #EB5757; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(235, 87, 87, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .wp-block-categories-dropdown select:invalid, .wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid, .wp-block-archives-dropdown .was-validated select:invalid, .form-control.is-invalid, .wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid {
  border-color: #EB5757;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23EB5757'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .wp-block-categories-dropdown select:invalid:focus, .wp-block-categories-dropdown .was-validated select:invalid:focus,
  .was-validated .wp-block-archives-dropdown select:invalid:focus, .wp-block-archives-dropdown .was-validated select:invalid:focus, .form-control.is-invalid:focus, .wp-block-categories-dropdown select.is-invalid:focus,
  .wp-block-archives-dropdown select.is-invalid:focus {
    border-color: #EB5757;
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .form-select:invalid, .was-validated select:invalid, .form-select.is-invalid, select.is-invalid {
  border-color: #EB5757; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23EB5757'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .was-validated select:invalid:focus, .form-select.is-invalid:focus, select.is-invalid:focus {
    border-color: #EB5757;
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #EB5757; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #EB5757; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #EB5757; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .was-validated .input-group .wp-block-categories-dropdown select:invalid, .wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid, .wp-block-archives-dropdown .was-validated .input-group select:invalid, .input-group .form-control.is-invalid, .input-group .wp-block-categories-dropdown select.is-invalid, .wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid, .wp-block-archives-dropdown .input-group select.is-invalid, .was-validated .input-group .form-select:invalid, .was-validated .input-group select:invalid,
.input-group .form-select.is-invalid,
.input-group select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .wp-block-categories-dropdown select:invalid:focus, .wp-block-categories-dropdown .was-validated .input-group select:invalid:focus,
  .was-validated .input-group .wp-block-archives-dropdown select:invalid:focus, .wp-block-archives-dropdown .was-validated .input-group select:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .wp-block-categories-dropdown select.is-invalid:focus, .wp-block-categories-dropdown .input-group select.is-invalid:focus,
  .input-group .wp-block-archives-dropdown select.is-invalid:focus, .wp-block-archives-dropdown .input-group select.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .was-validated .input-group select:invalid:focus,
  .input-group .form-select.is-invalid:focus,
  .input-group select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #7E87BF;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #7E87BF; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #000;
  background-color: #9467FE;
  border-color: #9467FE; }
  .btn-primary:hover {
    color: #000;
    background-color: #a47efe;
    border-color: #9f76fe; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #a47efe;
    border-color: #9f76fe;
    box-shadow: 0 0 0 0rem rgba(126, 88, 216, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #a985fe;
    border-color: #9f76fe; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(126, 88, 216, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #9467FE;
    border-color: #9467FE; }

.btn-secondary {
  color: #ffffff;
  background-color: #362465;
  border-color: #362465; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #2e1f56;
    border-color: #2b1d51; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #ffffff;
    background-color: #2e1f56;
    border-color: #2b1d51;
    box-shadow: 0 0 0 0rem rgba(84, 69, 124, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #2b1d51;
    border-color: #291b4c; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(84, 69, 124, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #ffffff;
    background-color: #362465;
    border-color: #362465; }

.btn-success {
  color: #000;
  background-color: #11B011;
  border-color: #11B011; }
  .btn-success:hover {
    color: #000;
    background-color: #35bc35;
    border-color: #29b829; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #35bc35;
    border-color: #29b829;
    box-shadow: 0 0 0 0rem rgba(14, 150, 14, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #41c041;
    border-color: #29b829; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(14, 150, 14, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #11B011;
    border-color: #11B011; }

.btn-info {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff; }
  .btn-info:hover {
    color: #000;
    background-color: #26baff;
    border-color: #1ab6ff; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #26baff;
    border-color: #1ab6ff;
    box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #33beff;
    border-color: #1ab6ff; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff; }

.btn-warning {
  color: #000;
  background-color: #FFBB69;
  border-color: #FFBB69; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffc580;
    border-color: #ffc278; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffc580;
    border-color: #ffc278;
    box-shadow: 0 0 0 0rem rgba(217, 159, 89, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffc987;
    border-color: #ffc278; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 159, 89, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #FFBB69;
    border-color: #FFBB69; }

.btn-danger {
  color: #000;
  background-color: #EB5757;
  border-color: #EB5757; }
  .btn-danger:hover {
    color: #000;
    background-color: #ee7070;
    border-color: #ed6868; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #000;
    background-color: #ee7070;
    border-color: #ed6868;
    box-shadow: 0 0 0 0rem rgba(200, 74, 74, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ef7979;
    border-color: #ed6868; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(200, 74, 74, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #000;
    background-color: #EB5757;
    border-color: #EB5757; }

.btn-light {
  color: #000;
  background-color: #F0EAFF;
  border-color: #F0EAFF; }
  .btn-light:hover {
    color: #000;
    background-color: #f2edff;
    border-color: #f2ecff; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f2edff;
    border-color: #f2ecff;
    box-shadow: 0 0 0 0rem rgba(204, 199, 217, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f3eeff;
    border-color: #f2ecff; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(204, 199, 217, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #F0EAFF;
    border-color: #F0EAFF; }

.btn-dark {
  color: #ffffff;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #ffffff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1a1e21;
    border-color: #191c1f; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529; }

.btn-outline-primary {
  color: #9467FE;
  border-color: #9467FE; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #9467FE;
    border-color: #9467FE; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #9467FE;
    border-color: #9467FE; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #9467FE;
    background-color: transparent; }

.btn-outline-secondary {
  color: #362465;
  border-color: #362465; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #362465;
    border-color: #362465; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0rem rgba(54, 36, 101, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #362465;
    border-color: #362465; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(54, 36, 101, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #362465;
    background-color: transparent; }

.btn-outline-success {
  color: #11B011;
  border-color: #11B011; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #11B011;
    border-color: #11B011; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #11B011;
    border-color: #11B011; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #11B011;
    background-color: transparent; }

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #00aeff;
    background-color: transparent; }

.btn-outline-warning {
  color: #FFBB69;
  border-color: #FFBB69; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #FFBB69;
    border-color: #FFBB69; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0rem rgba(255, 187, 105, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #FFBB69;
    border-color: #FFBB69; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 187, 105, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #FFBB69;
    background-color: transparent; }

.btn-outline-danger {
  color: #EB5757;
  border-color: #EB5757; }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #EB5757;
    border-color: #EB5757; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #EB5757;
    border-color: #EB5757; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #EB5757;
    background-color: transparent; }

.btn-outline-light {
  color: #F0EAFF;
  border-color: #F0EAFF; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #F0EAFF;
    border-color: #F0EAFF; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0rem rgba(240, 234, 255, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #F0EAFF;
    border-color: #F0EAFF; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(240, 234, 255, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #F0EAFF;
    background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #9467FE;
  text-decoration: underline; }
  .btn-link:hover {
    color: #7652cb; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #7E87BF;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #9467FE; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #ffffff;
      background-color: #9467FE; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #9467FE;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #7652cb; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #F0F5FF;
    border-color: #dee2e6 #dee2e6 #F0F5FF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #9467FE; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.375rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.375rem - 1px);
      border-bottom-left-radius: calc(0.375rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px); }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #7E87BF;
  text-align: left;
  background-color: #F0F5FF;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #855de5;
    background-color: #f4f0ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23855de5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237E87BF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #cab3ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #F0F5FF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.375rem - 1px);
      border-bottom-left-radius: calc(0.375rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #9467FE;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #7652cb;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #7652cb;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #9467FE;
  border-color: #9467FE; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #593e98;
  background-color: #eae1ff;
  border-color: #dfd1ff; }
  .alert-primary .alert-link {
    color: #47327a; }

.alert-secondary {
  color: #20163d;
  background-color: #d7d3e0;
  border-color: #c3bdd1; }
  .alert-secondary .alert-link {
    color: #1a1231; }

.alert-success {
  color: #0a6a0a;
  background-color: #cfefcf;
  border-color: #b8e7b8; }
  .alert-success .alert-link {
    color: #085508; }

.alert-info {
  color: #006899;
  background-color: #ccefff;
  border-color: #b3e7ff; }
  .alert-info .alert-link {
    color: #00537a; }

.alert-warning {
  color: #664b2a;
  background-color: #fff1e1;
  border-color: #ffebd2; }
  .alert-warning .alert-link {
    color: #523c22; }

.alert-danger {
  color: #8d3434;
  background-color: #fbdddd;
  border-color: #f9cdcd; }
  .alert-danger .alert-link {
    color: #712a2a; }

.alert-light {
  color: #605e66;
  background-color: #fcfbff;
  border-color: #fbf9ff; }
  .alert-light .alert-link {
    color: #4d4b52; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.375rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #9467FE;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #7E87BF;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #9467FE;
    border-color: #9467FE; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #593e98;
  background-color: #eae1ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #593e98;
    background-color: #d3cbe6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #593e98;
    border-color: #593e98; }

.list-group-item-secondary {
  color: #20163d;
  background-color: #d7d3e0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #20163d;
    background-color: #c2beca; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #20163d;
    border-color: #20163d; }

.list-group-item-success {
  color: #0a6a0a;
  background-color: #cfefcf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0a6a0a;
    background-color: #bad7ba; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #0a6a0a;
    border-color: #0a6a0a; }

.list-group-item-info {
  color: #006899;
  background-color: #ccefff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006899;
    background-color: #b8d7e6; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #006899;
    border-color: #006899; }

.list-group-item-warning {
  color: #664b2a;
  background-color: #fff1e1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664b2a;
    background-color: #e6d9cb; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #664b2a;
    border-color: #664b2a; }

.list-group-item-danger {
  color: #8d3434;
  background-color: #fbdddd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #8d3434;
    background-color: #e2c7c7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #8d3434;
    border-color: #8d3434; }

.list-group-item-light {
  color: #605e66;
  background-color: #fcfbff; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #605e66;
    background-color: #e3e2e6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #605e66;
    border-color: #605e66; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(148, 103, 254, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 15px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #CFDBED;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #CFDBED;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ffffff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1C2E9E;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #7E87BF; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #9467FE; }
  .link-primary:hover, .link-primary:focus {
    color: #a985fe; }

.link-secondary {
  color: #362465; }
  .link-secondary:hover, .link-secondary:focus {
    color: #2b1d51; }

.link-success {
  color: #11B011; }
  .link-success:hover, .link-success:focus {
    color: #41c041; }

.link-info {
  color: #00aeff; }
  .link-info:hover, .link-info:focus {
    color: #33beff; }

.link-warning {
  color: #FFBB69; }
  .link-warning:hover, .link-warning:focus {
    color: #ffc987; }

.link-danger {
  color: #EB5757; }
  .link-danger:hover, .link-danger:focus {
    color: #ef7979; }

.link-light {
  color: #F0EAFF; }
  .link-light:hover, .link-light:focus {
    color: #f3eeff; }

.link-dark {
  color: #212529; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #CFDBED !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #CFDBED !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #CFDBED !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #CFDBED !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #CFDBED !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #9467FE !important; }

.border-secondary {
  border-color: #362465 !important; }

.border-success {
  border-color: #11B011 !important; }

.border-info {
  border-color: #00aeff !important; }

.border-warning {
  border-color: #FFBB69 !important; }

.border-danger {
  border-color: #EB5757 !important; }

.border-light {
  border-color: #F0EAFF !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #ffffff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.425rem + 2.1vw) !important; }

.fs-2 {
  font-size: calc(1.3625rem + 1.35vw) !important; }

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1.125rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.6 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(151, 151, 159, 0.39) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.75rem !important; }

.rounded-2 {
  border-radius: 0.375rem !important; }

.rounded-3 {
  border-radius: 1rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important; }
  .fs-2 {
    font-size: 2.375rem !important; }
  .fs-3 {
    font-size: 1.875rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body {
  line-height: 1.6;
  background-color: var(--body-bg);
  color: var(--body-color);
  font-size: 1rem;
  font-family: var(--font-family-base); }
  body.fixed {
    overflow: hidden;
    position: fixed; }
  @media only screen and (max-width: 1280px) {
    body {
      font-size: 15px; } }

h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.comment-reply-title a,
.wp-block-search .wp-block-search__label a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title); }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label, h5, .h5, h6, .h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-family-title);
  font-weight: 600;
  color: var(--title);
  line-height: 1.4; }

h1, .h1,
.h1 {
  font-size: 3rem; }
  @media only screen and (max-width: 1680px) {
    h1, .h1,
    .h1 {
      font-size: 3rem; } }
  @media only screen and (max-width: 1280px) {
    h1, .h1,
    .h1 {
      font-size: 2.813rem; } }
  @media only screen and (max-width: 767px) {
    h1, .h1,
    .h1 {
      font-size: 2.625rem; } }
  @media only screen and (max-width: 575px) {
    h1, .h1,
    .h1 {
      font-size: 2.25rem; } }

h2, .h2,
.h2 {
  font-size: 2.375rem; }
  @media only screen and (max-width: 1680px) {
    h2, .h2,
    .h2 {
      font-size: 2.625rem; } }
  @media only screen and (max-width: 1280px) {
    h2, .h2,
    .h2 {
      font-size: 2.375rem; } }
  @media only screen and (max-width: 767px) {
    h2, .h2,
    .h2 {
      font-size: 2rem; } }
  @media only screen and (max-width: 575px) {
    h2, .h2,
    .h2 {
      font-size: 1.65rem; } }

h3, .h3,
.h3 {
  font-size: 1.875rem; }
  @media only screen and (max-width: 1680px) {
    h3, .h3,
    .h3 {
      font-size: 2rem; } }
  @media only screen and (max-width: 1280px) {
    h3, .h3,
    .h3 {
      font-size: 1.875rem; } }
  @media only screen and (max-width: 767px) {
    h3, .h3,
    .h3 {
      font-size: 1.625rem; } }
  @media only screen and (max-width: 575px) {
    h3, .h3,
    .h3 {
      font-size: 1.225rem; } }

h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label,
.h4 {
  font-size: 1.5rem; }
  @media only screen and (max-width: 767px) {
    h4, .h4, .comment-reply-title,
    .wp-block-search .wp-block-search__label,
    .h4 {
      font-size: 1.25rem; } }

h5, .h5,
.h5 {
  font-size: 1.25rem; }
  @media only screen and (max-width: 767px) {
    h5, .h5,
    .h5 {
      font-size: 1.125rem; } }

h6, .h6,
.h6 {
  font-size: 1.125rem; }
  @media only screen and (max-width: 767px) {
    h6, .h6,
    .h6 {
      font-size: 1rem; } }

ul {
  padding: 0;
  margin: 0; }

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

li {
  list-style: none; }

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none;
    color: var(--primary-hover); }

.text-primary {
  color: var(--primary) !important; }

.text-secondary {
  color: var(--secondary) !important; }

.bg-primary {
  background-color: var(--primary) !important; }

.bg-secondary {
  background-color: var(--secondary) !important; }

.bg-blue {
  background-color: #060340 !important; }

b,
strong,
.strong {
  font-weight: 700; }

.h-0 {
  height: 0; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px; }
  #main-wrapper.show {
    opacity: 1; }

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 100px;
  padding-right: 100px; }
  @media only screen and (max-width: 1480px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (max-width: 1280px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 575px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 15px;
      padding-right: 15px; } }

.bg-grey {
  background-color: var(--grey); }

.text-black {
  color: #071c35; }

::selection {
  color: #ffffff;
  background: var(--primary); }

.modal-backdrop {
  z-index: 99999; }

.modal {
  z-index: 100000; }

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon"; }

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px; } }

.gradient {
  background-image: var(--gradient); }

.gradient-sec {
  background-image: var(--gradient-sec); }

.content-inner {
  padding-top: 100px;
  padding-bottom: 70px; }
  @media only screen and (max-width: 1480px) {
    .content-inner {
      padding-top: 70px;
      padding-bottom: 50px; } }
  @media only screen and (max-width: 575px) {
    .content-inner {
      padding-top: 50px;
      padding-bottom: 30px; } }

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 991px) {
    .content-inner-1 {
      padding-top: 50px;
      padding-bottom: 20px; } }

.content-inner-2 {
  padding-top: 100px;
  background: #F0EAFF !important; }
  @media only screen and (max-width: 1480px) {
    .content-inner-2 {
      padding-top: 70px; } }

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px; }
  @media only screen and (max-width: 991px) {
    .content-inner-3 {
      padding-top: 50px;
      padding-bottom: 20px; } }

.mfp-bg {
  z-index: 99999; }

.mfp-wrap {
  z-index: 100000; }

.rounded {
  border-radius: var(--border-radius-base) !important; }

.height-md {
  height: 370px; }

.height-lg {
  height: 600px; }
  @media only screen and (max-width: 575px) {
    .height-lg {
      height: 400px; } }

.border-primary {
  border-color: var(--primary) !important; }

.border-bottom {
  border-bottom: 1px solid #eee !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.bg-light {
  background-color: #F8F5FF !important; }

.preloader-wrapper-1,
.preloader-wrapper-2 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s; }
  .preloader-wrapper-1 .preloader-inner,
  .preloader-wrapper-2 .preloader-inner {
    position: relative; }

.preloader-wrapper-1 .preloader-inner {
  height: 100px;
  width: 150px;
  padding: 5px;
  border-radius: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), 0 0 0 5px var(--primary) inset;
  perspective: 37.5rem;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  animation: pre_ani_cover_1 7s ease-in-out infinite; }
  .preloader-wrapper-1 .preloader-inner .preloader-shade,
  .preloader-wrapper-1 .preloader-inner .preloader-wrap {
    width: calc(50% - 0.25rem);
    position: absolute;
    left: 0.25rem;
    animation: pre_ani_cover_1 7s ease-in-out infinite; }
  .preloader-wrapper-1 .preloader-inner .preloader-shade {
    background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 50%);
    height: 3.75rem;
    filter: blur(0.25rem);
    transform: scaleY(0);
    transform-origin: 100% 0%;
    top: calc(100% - 0.25rem);
    animation-name: pre_ani_shadow_1; }
  .preloader-wrapper-1 .preloader-inner .preloader-wrap {
    background-color: #fff;
    background-image: linear-gradient(90deg, rgba(227, 228, 232, 0) 87.5%, #e3e4e8);
    height: calc(100% - 0.5rem);
    transform-origin: 100% 50%;
    animation-name: pre_ani_wrap_1; }
  .preloader-wrapper-1 .preloader-inner .wrap2,
  .preloader-wrapper-1 .preloader-inner .wrap3,
  .preloader-wrapper-1 .preloader-inner .wrap4 {
    background-image: repeating-linear-gradient(#17181c 0 0.125rem, rgba(23, 24, 28, 0) 0.125rem 0.5rem), linear-gradient(90deg, rgba(227, 228, 232, 0) 87.5%, #e3e4e8);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 4.125rem, 100% 100%; }
  .preloader-wrapper-1 .preloader-inner .wrap2 {
    animation-name: pre_ani_wrap_2; }
  .preloader-wrapper-1 .preloader-inner .wrap3 {
    animation-name: pre_ani_wrap_3; }
  .preloader-wrapper-1 .preloader-inner .wrap4 {
    animation-name: pre_ani_wrap_4; }
  .preloader-wrapper-1 .preloader-inner .wrap5 {
    animation-name: pre_ani_wrap_5; }

@keyframes pre_ani_cover_1 {
  from, 5%, 45%, 55%, 95%, to {
    background-color: var(--rgba-primary-6);
    animation-timing-function: ease-out; }
  10%, 40%, 60%, 90% {
    background-color: var(--rgba-primary-6);
    animation-timing-function: ease-in; } }

@keyframes pre_ani_shadow_1 {
  from, 10.01%, 20.01%, 30.01%, 40.01% {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    animation-timing-function: ease-in; }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translate3d(0, 0, 1px) scaleY(0.2) rotateY(90deg);
    animation-timing-function: ease-out; }
  10%, 20%, 30%, 40%, 50%, to {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    animation-timing-function: ease-out; }
  50.01%, 60.01%, 70.01%, 80.01%, 90.01% {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    animation-timing-function: ease-in; }
  60%, 70%, 80%, 90%, to {
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    animation-timing-function: ease-out; } }

@keyframes pre_ani_wrap_1 {
  from, to {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.4deg);
    animation-timing-function: ease-in-out; }
  10%, 15% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-out; }
  20%, 80% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-in; }
  85%, 90% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(180deg);
    animation-timing-function: ease-in-out; } }

@keyframes pre_ani_wrap_2 {
  from, to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-in; }
  5%, 10% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-in-out; }
  20%, 25% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-out; }
  30%, 70% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-in; }
  75%, 80% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    animation-timing-function: ease-in-out; }
  90%, 95% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    animation-timing-function: ease-out; } }

@keyframes pre_ani_wrap_3 {
  from, 10%, 90%, to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-in; }
  15%, 20% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-in-out; }
  30%, 35% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-out; }
  40%, 60% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-in; }
  65%, 70% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    animation-timing-function: ease-in-out; }
  80%, 85% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    animation-timing-function: ease-out; } }

@keyframes pre_ani_wrap_4 {
  from, 20%, 80%, to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-in; }
  25%, 30% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-in-out; }
  40%, 45% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-out; }
  50% {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-in; }
  55%, 60% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    animation-timing-function: ease-in-out; }
  70%, 75% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    animation-timing-function: ease-out; } }

@keyframes pre_ani_wrap_5 {
  from, 30%, 70%, to {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, 0, 1px) rotateY(0);
    animation-timing-function: ease-in; }
  35%, 40% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0deg);
    animation-timing-function: ease-in-out; }
  50% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(179.6deg);
    animation-timing-function: ease-in-out; }
  60%, 65% {
    background-color: #fff;
    transform: translate3d(0, 0, 1px) rotateY(0);
    animation-timing-function: ease-out; } }

.preloader-wrapper-2 .preloader-inner {
  width: 110px;
  height: 90px;
  margin: 50px auto 0; }
  .preloader-wrapper-2 .preloader-inner span {
    background: var(--primary);
    width: 5px;
    height: 80px;
    border-radius: 10px;
    transform: translateY(-50%) rotate(0);
    position: absolute;
    top: 50%;
    animation: pre_ani_2 0.7s infinite alternate-reverse; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(1) {
      margin-left: 0px; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(2) {
      margin-left: 14px;
      animation-delay: 0.1s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(3) {
      margin-left: 28px;
      animation-delay: 0.2s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(4) {
      margin-left: 42px;
      animation-delay: 0.3s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(5) {
      margin-left: 56px;
      animation-delay: 0.4s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(6) {
      margin-left: 70px;
      animation-delay: 0.5s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(7) {
      margin-left: 84px;
      animation-delay: 0.6s; }
    .preloader-wrapper-2 .preloader-inner span:nth-child(8) {
      margin-left: 98px;
      animation-delay: 0.7s; }

@keyframes pre_ani_2 {
  0% {
    height: 5px;
    transform: translateY(-50%) rotate(40deg); }
  100% {
    height: 90px;
    transform: translateY(-50%) rotate(0deg); } }

.font-12 {
  font-size: 12px; }

.font-13 {
  font-size: 13px; }

.font-14 {
  font-size: 14px; }

.font-15 {
  font-size: 15px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px; }

.font-20 {
  font-size: 20px; }

.font-22 {
  font-size: 22px; }

.font-24 {
  font-size: 24px; }

.font-26 {
  font-size: 26px; }

.font-0 {
  font-size: 0px !important; }

.font-5 {
  font-size: 5px !important; }

.font-10 {
  font-size: 10px !important; }

.font-15 {
  font-size: 15px !important; }

.font-20 {
  font-size: 20px !important; }

.font-25 {
  font-size: 25px !important; }

.font-30 {
  font-size: 30px !important; }

.font-35 {
  font-size: 35px !important; }

.font-40 {
  font-size: 40px !important; }

.font-45 {
  font-size: 45px !important; }

.font-50 {
  font-size: 50px !important; }

.font-55 {
  font-size: 55px !important; }

.font-60 {
  font-size: 60px !important; }

.font-65 {
  font-size: 65px !important; }

.font-70 {
  font-size: 70px !important; }

.font-75 {
  font-size: 75px !important; }

.font-80 {
  font-size: 80px !important; }

.font-85 {
  font-size: 85px !important; }

.font-90 {
  font-size: 90px !important; }

.font-95 {
  font-size: 95px !important; }

.font-100 {
  font-size: 100px !important; }

.font-w0 {
  font-weight: 1 !important; }

.font-w100 {
  font-weight: 101 !important; }

.font-w200 {
  font-weight: 201 !important; }

.font-w300 {
  font-weight: 301 !important; }

.font-w400 {
  font-weight: 401 !important; }

.font-w500 {
  font-weight: 501 !important; }

.font-w600 {
  font-weight: 601 !important; }

.font-w700 {
  font-weight: 701 !important; }

.font-w800 {
  font-weight: 801 !important; }

.font-w900 {
  font-weight: 901 !important; }

.p-a0 {
  padding: 0px; }

.p-l0 {
  padding-left: 0px; }

.p-r0 {
  padding-right: 0px; }

.p-t0 {
  padding-top: 0px; }

.p-b0 {
  padding-bottom: 0px; }

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px; }

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px; }

.m-a0 {
  margin: 0px; }

.m-l0 {
  margin-left: 0px; }

.m-r0 {
  margin-right: 0px; }

.m-t0 {
  margin-top: 0px; }

.m-b0 {
  margin-bottom: 0px; }

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px; }

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px; }

.p-a5 {
  padding: 5px; }

.p-l5 {
  padding-left: 5px; }

.p-r5 {
  padding-right: 5px; }

.p-t5 {
  padding-top: 5px; }

.p-b5 {
  padding-bottom: 5px; }

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px; }

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px; }

.m-a5 {
  margin: 5px; }

.m-l5 {
  margin-left: 5px; }

.m-r5 {
  margin-right: 5px; }

.m-t5 {
  margin-top: 5px; }

.m-b5 {
  margin-bottom: 5px; }

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px; }

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px; }

.p-a10 {
  padding: 10px; }

.p-l10 {
  padding-left: 10px; }

.p-r10 {
  padding-right: 10px; }

.p-t10 {
  padding-top: 10px; }

.p-b10 {
  padding-bottom: 10px; }

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px; }

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px; }

.m-a10 {
  margin: 10px; }

.m-l10 {
  margin-left: 10px; }

.m-r10 {
  margin-right: 10px; }

.m-t10 {
  margin-top: 10px; }

.m-b10 {
  margin-bottom: 10px; }

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px; }

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px; }

.p-a15 {
  padding: 15px; }

.p-l15 {
  padding-left: 15px; }

.p-r15 {
  padding-right: 15px; }

.p-t15 {
  padding-top: 15px; }

.p-b15 {
  padding-bottom: 15px; }

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px; }

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px; }

.m-a15 {
  margin: 15px; }

.m-l15 {
  margin-left: 15px; }

.m-r15 {
  margin-right: 15px; }

.m-t15 {
  margin-top: 15px; }

.m-b15 {
  margin-bottom: 15px; }

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px; }

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px; }

.p-a20 {
  padding: 20px; }

.p-l20 {
  padding-left: 20px; }

.p-r20 {
  padding-right: 20px; }

.p-t20 {
  padding-top: 20px; }

.p-b20 {
  padding-bottom: 20px; }

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px; }

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px; }

.m-a20 {
  margin: 20px; }

.m-l20 {
  margin-left: 20px; }

.m-r20 {
  margin-right: 20px; }

.m-t20 {
  margin-top: 20px; }

.m-b20 {
  margin-bottom: 20px; }

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px; }

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px; }

.p-a25 {
  padding: 25px; }

.p-l25 {
  padding-left: 25px; }

.p-r25 {
  padding-right: 25px; }

.p-t25 {
  padding-top: 25px; }

.p-b25 {
  padding-bottom: 25px; }

.p-lr25 {
  padding-left: 25px;
  padding-right: 25px; }

.p-tb25 {
  padding-bottom: 25px;
  padding-top: 25px; }

.m-a25 {
  margin: 25px; }

.m-l25 {
  margin-left: 25px; }

.m-r25 {
  margin-right: 25px; }

.m-t25 {
  margin-top: 25px; }

.m-b25 {
  margin-bottom: 25px; }

.m-lr25 {
  margin-left: 25px;
  margin-right: 25px; }

.m-tb25 {
  margin-bottom: 25px;
  margin-top: 25px; }

.p-a30 {
  padding: 30px; }

.p-l30 {
  padding-left: 30px; }

.p-r30 {
  padding-right: 30px; }

.p-t30 {
  padding-top: 30px; }

.p-b30 {
  padding-bottom: 30px; }

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px; }

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px; }

.m-a30 {
  margin: 30px; }

.m-l30 {
  margin-left: 30px; }

.m-r30 {
  margin-right: 30px; }

.m-t30 {
  margin-top: 30px; }

.m-b30 {
  margin-bottom: 30px; }

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px; }

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px; }

.p-a35 {
  padding: 35px; }

.p-l35 {
  padding-left: 35px; }

.p-r35 {
  padding-right: 35px; }

.p-t35 {
  padding-top: 35px; }

.p-b35 {
  padding-bottom: 35px; }

.p-lr35 {
  padding-left: 35px;
  padding-right: 35px; }

.p-tb35 {
  padding-bottom: 35px;
  padding-top: 35px; }

.m-a35 {
  margin: 35px; }

.m-l35 {
  margin-left: 35px; }

.m-r35 {
  margin-right: 35px; }

.m-t35 {
  margin-top: 35px; }

.m-b35 {
  margin-bottom: 35px; }

.m-lr35 {
  margin-left: 35px;
  margin-right: 35px; }

.m-tb35 {
  margin-bottom: 35px;
  margin-top: 35px; }

.p-a40 {
  padding: 40px; }

.p-l40 {
  padding-left: 40px; }

.p-r40 {
  padding-right: 40px; }

.p-t40 {
  padding-top: 40px; }

.p-b40 {
  padding-bottom: 40px; }

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px; }

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px; }

.m-a40 {
  margin: 40px; }

.m-l40 {
  margin-left: 40px; }

.m-r40 {
  margin-right: 40px; }

.m-t40 {
  margin-top: 40px; }

.m-b40 {
  margin-bottom: 40px; }

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px; }

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px; }

.p-a45 {
  padding: 45px; }

.p-l45 {
  padding-left: 45px; }

.p-r45 {
  padding-right: 45px; }

.p-t45 {
  padding-top: 45px; }

.p-b45 {
  padding-bottom: 45px; }

.p-lr45 {
  padding-left: 45px;
  padding-right: 45px; }

.p-tb45 {
  padding-bottom: 45px;
  padding-top: 45px; }

.m-a45 {
  margin: 45px; }

.m-l45 {
  margin-left: 45px; }

.m-r45 {
  margin-right: 45px; }

.m-t45 {
  margin-top: 45px; }

.m-b45 {
  margin-bottom: 45px; }

.m-lr45 {
  margin-left: 45px;
  margin-right: 45px; }

.m-tb45 {
  margin-bottom: 45px;
  margin-top: 45px; }

.p-a50 {
  padding: 50px; }

.p-l50 {
  padding-left: 50px; }

.p-r50 {
  padding-right: 50px; }

.p-t50 {
  padding-top: 50px; }

.p-b50 {
  padding-bottom: 50px; }

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px; }

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px; }

.m-a50 {
  margin: 50px; }

.m-l50 {
  margin-left: 50px; }

.m-r50 {
  margin-right: 50px; }

.m-t50 {
  margin-top: 50px; }

.m-b50 {
  margin-bottom: 50px; }

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px; }

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px; }

.p-a55 {
  padding: 55px; }

.p-l55 {
  padding-left: 55px; }

.p-r55 {
  padding-right: 55px; }

.p-t55 {
  padding-top: 55px; }

.p-b55 {
  padding-bottom: 55px; }

.p-lr55 {
  padding-left: 55px;
  padding-right: 55px; }

.p-tb55 {
  padding-bottom: 55px;
  padding-top: 55px; }

.m-a55 {
  margin: 55px; }

.m-l55 {
  margin-left: 55px; }

.m-r55 {
  margin-right: 55px; }

.m-t55 {
  margin-top: 55px; }

.m-b55 {
  margin-bottom: 55px; }

.m-lr55 {
  margin-left: 55px;
  margin-right: 55px; }

.m-tb55 {
  margin-bottom: 55px;
  margin-top: 55px; }

.p-a60 {
  padding: 60px; }

.p-l60 {
  padding-left: 60px; }

.p-r60 {
  padding-right: 60px; }

.p-t60 {
  padding-top: 60px; }

.p-b60 {
  padding-bottom: 60px; }

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px; }

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px; }

.m-a60 {
  margin: 60px; }

.m-l60 {
  margin-left: 60px; }

.m-r60 {
  margin-right: 60px; }

.m-t60 {
  margin-top: 60px; }

.m-b60 {
  margin-bottom: 60px; }

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px; }

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px; }

.p-a65 {
  padding: 65px; }

.p-l65 {
  padding-left: 65px; }

.p-r65 {
  padding-right: 65px; }

.p-t65 {
  padding-top: 65px; }

.p-b65 {
  padding-bottom: 65px; }

.p-lr65 {
  padding-left: 65px;
  padding-right: 65px; }

.p-tb65 {
  padding-bottom: 65px;
  padding-top: 65px; }

.m-a65 {
  margin: 65px; }

.m-l65 {
  margin-left: 65px; }

.m-r65 {
  margin-right: 65px; }

.m-t65 {
  margin-top: 65px; }

.m-b65 {
  margin-bottom: 65px; }

.m-lr65 {
  margin-left: 65px;
  margin-right: 65px; }

.m-tb65 {
  margin-bottom: 65px;
  margin-top: 65px; }

.p-a70 {
  padding: 70px; }

.p-l70 {
  padding-left: 70px; }

.p-r70 {
  padding-right: 70px; }

.p-t70 {
  padding-top: 70px; }

.p-b70 {
  padding-bottom: 70px; }

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px; }

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px; }

.m-a70 {
  margin: 70px; }

.m-l70 {
  margin-left: 70px; }

.m-r70 {
  margin-right: 70px; }

.m-t70 {
  margin-top: 70px; }

.m-b70 {
  margin-bottom: 70px; }

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px; }

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px; }

.p-a75 {
  padding: 75px; }

.p-l75 {
  padding-left: 75px; }

.p-r75 {
  padding-right: 75px; }

.p-t75 {
  padding-top: 75px; }

.p-b75 {
  padding-bottom: 75px; }

.p-lr75 {
  padding-left: 75px;
  padding-right: 75px; }

.p-tb75 {
  padding-bottom: 75px;
  padding-top: 75px; }

.m-a75 {
  margin: 75px; }

.m-l75 {
  margin-left: 75px; }

.m-r75 {
  margin-right: 75px; }

.m-t75 {
  margin-top: 75px; }

.m-b75 {
  margin-bottom: 75px; }

.m-lr75 {
  margin-left: 75px;
  margin-right: 75px; }

.m-tb75 {
  margin-bottom: 75px;
  margin-top: 75px; }

.p-a80 {
  padding: 80px; }

.p-l80 {
  padding-left: 80px; }

.p-r80 {
  padding-right: 80px; }

.p-t80 {
  padding-top: 80px; }

.p-b80 {
  padding-bottom: 80px; }

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px; }

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px; }

.m-a80 {
  margin: 80px; }

.m-l80 {
  margin-left: 80px; }

.m-r80 {
  margin-right: 80px; }

.m-t80 {
  margin-top: 80px; }

.m-b80 {
  margin-bottom: 80px; }

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px; }

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px; }

.p-a85 {
  padding: 85px; }

.p-l85 {
  padding-left: 85px; }

.p-r85 {
  padding-right: 85px; }

.p-t85 {
  padding-top: 85px; }

.p-b85 {
  padding-bottom: 85px; }

.p-lr85 {
  padding-left: 85px;
  padding-right: 85px; }

.p-tb85 {
  padding-bottom: 85px;
  padding-top: 85px; }

.m-a85 {
  margin: 85px; }

.m-l85 {
  margin-left: 85px; }

.m-r85 {
  margin-right: 85px; }

.m-t85 {
  margin-top: 85px; }

.m-b85 {
  margin-bottom: 85px; }

.m-lr85 {
  margin-left: 85px;
  margin-right: 85px; }

.m-tb85 {
  margin-bottom: 85px;
  margin-top: 85px; }

.p-a90 {
  padding: 90px; }

.p-l90 {
  padding-left: 90px; }

.p-r90 {
  padding-right: 90px; }

.p-t90 {
  padding-top: 90px; }

.p-b90 {
  padding-bottom: 90px; }

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px; }

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px; }

.m-a90 {
  margin: 90px; }

.m-l90 {
  margin-left: 90px; }

.m-r90 {
  margin-right: 90px; }

.m-t90 {
  margin-top: 90px; }

.m-b90 {
  margin-bottom: 90px; }

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px; }

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px; }

.p-a95 {
  padding: 95px; }

.p-l95 {
  padding-left: 95px; }

.p-r95 {
  padding-right: 95px; }

.p-t95 {
  padding-top: 95px; }

.p-b95 {
  padding-bottom: 95px; }

.p-lr95 {
  padding-left: 95px;
  padding-right: 95px; }

.p-tb95 {
  padding-bottom: 95px;
  padding-top: 95px; }

.m-a95 {
  margin: 95px; }

.m-l95 {
  margin-left: 95px; }

.m-r95 {
  margin-right: 95px; }

.m-t95 {
  margin-top: 95px; }

.m-b95 {
  margin-bottom: 95px; }

.m-lr95 {
  margin-left: 95px;
  margin-right: 95px; }

.m-tb95 {
  margin-bottom: 95px;
  margin-top: 95px; }

.p-a100 {
  padding: 100px; }

.p-l100 {
  padding-left: 100px; }

.p-r100 {
  padding-right: 100px; }

.p-t100 {
  padding-top: 100px; }

.p-b100 {
  padding-bottom: 100px; }

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px; }

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px; }

.m-a100 {
  margin: 100px; }

.m-l100 {
  margin-left: 100px; }

.m-r100 {
  margin-right: 100px; }

.m-t100 {
  margin-top: 100px; }

.m-b100 {
  margin-bottom: 100px; }

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px; }

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px; }

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0px; }
  .m-lg-b0 {
    margin-bottom: 0px; }
  .m-lg-t5 {
    margin-top: 5px; }
  .m-lg-b5 {
    margin-bottom: 5px; }
  .m-lg-t10 {
    margin-top: 10px; }
  .m-lg-b10 {
    margin-bottom: 10px; }
  .m-lg-t15 {
    margin-top: 15px; }
  .m-lg-b15 {
    margin-bottom: 15px; }
  .m-lg-t20 {
    margin-top: 20px; }
  .m-lg-b20 {
    margin-bottom: 20px; }
  .m-lg-t25 {
    margin-top: 25px; }
  .m-lg-b25 {
    margin-bottom: 25px; }
  .m-lg-t30 {
    margin-top: 30px; }
  .m-lg-b30 {
    margin-bottom: 30px; }
  .m-lg-t35 {
    margin-top: 35px; }
  .m-lg-b35 {
    margin-bottom: 35px; }
  .m-lg-t40 {
    margin-top: 40px; }
  .m-lg-b40 {
    margin-bottom: 40px; }
  .m-lg-t45 {
    margin-top: 45px; }
  .m-lg-b45 {
    margin-bottom: 45px; }
  .m-lg-t50 {
    margin-top: 50px; }
  .m-lg-b50 {
    margin-bottom: 50px; }
  .m-lg-t55 {
    margin-top: 55px; }
  .m-lg-b55 {
    margin-bottom: 55px; }
  .m-lg-t60 {
    margin-top: 60px; }
  .m-lg-b60 {
    margin-bottom: 60px; }
  .m-lg-t65 {
    margin-top: 65px; }
  .m-lg-b65 {
    margin-bottom: 65px; }
  .m-lg-t70 {
    margin-top: 70px; }
  .m-lg-b70 {
    margin-bottom: 70px; }
  .m-lg-t75 {
    margin-top: 75px; }
  .m-lg-b75 {
    margin-bottom: 75px; }
  .m-lg-t80 {
    margin-top: 80px; }
  .m-lg-b80 {
    margin-bottom: 80px; }
  .m-lg-t85 {
    margin-top: 85px; }
  .m-lg-b85 {
    margin-bottom: 85px; }
  .m-lg-t90 {
    margin-top: 90px; }
  .m-lg-b90 {
    margin-bottom: 90px; }
  .m-lg-t95 {
    margin-top: 95px; }
  .m-lg-b95 {
    margin-bottom: 95px; }
  .m-lg-t100 {
    margin-top: 100px; }
  .m-lg-b100 {
    margin-bottom: 100px; } }

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0px; }
  .m-md-b0 {
    margin-bottom: 0px; }
  .m-md-t5 {
    margin-top: 5px; }
  .m-md-b5 {
    margin-bottom: 5px; }
  .m-md-t10 {
    margin-top: 10px; }
  .m-md-b10 {
    margin-bottom: 10px; }
  .m-md-t15 {
    margin-top: 15px; }
  .m-md-b15 {
    margin-bottom: 15px; }
  .m-md-t20 {
    margin-top: 20px; }
  .m-md-b20 {
    margin-bottom: 20px; }
  .m-md-t25 {
    margin-top: 25px; }
  .m-md-b25 {
    margin-bottom: 25px; }
  .m-md-t30 {
    margin-top: 30px; }
  .m-md-b30 {
    margin-bottom: 30px; }
  .m-md-t35 {
    margin-top: 35px; }
  .m-md-b35 {
    margin-bottom: 35px; }
  .m-md-t40 {
    margin-top: 40px; }
  .m-md-b40 {
    margin-bottom: 40px; }
  .m-md-t45 {
    margin-top: 45px; }
  .m-md-b45 {
    margin-bottom: 45px; }
  .m-md-t50 {
    margin-top: 50px; }
  .m-md-b50 {
    margin-bottom: 50px; }
  .m-md-t55 {
    margin-top: 55px; }
  .m-md-b55 {
    margin-bottom: 55px; }
  .m-md-t60 {
    margin-top: 60px; }
  .m-md-b60 {
    margin-bottom: 60px; }
  .m-md-t65 {
    margin-top: 65px; }
  .m-md-b65 {
    margin-bottom: 65px; }
  .m-md-t70 {
    margin-top: 70px; }
  .m-md-b70 {
    margin-bottom: 70px; }
  .m-md-t75 {
    margin-top: 75px; }
  .m-md-b75 {
    margin-bottom: 75px; }
  .m-md-t80 {
    margin-top: 80px; }
  .m-md-b80 {
    margin-bottom: 80px; }
  .m-md-t85 {
    margin-top: 85px; }
  .m-md-b85 {
    margin-bottom: 85px; }
  .m-md-t90 {
    margin-top: 90px; }
  .m-md-b90 {
    margin-bottom: 90px; }
  .m-md-t95 {
    margin-top: 95px; }
  .m-md-b95 {
    margin-bottom: 95px; }
  .m-md-t100 {
    margin-top: 100px; }
  .m-md-b100 {
    margin-bottom: 100px; } }

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0px; }
  .m-sm-b0 {
    margin-bottom: 0px; }
  .m-sm-t5 {
    margin-top: 5px; }
  .m-sm-b5 {
    margin-bottom: 5px; }
  .m-sm-t10 {
    margin-top: 10px; }
  .m-sm-b10 {
    margin-bottom: 10px; }
  .m-sm-t15 {
    margin-top: 15px; }
  .m-sm-b15 {
    margin-bottom: 15px; }
  .m-sm-t20 {
    margin-top: 20px; }
  .m-sm-b20 {
    margin-bottom: 20px; }
  .m-sm-t25 {
    margin-top: 25px; }
  .m-sm-b25 {
    margin-bottom: 25px; }
  .m-sm-t30 {
    margin-top: 30px; }
  .m-sm-b30 {
    margin-bottom: 30px; }
  .m-sm-t35 {
    margin-top: 35px; }
  .m-sm-b35 {
    margin-bottom: 35px; }
  .m-sm-t40 {
    margin-top: 40px; }
  .m-sm-b40 {
    margin-bottom: 40px; }
  .m-sm-t45 {
    margin-top: 45px; }
  .m-sm-b45 {
    margin-bottom: 45px; }
  .m-sm-t50 {
    margin-top: 50px; }
  .m-sm-b50 {
    margin-bottom: 50px; }
  .m-sm-t55 {
    margin-top: 55px; }
  .m-sm-b55 {
    margin-bottom: 55px; }
  .m-sm-t60 {
    margin-top: 60px; }
  .m-sm-b60 {
    margin-bottom: 60px; }
  .m-sm-t65 {
    margin-top: 65px; }
  .m-sm-b65 {
    margin-bottom: 65px; }
  .m-sm-t70 {
    margin-top: 70px; }
  .m-sm-b70 {
    margin-bottom: 70px; }
  .m-sm-t75 {
    margin-top: 75px; }
  .m-sm-b75 {
    margin-bottom: 75px; }
  .m-sm-t80 {
    margin-top: 80px; }
  .m-sm-b80 {
    margin-bottom: 80px; }
  .m-sm-t85 {
    margin-top: 85px; }
  .m-sm-b85 {
    margin-bottom: 85px; }
  .m-sm-t90 {
    margin-top: 90px; }
  .m-sm-b90 {
    margin-bottom: 90px; }
  .m-sm-t95 {
    margin-top: 95px; }
  .m-sm-b95 {
    margin-bottom: 95px; }
  .m-sm-t100 {
    margin-top: 100px; }
  .m-sm-b100 {
    margin-bottom: 100px; } }

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0px; }
  .m-xs-b0 {
    margin-bottom: 0px; }
  .m-xs-t5 {
    margin-top: 5px; }
  .m-xs-b5 {
    margin-bottom: 5px; }
  .m-xs-t10 {
    margin-top: 10px; }
  .m-xs-b10 {
    margin-bottom: 10px; }
  .m-xs-t15 {
    margin-top: 15px; }
  .m-xs-b15 {
    margin-bottom: 15px; }
  .m-xs-t20 {
    margin-top: 20px; }
  .m-xs-b20 {
    margin-bottom: 20px; }
  .m-xs-t25 {
    margin-top: 25px; }
  .m-xs-b25 {
    margin-bottom: 25px; }
  .m-xs-t30 {
    margin-top: 30px; }
  .m-xs-b30 {
    margin-bottom: 30px; }
  .m-xs-t35 {
    margin-top: 35px; }
  .m-xs-b35 {
    margin-bottom: 35px; }
  .m-xs-t40 {
    margin-top: 40px; }
  .m-xs-b40 {
    margin-bottom: 40px; }
  .m-xs-t45 {
    margin-top: 45px; }
  .m-xs-b45 {
    margin-bottom: 45px; }
  .m-xs-t50 {
    margin-top: 50px; }
  .m-xs-b50 {
    margin-bottom: 50px; }
  .m-xs-t55 {
    margin-top: 55px; }
  .m-xs-b55 {
    margin-bottom: 55px; }
  .m-xs-t60 {
    margin-top: 60px; }
  .m-xs-b60 {
    margin-bottom: 60px; }
  .m-xs-t65 {
    margin-top: 65px; }
  .m-xs-b65 {
    margin-bottom: 65px; }
  .m-xs-t70 {
    margin-top: 70px; }
  .m-xs-b70 {
    margin-bottom: 70px; }
  .m-xs-t75 {
    margin-top: 75px; }
  .m-xs-b75 {
    margin-bottom: 75px; }
  .m-xs-t80 {
    margin-top: 80px; }
  .m-xs-b80 {
    margin-bottom: 80px; }
  .m-xs-t85 {
    margin-top: 85px; }
  .m-xs-b85 {
    margin-bottom: 85px; }
  .m-xs-t90 {
    margin-top: 90px; }
  .m-xs-b90 {
    margin-bottom: 90px; }
  .m-xs-t95 {
    margin-top: 95px; }
  .m-xs-b95 {
    margin-bottom: 95px; }
  .m-xs-t100 {
    margin-top: 100px; }
  .m-xs-b100 {
    margin-bottom: 100px; } }

.m-auto {
  margin: auto; }

.max-w50 {
  max-width: 50px; }

.max-w60 {
  max-width: 60px; }

.max-w80 {
  max-width: 80px; }

.max-w100 {
  max-width: 100px; }

.max-w200 {
  max-width: 200px; }

.max-w300 {
  max-width: 300px; }

.max-w400 {
  max-width: 400px; }

.max-w500 {
  max-width: 500px; }

.max-w600 {
  max-width: 600px; }

.max-w700 {
  max-width: 700px; }

.max-w800 {
  max-width: 800px; }

.max-w900 {
  max-width: 900px; }

.max-w1000 {
  max-width: 1000px; }

.sticky-top {
  top: 120px; }

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover; }

section {
  position: relative;
  z-index: 1; }

.section-head {
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .section-head {
      margin-bottom: 30px; } }
  .section-head h1, .section-head .h1, .section-head h2, .section-head .h2, .section-head h3, .section-head .h3, .section-head h4, .section-head .h4, .section-head .comment-reply-title,
  .section-head .wp-block-search .wp-block-search__label, .wp-block-search .section-head .wp-block-search__label, .section-head h5, .section-head .h5, .section-head h6, .section-head .h6 {
    font-family: var(--font-family-title); }
  .section-head .sub-title {
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 16px; }
  .section-head h2.title, .section-head .title.h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #362465; }
    @media only screen and (max-width: 1280px) {
      .section-head h2.title, .section-head .title.h2 {
        font-size: 42px; } }
    @media only screen and (max-width: 991px) {
      .section-head h2.title, .section-head .title.h2 {
        font-size: 36px; } }
    @media only screen and (max-width: 575px) {
      .section-head h2.title, .section-head .title.h2 {
        font-size: 28px; } }
  .section-head h3.title, .section-head .title.h3 {
    font-size: 50px;
    font-weight: 600;
    line-height: 1.2; }
    @media only screen and (max-width: 1480px) {
      .section-head h3.title, .section-head .title.h3 {
        font-size: 42px; } }
    @media only screen and (max-width: 1280px) {
      .section-head h3.title, .section-head .title.h3 {
        font-size: 36px; } }
    @media only screen and (max-width: 767px) {
      .section-head h3.title, .section-head .title.h3 {
        font-size: 28px; } }
  .section-head h4.title, .section-head .title.h4, .section-head .title.comment-reply-title,
  .section-head .wp-block-search .title.wp-block-search__label, .wp-block-search .section-head .title.wp-block-search__label {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px; }
    @media only screen and (max-width: 1480px) {
      .section-head h4.title, .section-head .title.h4, .section-head .title.comment-reply-title,
      .section-head .wp-block-search .title.wp-block-search__label, .wp-block-search .section-head .title.wp-block-search__label {
        font-size: 30px; } }
    @media only screen and (max-width: 1280px) {
      .section-head h4.title, .section-head .title.h4, .section-head .title.comment-reply-title,
      .section-head .wp-block-search .title.wp-block-search__label, .wp-block-search .section-head .title.wp-block-search__label {
        font-size: 26px; } }
    @media only screen and (max-width: 767px) {
      .section-head h4.title, .section-head .title.h4, .section-head .title.comment-reply-title,
      .section-head .wp-block-search .title.wp-block-search__label, .wp-block-search .section-head .title.wp-block-search__label {
        font-size: 22px; } }
  .section-head p {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400; }
    @media only screen and (max-width: 767px) {
      .section-head p {
        font-size: 16px; } }
  .section-head.text-center {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto; }
    .section-head.text-center p {
      max-width: 585px;
      margin-left: auto;
      margin-right: auto; }
  .section-head.m-b30 {
    margin-bottom: 30px; }

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0; }
  .row.spno [class*="col"],
  .row.spno [class*="col"],
  .spno [class*="col"],
  .spno [class*="col"] {
    padding-left: 0;
    padding-right: 0; }

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row.sp4 [class*="col"],
  .row.sp4 [class*="col"],
  .sp4 [class*="col"],
  .sp4 [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px; }
  .row.sp15 [class*="col"],
  .row.sp15 [class*="col"],
  .sp15 [class*="col"],
  .sp15 [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.row.sp10,
.sp10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row.sp10 [class*="col"],
  .row.sp10 [class*="col"],
  .sp10 [class*="col"],
  .sp10 [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px; }
  .row.sp60 [class*="col"],
  .row.sp60 [class*="col"],
  .sp60 [class*="col"],
  .sp60 [class*="col"] {
    padding-left: 30px;
    padding-right: 30px; }

.rounded-xl {
  border-radius: 50% !important;
  overflow: hidden; }

.rounded-lg {
  border-radius: 20px !important;
  overflow: hidden; }

.rounded-md {
  border-radius: 8px !important;
  overflow: hidden; }

.rounded-sm {
  border-radius: 4px !important;
  overflow: hidden; }

.text-maroon {
  color: #9e0168; }

.text-orange {
  color: #ff8853; }

.text-yellow {
  color: #EFD430; }

.text-skyblue {
  color: #53C258; }

.text-red {
  color: #f22462; }

.text-green {
  color: #53C258; }

.text-blue {
  color: #131045; }

.bg-maroon {
  background-color: #9e0168; }

.bg-orange {
  background-color: #ff8853; }

.bg-yellow {
  background-color: #EFD430; }

.bg-skyblue {
  background-color: #00aeff; }

.bg-red {
  background-color: #f22462; }

.bg-green {
  background-color: #53C258; }

.bg-blue {
  background-color: #131045; }

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85); }

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7); }

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5); }

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3); }

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2); }

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8); }

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5); }

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left; }

.shadow-none {
  box-shadow: unset; }

.separator-2 {
  width: 55px;
  height: 5px;
  border-radius: 5px;
  position: relative;
  z-index: 2; }

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

:root {
  --header-height: 80px; }

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #EFBB20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 215px;
  height: var(--header-height);
  position: relative;
  z-index: 9; }
  .logo-header a {
    display: table-cell;
    vertical-align: middle; }
  .logo-header span {
    font-size: 20px;
    letter-spacing: 20px; }
  @media only screen and (max-width: 575px) {
    .logo-header {
      width: 185px; } }

.top-bar {
  background-color: var(--primary);
  color: #212529;
  padding: 8px 0;
  position: relative; }
  .top-bar .dz-topbar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px; }
  .top-bar .dz-topbar-left {
    padding-left: 15px; }
  .top-bar .dz-topbar-right {
    display: flex;
    align-items: center;
    padding-right: 15px; }
    .top-bar .dz-topbar-right ul {
      margin-left: 15px; }
  .top-bar .top-info li {
    display: inline-flex;
    padding-right: 15px; }
  .top-bar .top-info p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 0.8125rem;
    font-weight: 500; }
    .top-bar .top-info p.label {
      color: #BCFAF2;
      margin-right: 4px;
      font-weight: 400; }
  @media only screen and (max-width: 991px) {
    .top-bar {
      display: none; } }

.site-header {
  position: relative;
  z-index: 9999; }
  .site-header .main-bar {
    background: #fff;
    width: 100%;
    position: relative;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 12px 0; }
    @media only screen and (max-width: 575px) {
      .site-header .main-bar {
        padding: 0; } }
  .site-header .container {
    position: relative; }
  .site-header .extra-nav {
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    height: var(--header-height);
    padding-left: 30px; }
    @media only screen and (max-width: 1280px) {
      .site-header .extra-nav {
        padding-left: 20px; } }
    @media only screen and (max-width: 767px) {
      .site-header .extra-nav {
        display: none; } }
    .site-header .extra-nav .extra-cell {
      display: flex;
      align-items: center; }
      .site-header .extra-nav .extra-cell > * {
        margin-left: 10px; }
    .site-header .extra-nav #quik-search-btn {
      color: #fff;
      cursor: pointer;
      margin-right: 0px;
      border: 0;
      width: 45px;
      height: 45px;
      background: var(--secondary);
      line-height: 45px;
      text-align: center;
      font-size: 18px;
      border-radius: var(--border-radius-base); }
    @media only screen and (max-width: 575px) {
      .site-header .extra-nav .btn {
        padding-left: 15px;
        padding-right: 15px; } }
    .site-header .extra-nav ul li {
      display: inline-block; }
      .site-header .extra-nav ul li .icon {
        padding: 0;
        background: transparent;
        min-width: 40px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border-radius: 4px; }
    .site-header .extra-nav .extra-svg {
      display: flex;
      margin: 0 -5px; }
      .site-header .extra-nav .extra-svg .tp-user {
        height: 45px;
        width: 45px;
        border: 1px solid var(--primary);
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        margin: 0 5px; }

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important; }
  @media only screen and (max-width: 991px) {
    .navbar-toggler {
      margin: 18px 0px 16px 10px; } }

.navicon {
  width: 45px;
  height: 45px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-base); }
  .navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    background: #fff;
    left: 10px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .navicon span:nth-child(1) {
      top: 13px;
      width: 22px; }
    .navicon span:nth-child(2) {
      top: 22px;
      width: 25px; }
    .navicon span:nth-child(3) {
      top: 32px;
      width: 20px; }
  .navicon.open span:nth-child(1) {
    top: 23px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .navicon.open span:nth-child(3) {
    top: 23px;
    width: 23px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg); }

.header-tb .navicon span {
  background: #fff; }

.header-nav {
  padding: 0; }
  @media only screen and (max-width: 991px) {
    .header-nav {
      overflow-y: scroll;
      position: fixed;
      width: 60px;
      left: -280px;
      height: 100vh !important;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      top: 0;
      background-color: #ffffff;
      margin: 0;
      z-index: 99; } }
  @media only screen and (max-width: 991px) {
    .header-nav.show {
      left: -1px;
      -webkit-transition: all 1s;
      -ms-transition: all 1s;
      transition: all 1s;
      margin: 0;
      width: 280px; } }
  .header-nav .logo-header {
    display: none; }
    @media only screen and (max-width: 991px) {
      .header-nav .logo-header {
        display: block;
        padding: 20px 20px;
        float: none;
        height: auto;
        width: 240px; }
        .header-nav .logo-header img {
          height: 50px; }
        .header-nav .logo-header .logo-dark {
          display: block !important; } }
  .header-nav .mo-footer {
    display: none;
    padding: 30px 20px;
    text-align: center; }
    @media only screen and (max-width: 991px) {
      .header-nav .mo-footer {
        display: flex;
        padding: 30px 20px;
        text-align: center;
        gap: 15px; }
        .header-nav .mo-footer .dz-theme-mode {
          position: absolute;
          right: 15px;
          top: 28px;
          z-index: 14;
          border: 1px solid #cfdbed;
          border: 1px solid var(--border-color); } }
  .header-nav .nav {
    float: right;
    padding: 0;
    font-size: 0; }
    .header-nav .nav > li {
      margin: 0px;
      font-weight: 400;
      position: relative; }
      .header-nav .nav > li.has-mega-menu {
        position: inherit; }
      @media only screen and (max-width: 991px) {
        .header-nav .nav > li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%; } }
      .header-nav .nav > li.menu-item-has-children > a:after, .header-nav .nav > li.sub-menu-down > a:after {
        content: "\f078";
        font-size: 9px;
        margin-left: 5px;
        margin-top: 0;
        vertical-align: middle;
        font-weight: 900;
        font-family: "Font Awesome 5 Free"; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li.menu-item-has-children > a:after, .header-nav .nav > li.sub-menu-down > a:after {
            content: "\f054";
            background-color: var(--rgba-primary-2);
            color: var(--primary);
            height: 30px;
            line-height: 30px;
            right: 0;
            text-align: center;
            width: 30px;
            z-index: 3;
            float: right;
            font-size: 14px;
            border-radius: 4px;
            margin-top: -7px; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li.menu-item-has-children > a:after:before, .header-nav .nav > li.sub-menu-down > a:after:before {
            content: "\f078"; } }
      @media only screen and (max-width: 991px) {
        .header-nav .nav > li.menu-item-has-children.open > a:after, .header-nav .nav > li.sub-menu-down.open > a:after {
          content: "\f078";
          color: #fff;
          background: var(--primary); } }
      .header-nav .nav > li > a {
        color: var(--body-color);
        font-size: 16px;
        padding: 32px 20px;
        font-family: var(--font-family-title);
        cursor: pointer;
        font-weight: 500;
        display: inline-block;
        position: relative;
        border-width: 0 0 0 1px;
        text-transform: capitalize;
        line-height: 1; }
        @media only screen and (max-width: 1280px) {
          .header-nav .nav > li > a {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li > a {
            padding: 15px 0;
            display: block;
            color: #1C2E9E; } }
        .header-nav .nav > li > a span {
          display: inline-block; }
        .header-nav .nav > li > a.active {
          color: var(--primary); }
      .header-nav .nav > li .mega-menu,
      .header-nav .nav > li .sub-menu {
        background-color: #fff;
        display: block;
        left: 0;
        list-style: none;
        opacity: 0;
        padding: 15px 0;
        position: absolute;
        visibility: hidden;
        width: 220px;
        z-index: 10;
        margin-top: 20px;
        text-align: left;
        border-radius: 0 var(--border-radius-base) var(--border-radius-base) var(--border-radius-base);
        -webkit-transition: all 1s;
        -ms-transition: all 1s;
        transition: all 1s;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05); }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu,
          .header-nav .nav > li .sub-menu {
            display: none;
            position: static;
            visibility: visible;
            width: auto;
            background: transparent;
            box-shadow: none;
            margin: 0;
            border-top: 1px solid #eee;
            padding: 10px 0;
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; }
            .header-nav .nav > li .mega-menu > li > a i,
            .header-nav .nav > li .sub-menu > li > a i {
              color: #fff !important;
              height: 30px;
              line-height: 30px;
              right: 0;
              text-align: center;
              width: 30px;
              z-index: 3;
              font-size: 14px !important;
              margin: 0 !important;
              background: #aaa; } }
        .header-nav .nav > li .mega-menu .menu-item-has-children > a:before,
        .header-nav .nav > li .sub-menu .menu-item-has-children > a:before {
          content: "\f054";
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          float: right;
          font-size: 10px;
          margin-top: 2px; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu .menu-item-has-children.open > a:before,
          .header-nav .nav > li .sub-menu .menu-item-has-children.open > a:before {
            content: "\f054"; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu,
          .header-nav .nav > li .sub-menu {
            display: none;
            position: static;
            visibility: visible;
            width: auto;
            background: transparent;
            box-shadow: none;
            margin: 0;
            border-top: 1px solid #eee;
            padding: 0 0 5px;
            -webkit-transition: none;
            -ms-transition: none;
            transition: none;
            opacity: 1; }
            .header-nav .nav > li .mega-menu > li > a i,
            .header-nav .nav > li .sub-menu > li > a i {
              display: none; }
            .header-nav .nav > li .mega-menu .menu-item-has-children > a:before,
            .header-nav .nav > li .mega-menu > li > a i,
            .header-nav .nav > li .sub-menu .menu-item-has-children > a:before,
            .header-nav .nav > li .sub-menu > li > a i {
              color: #fff !important;
              font-size: 14px;
              content: "\f054";
              height: 30px;
              line-height: 30px;
              right: 0;
              text-align: center;
              width: 30px;
              z-index: 3;
              margin: 0 !important;
              background: var(--primary);
              border-radius: var(--border-radius-base); }
            .header-nav .nav > li .mega-menu .menu-item-has-children.open > a:before,
            .header-nav .nav > li .sub-menu .menu-item-has-children.open > a:before {
              content: "\f078";
              background: #333; } }
        .header-nav .nav > li .mega-menu li,
        .header-nav .nav > li .sub-menu li {
          position: relative; }
          .header-nav .nav > li .mega-menu li a,
          .header-nav .nav > li .sub-menu li a {
            color: #1C2E9E;
            display: block;
            font-size: 15px;
            padding: 10px 20px;
            line-height: 1.3;
            text-transform: capitalize;
            position: relative;
            transition: all 0.15s linear;
            -webkit-transition: all 0.15s linear;
            -moz-transition: all 0.15s linear;
            -o-transition: all 0.15s linear;
            font-weight: 500; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li a,
              .header-nav .nav > li .sub-menu li a {
                padding: 4px 0 4px 15px;
                display: block;
                line-height: 30px;
                position: relative;
                border: 1px solid #eee;
                border-width: 0 0 1px 0px; }
                .header-nav .nav > li .mega-menu li a:after,
                .header-nav .nav > li .sub-menu li a:after {
                  content: "";
                  font-family: "Font Awesome 5 Free";
                  width: auto;
                  height: auto;
                  background: 0 0 !important;
                  top: 3px;
                  color: #262F5A;
                  font-size: 12px;
                  position: absolute;
                  left: 0;
                  font-weight: 700; }
                .header-nav .nav > li .mega-menu li a:last-child,
                .header-nav .nav > li .sub-menu li a:last-child {
                  border-bottom: 0; } }
          .header-nav .nav > li .mega-menu li:hover > a,
          .header-nav .nav > li .sub-menu li:hover > a {
            color: var(--primary); }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li:hover > a,
              .header-nav .nav > li .sub-menu li:hover > a {
                padding: 4px 0 4px 15px;
                background: transparent; } }
            .header-nav .nav > li .mega-menu li:hover > a:after,
            .header-nav .nav > li .sub-menu li:hover > a:after {
              width: 10px; }
        .header-nav .nav > li .mega-menu li > .sub-menu,
        .header-nav .nav > li .sub-menu li > .sub-menu {
          left: 220px;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu li > .sub-menu,
            .header-nav .nav > li .sub-menu li > .sub-menu {
              display: none; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu .mega-menu,
          .header-nav .nav > li .mega-menu .sub-menu,
          .header-nav .nav > li .sub-menu .mega-menu,
          .header-nav .nav > li .sub-menu .sub-menu {
            border-top: 0;
            padding-left: 20px; }
            .header-nav .nav > li .mega-menu .mega-menu li a:after,
            .header-nav .nav > li .mega-menu .sub-menu li a:after,
            .header-nav .nav > li .sub-menu .mega-menu li a:after,
            .header-nav .nav > li .sub-menu .sub-menu li a:after {
              width: 5px;
              content: none;
              height: 5px;
              background: #262F5A !important;
              border-radius: 4px;
              top: 13px; } }
      .header-nav .nav > li .sub-menu li > a > i {
        color: inherit;
        display: block;
        float: right;
        font-size: 18px;
        opacity: 1;
        margin-top: 0px; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .sub-menu li > a > i {
            display: none; } }
      .header-nav .nav > li .mega-menu {
        display: flex;
        right: 0px;
        width: 100%;
        z-index: 9;
        padding: 0; }
        .header-nav .nav > li .mega-menu > li {
          display: block;
          padding: 15px 0 15px;
          position: relative;
          vertical-align: top;
          width: 25%; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu > li {
              width: 100%;
              padding: 0; } }
          .header-nav .nav > li .mega-menu > li > a {
            color: #212529;
            display: block;
            padding: 0 20px;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 15px; }
            .header-nav .nav > li .mega-menu > li > a > i {
              font-size: 14px;
              margin-right: 5px;
              text-align: center;
              width: 15px; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li > a {
                display: none; } }
          .header-nav .nav > li .mega-menu > li:after {
            content: "";
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            right: 0px;
            top: 0px;
            display: block;
            width: 1px;
            height: 100%; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li:after {
                content: none; } }
          .header-nav .nav > li .mega-menu > li:last-child:after {
            display: none; }
          .header-nav .nav > li .mega-menu > li:hover > a {
            background-color: transparent; }
          .header-nav .nav > li .mega-menu > li li i {
            font-size: 14px;
            margin-right: 5px;
            text-align: center;
            width: 15px; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu {
            display: none;
            padding: 10px 0; } }
      .header-nav .nav > li.active > a {
        color: var(--primary); }
      .header-nav .nav > li:hover > a {
        color: var(--primary); }
      .header-nav .nav > li:hover > .mega-menu,
      .header-nav .nav > li:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
        -webkit-transition: all 1s;
        -ms-transition: all 1s;
        transition: all 1s; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li:hover > .mega-menu,
          .header-nav .nav > li:hover > .sub-menu {
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; } }
        .header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
        .header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
          top: -1px; }
      .header-nav .nav > li.open .mega-menu,
      .header-nav .nav > li.open .sub-menu {
        display: block;
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
      .header-nav .nav > li.open .open .mega-menu,
      .header-nav .nav > li.open .open .sub-menu {
        display: block;
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
    @media only screen and (max-width: 1480px) {
      .header-nav .nav > li:nth-last-child(4) .sub-menu .sub-menu,
      .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
      .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
      .header-nav .nav > li:last-child .sub-menu .sub-menu {
        left: -220px; } }
    @media only screen and (max-width: 991px) {
      .header-nav .nav {
        float: none;
        padding: 0 20px; } }
  .header-nav .sidebar-footer {
    display: none; }
    @media only screen and (max-width: 991px) {
      .header-nav .sidebar-footer {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: auto;
        width: 100%;
        text-align: center; } }
    .header-nav .sidebar-footer .dz-login-register {
      text-align: center;
      padding-bottom: 10px; }
  .header-nav .dz-social-icon {
    text-align: center;
    display: none; }
    .header-nav .dz-social-icon li {
      display: inline-block;
      margin: 0 2px; }
    .header-nav .dz-social-icon a {
      width: 40px;
      height: 40px;
      padding: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 38px;
      text-align: center;
      border-radius: var(--border-radius-base); }
    @media only screen and (max-width: 991px) {
      .header-nav .dz-social-icon {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px; } }

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .header-transparent .main-bar {
    background: transparent;
    box-shadow: none; }
  .header-transparent .container-fluid, .header-transparent .container-sm, .header-transparent .container-md, .header-transparent .container-lg, .header-transparent .container-xl {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (max-width: 1280px) {
      .header-transparent .container-fluid, .header-transparent .container-sm, .header-transparent .container-md, .header-transparent .container-lg, .header-transparent .container-xl {
        padding-left: 15px;
        padding-right: 15px; } }
  .header-transparent .navbar-toggler span {
    background: #ffffff; }
  .header-transparent .is-fixed .nav > li > a {
    color: #212529; }
  .header-transparent .is-fixed .btn.text-white:not(:hover) {
    color: #212529 !important; }
  .header-transparent .top-bar {
    border-color: #ffffff; }
    .header-transparent .top-bar .dz-topbar-left li,
    .header-transparent .top-bar .dz-topbar-left i,
    .header-transparent .top-bar .dz-topbar-right li,
    .header-transparent .top-bar .dz-topbar-right i {
      color: #ffffff; }
  .header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
    padding-top: 85px; }
  @media only screen and (min-width: 991px) {
    .header-transparent .nav > li > a {
      color: #fff; } }
  .header-transparent .logo-header .logo-light {
    display: table-cell; }
  .header-transparent .logo-header .logo-dark {
    display: none; }
  .header-transparent .is-fixed .logo-header .logo-light {
    display: none; }
  .header-transparent .is-fixed .logo-header .logo-dark {
    display: table-cell; }

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: var(--card-bg);
  transition: all 0.5s;
  padding: 0; }

.is-fixed .logo-header img {
  padding: 10px 0; }

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: auto;
    position: fixed;
    width: 280px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: var(--card-bg);
    margin: 0;
    z-index: 99; }
    .mo-left .header-nav.nav-dark {
      background-color: #202020; }
    .mo-left .header-nav.show {
      left: -1px;
      transition: all 0.8s;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      -o-transition: all 0.8s;
      margin: 0;
      width: 280px; }
    .mo-left .header-nav .logo-header {
      display: block;
      float: none;
      height: auto !important;
      max-width: 100%;
      padding: 30px 20px;
      width: 100% !important; }
      .mo-left .header-nav .logo-header img {
        padding: 0;
        height: auto;
        max-width: unset;
        vertical-align: middle; }
    .mo-left .header-nav li.open a {
      position: relative; }
  .mo-left .navbar-toggler.open {
    z-index: 99; }
    .mo-left .navbar-toggler.open:after {
      content: "";
      left: 0;
      position: fixed;
      right: -20px;
      top: -63px;
      width: 100%;
      z-index: -1;
      margin: 0 0px 0px 20px;
      transition: all 0.5s;
      transform: scale(100);
      -o-transform: scale(100);
      -moz-transform: scale(100);
      -webkit-transform: scale(100);
      transform-origin: top right;
      background-color: rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6); }
    .mo-left .navbar-toggler.open span {
      background: #fff; } }

.text-black .nav > li > a {
  color: #212529; }

.text-black .navbar-toggler span {
  background: #212529; }

.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s; }

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown; }

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

.dz-bnr-inr {
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
  width: 100%;
  background-image: url(../images/banner/bnr.png);
  background-color: #261355;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .dz-bnr-inr {
      height: 350px; } }
  @media only screen and (max-width: 767px) {
    .dz-bnr-inr {
      height: 280px; } }
  .dz-bnr-inr .container {
    display: table;
    height: 100%; }
  .dz-bnr-inr .dz-bnr-inr-entry {
    height: 450px;
    vertical-align: middle;
    display: table-cell; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr .dz-bnr-inr-entry {
        height: 350px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr .dz-bnr-inr-entry {
        height: 280px; } }
  .dz-bnr-inr h1, .dz-bnr-inr .h1 {
    font-size: 70px;
    margin-bottom: 1rem;
    line-height: 1.2;
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    text-shadow: 0px 30px 70px #002622; }
    @media only screen and (max-width: 1280px) {
      .dz-bnr-inr h1, .dz-bnr-inr .h1 {
        font-size: 48px; } }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr h1, .dz-bnr-inr .h1 {
        font-size: 36px;
        margin-bottom: 5px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr h1, .dz-bnr-inr .h1 {
        font-size: 32px; } }
  .dz-bnr-inr .text {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.7;
    color: #9467FE; }
    @media only screen and (max-width: 1280px) {
      .dz-bnr-inr .text {
        font-size: 18px; } }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr .text {
        font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr .text {
        font-size: 14px; } }

.breadcrumb-row ul {
  background: var(--card-bg);
  padding: 0;
  display: inline-block;
  padding: 10px 30px;
  border-radius: var(--border-radius-base); }
  @media only screen and (max-width: 575px) {
    .breadcrumb-row ul {
      padding: 10px 18px; } }
  .breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--title); }
    .breadcrumb-row ul li.active {
      color: var(--title) !important; }
      .breadcrumb-row ul li.active a {
        color: var(--title); }
    .breadcrumb-row ul li a {
      color: var(--primary); }
    .breadcrumb-row ul li i {
      color: var(--primary);
      margin-right: 10px; }
    @media only screen and (max-width: 575px) {
      .breadcrumb-row ul li {
        font-size: 14px; } }
  .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item {
    padding-left: 12px; }
    .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
      content: "\f101";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: var(--primary);
      padding-right: 12px; }
    @media only screen and (max-width: 575px) {
      .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item {
        padding-left: 8px; }
        .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
          font-size: 12px;
          padding-right: 8px;
          margin-top: 2px; } }

.bg-shape1 {
  animation: aniRotate 10s infinite linear; }

.bg-shape2 {
  animation: aniRotate 10s infinite linear; }

.bg-shape3 {
  animation: aniRotate 10s infinite linear; }

.bg-shape4 {
  animation: aniRotate 10s infinite linear; }

.dz-bnr-inr {
  position: relative; }
  .dz-bnr-inr.style-1 {
    position: relative;
    background-color: #362465; }
    .dz-bnr-inr.style-1 .bg-shape1,
    .dz-bnr-inr.style-1 .bg-shape2,
    .dz-bnr-inr.style-1 .bg-shape3,
    .dz-bnr-inr.style-1 .bg-shape4 {
      position: absolute; }
      @media only screen and (max-width: 991px) {
        .dz-bnr-inr.style-1 .bg-shape1,
        .dz-bnr-inr.style-1 .bg-shape2,
        .dz-bnr-inr.style-1 .bg-shape3,
        .dz-bnr-inr.style-1 .bg-shape4 {
          display: none; } }
    .dz-bnr-inr.style-1 .bg-shape1 {
      top: 20%;
      left: 6%;
      animation: aniRotate 10s infinite linear;
      width: 70px; }
    .dz-bnr-inr.style-1 .bg-shape2 {
      right: 8%;
      bottom: 2%;
      animation: aniRotate 10s infinite linear;
      width: 70px; }
    .dz-bnr-inr.style-1 .bg-shape3 {
      bottom: 10%;
      left: 2%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-1 .bg-shape4 {
      top: 20%;
      right: 2%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-1 .dz-bnr-inr-entry .breadcrumb-row {
      position: absolute !important;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0rem 0rem;
      background: #9467FE;
      border-radius: 0.6rem 0.6rem 0 0;
      height: 1.25rem; }
      .dz-bnr-inr.style-1 .dz-bnr-inr-entry .breadcrumb-row:after {
        content: "";
        position: absolute;
        left: -35px;
        bottom: 0;
        background-color: var(--primary);
        width: calc(100% + 70px);
        height: 20px;
        border-radius: 12px 12px 0 0; }
        @media only screen and (max-width: 575px) {
          .dz-bnr-inr.style-1 .dz-bnr-inr-entry .breadcrumb-row:after {
            display: none; } }
      .dz-bnr-inr.style-1 .dz-bnr-inr-entry .breadcrumb-row ul {
        margin-bottom: 0;
        margin-top: -25px;
        z-index: 1;
        box-shadow: 0px 15px 40px rgba(1, 18, 111, 0.15);
        position: relative; }
      @media only screen and (max-width: 575px) {
        .dz-bnr-inr.style-1 .dz-bnr-inr-entry .breadcrumb-row {
          width: 100%;
          background: none !important; } }
  .dz-bnr-inr.style-2 {
    z-index: auto !important; }
    .dz-bnr-inr.style-2 .bg-shape1 {
      position: absolute;
      top: 20%;
      left: 6%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-2 .bg-shape2 {
      position: absolute;
      right: 8%;
      bottom: 2%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-2 .bg-shape3 {
      position: absolute;
      bottom: 10%;
      left: 2%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-2 .bg-shape4 {
      position: absolute;
      top: 20%;
      right: 2%;
      animation: aniRotate 10s infinite linear; }
    .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
      position: relative;
      z-index: 2; }
    .dz-bnr-inr.style-2 .breadcrumb-row {
      position: absolute;
      bottom: -2.4rem; }
  @media only screen and (max-width: 991px) {
    .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
      margin-top: 0;
      font-size: 3.5rem; } }
  @media only screen and (max-width: 767px) {
    .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
      font-size: 3rem; }
    .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
      padding-top: 2rem !important; } }
  @media only screen and (max-width: 575px) {
    .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
      font-size: 2rem;
      text-align: center; }
    .dz-bnr-inr.style-2 .breadcrumb-row {
      width: 100%;
      text-align: center; } }

.page-title {
  padding: 30px 0; }
  .page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px; }
  .page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px; }
    .page-title .page-title-content p span {
      font-weight: 500;
      color: var(--primary); }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 575px) {
    .breadcrumbs {
      justify-content: flex-start; } }
  .breadcrumbs li a {
    color: rgba(151, 151, 159, 0.39);
    display: inline-block;
    margin-left: 15px;
    font-size: 14px; }
  .breadcrumbs li:first-child a {
    margin-left: 0px; }
  .breadcrumbs li.active a {
    color: var(--primary); }

footer {
  color: #95A3FF;
  background-color: #001288; }
  footer.site-footer {
    position: relative; }
  footer .footer-logo {
    margin-bottom: 25px; }
    footer .footer-logo img {
      max-width: 220px; }
  footer .footer-title {
    margin: 0;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    line-height: 1.2; }
  footer .footer-top {
    padding: 50px 0px 0; }
    footer .footer-top .widget {
      margin-bottom: 45px; }
      @media only screen and (max-width: 991px) {
        footer .footer-top .widget {
          margin-bottom: 35px; } }
    footer .footer-top + .footer-bottom .footer-inner {
      border-top: 1px solid rgba(255, 255, 255, 0.1); }
  footer .footer-bottom {
    font-size: 14px; }
  footer .footer-inner {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0; }
    footer .footer-inner p {
      margin-bottom: 0; }
    footer .footer-inner .copyright-text a {
      color: #9467FE; }
  @media only screen and (max-width: 991px) {
    footer .footer-bottom .text-start,
    footer .footer-bottom .text-end {
      text-align: center !important; }
    footer .footer-bottom .text-end {
      margin-top: 15px; } }
  @media only screen and (max-width: 575px) {
    footer .footer-top {
      padding: 45px 0px 0px; }
    footer .footer-bottom .text-end {
      margin-top: 10px; }
    footer .widget_services ul li a {
      display: block;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
    footer .widget_about {
      margin-right: 70px; } }

@media only screen and (max-width: 575px) and (max-width: 1191px) {
  footer {
    margin-right: 0; } }
  @media only screen and (max-width: 575px) {
    footer.footer-dark {
      background: var(--secondary);
      --title: #fff; }
      footer.footer-dark .footer-bottom {
        border-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.5); }
      footer.footer-dark .footer-top {
        color: rgba(255, 255, 255, 0.6); }
      footer.footer-dark .footer-category {
        background: var(--primary);
        border-color: rgba(255, 255, 255, 0.1); }
        footer.footer-dark .footer-category .toggle-items ul li a {
          color: rgba(255, 255, 255, 0.85); }
          footer.footer-dark .footer-category .toggle-items ul li a:hover {
            color: var(--secondary); }
        footer.footer-dark .footer-category .bg-shape1,
        footer.footer-dark .footer-category .bg-shape2 {
          position: absolute;
          -webkit-animation: aniRotate 10s infinite linear;
          animation: aniRotate 10s infinite linear; }
        footer.footer-dark .footer-category .bg-shape1 {
          left: 8%;
          bottom: -5%; }
        footer.footer-dark .footer-category .bg-shape2 {
          right: 4%;
          top: -22%;
          z-index: 1; } }

@media only screen and (max-width: 575px) and (max-width: 1680px) {
  footer .bg-shape1 {
    left: 3%; }
  footer .bg-shape2 {
    right: 0%; } }

@media only screen and (max-width: 575px) and (max-width: 1191px) {
  footer .column-reverse {
    flex-direction: column-reverse; } }

@media only screen and (max-width: 575px) and (max-width: 1280px) {
  footer .bg-shape1,
  footer .bg-shape2 {
    display: none; } }
  @media only screen and (max-width: 575px) {
    footer .list-column ul {
      display: flex;
      flex-wrap: wrap; }
      footer .list-column ul li {
        flex: 0 0 50%;
        max-width: 50%; }
    footer .fb-link {
      list-style: none;
      margin: 0;
      padding: 0; }
      footer .fb-link li display:inline-bloc a {
        color: #fff;
        position: relative; }
        footer .fb-link li display:inline-bloc a:after {
          content: "";
          background: var(--primary);
          width: 5px;
          height: 5px; }
    footer .footer-link {
      margin: 0 -15px; }
      footer .footer-link li {
        display: inline-block;
        font-family: "Poppins", sans-serif;
        position: relative;
        padding: 0 15px 0 15px; }
        footer .footer-link li a {
          color: inherit; } }
  @media only screen and (max-width: 575px) and (max-width: 575px) {
    footer .footer-link {
      margin: 0 -10px; }
      footer .footer-link li {
        padding: 0 10px; } }
  @media only screen and (max-width: 575px) {
    footer .ft-subscribe {
      position: relative; }
      footer .ft-subscribe .form-control, footer .ft-subscribe .wp-block-categories-dropdown select, .wp-block-categories-dropdown footer .ft-subscribe select,
      footer .ft-subscribe .wp-block-archives-dropdown select, .wp-block-archives-dropdown footer .ft-subscribe select {
        height: 55px; }
      footer .ft-subscribe .sub-btn {
        height: 45px;
        width: 45px;
        z-index: 1;
        border-radius: 10px;
        background-color: var(--primary);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px; }
    footer .widget_links ul {
      margin-left: -15px;
      margin-right: -15px; }
      footer .widget_links ul li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px; }
        footer .widget_links ul li a {
          color: #95A3FF;
          font-size: 14px;
          padding: 5px 0;
          display: block; }
          footer .widget_links ul li a:hover {
            color: #9467FE; }
    footer .widget-social ul {
      margin-left: -9px;
      margin-right: -9px; }
      footer .widget-social ul li {
        padding-left: 9px;
        padding-right: 9px;
        display: inline-block; }
        footer .widget-social ul li a {
          color: #ffffff;
          font-size: 1rem;
          font-weight: 300;
          display: block;
          padding: 6px 0; }
          footer .widget-social ul li a img {
            margin-right: 12px; } }
      @media only screen and (max-width: 575px) and (max-width: 575px) {
        footer .widget-social ul li a {
          font-size: 14px; }
          footer .widget-social ul li a img {
            width: 16px; } }

.site-footer.style-1 {
  position: relative;
  color: rgba(255, 255, 255, 0.7); }
  .site-footer.style-1 h1, .site-footer.style-1 .h1, .site-footer.style-1 h2, .site-footer.style-1 .h2, .site-footer.style-1 h3, .site-footer.style-1 .h3, .site-footer.style-1 h4, .site-footer.style-1 .h4, .site-footer.style-1 .comment-reply-title,
  .site-footer.style-1 .wp-block-search .wp-block-search__label, .wp-block-search .site-footer.style-1 .wp-block-search__label, .site-footer.style-1 h5, .site-footer.style-1 .h5, .site-footer.style-1 h6, .site-footer.style-1 .h6 {
    color: #fff; }
    .site-footer.style-1 h1 a, .site-footer.style-1 .h1 a, .site-footer.style-1 h2 a, .site-footer.style-1 .h2 a, .site-footer.style-1 h3 a, .site-footer.style-1 .h3 a, .site-footer.style-1 h4 a, .site-footer.style-1 .h4 a, .site-footer.style-1 .comment-reply-title a, .site-footer.style-1 .wp-block-search .wp-block-search__label a, .wp-block-search .site-footer.style-1 .wp-block-search__label a, .site-footer.style-1 h5 a, .site-footer.style-1 .h5 a, .site-footer.style-1 h6 a, .site-footer.style-1 .h6 a {
      color: #fff; }
  .site-footer.style-1 .widget.widget_about {
    margin-right: 20px;
    max-width: 650px; }
    .site-footer.style-1 .widget.widget_about p {
      font-weight: 400;
      margin-bottom: 25px;
      line-height: 1.7; }
  .site-footer.style-1 .widget.recent-posts-entry {
    margin-right: 30px; }
    @media only screen and (max-width: 767px) {
      .site-footer.style-1 .widget.recent-posts-entry {
        margin-right: 0; } }
    .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post {
      margin-bottom: 20px; }
      .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .dz-info {
        padding-left: 0; }
        .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .dz-info .post-date {
          color: var(--primary);
          font-weight: 600;
          font-size: 14px; }
        .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .dz-info h6 a, .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .dz-info .h6 a {
          font-family: "Poppins", sans-serif;
          font-weight: 500; }
      .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
        color: var(--title); }
      .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .title a {
        color: white;
        padding-right: 30px; }
        .site-footer.style-1 .widget.recent-posts-entry .widget-post-bx .widget-post .title a:hover {
          color: var(--primary); }
  .site-footer.style-1 .bg-shape1 {
    position: absolute;
    right: 4%;
    bottom: 20%;
    -webkit-animation: aniRotate 10s infinite linear;
    animation: aniRotate 10s infinite linear;
    z-index: 666;
    width: 65px; }
  .site-footer.style-1 .footer-top {
    position: relative;
    display: flex;
    padding: 100px 0 45px;
    z-index: 1;
    background-color: #221646;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: luminosity; }
    .site-footer.style-1 .footer-top:before {
      content: "";
      position: absolute;
      opacity: 0.95;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #221646;
      z-index: -1; }
    @media only screen and (max-width: 991px) {
      .site-footer.style-1 .footer-top {
        padding: 50px 0 15px; } }
  .site-footer.style-1 .footer-bottom {
    position: relative;
    padding: 1.5rem 0;
    background-color: #9467FE !important; }
    .site-footer.style-1 .footer-bottom .copyright-text {
      opacity: 0.8; }
      .site-footer.style-1 .footer-bottom .copyright-text a {
        text-decoration: underline;
        font-weight: 500;
        color: #fff; }

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px; }

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px; }

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px; }

.alert[class*=alert-] i {
  margin-right: 8px; }

.alert[class*=alert-] ul {
  padding-left: 25px;
  margin-top: 10px; }

.card {
  border: 0px solid var(--border-color);
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 40px rgba(21, 50, 93, 0.1);
  background: var(--card-bg);
  height: calc(100% - 1.875rem); }
  @media only screen and (max-width: 575px) {
    .card {
      margin-bottom: 20px;
      height: calc(100% - 1.25rem); } }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    background: var(--card-bg);
    padding: 15px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    @media only screen and (max-width: 575px) {
      .card-header {
        padding: 15px 15px; } }
  .card-title {
    font-size: 24px;
    margin-bottom: 0px; }
  .card .card-body {
    padding: 25px 30px; }
    @media only screen and (max-width: 575px) {
      .card .card-body {
        padding: 15px 15px; } }
  .card .card-footer {
    background-color: transparent;
    border-color: var(--border-color);
    padding: 15px 30px; }
    @media only screen and (max-width: 575px) {
      .card .card-footer {
        padding: 15px 15px; } }
    .card .card-footer.has-table {
      padding: 10px 25px; }
      @media only screen and (max-width: 575px) {
        .card .card-footer.has-table {
          padding: 10px 20px; } }

.form-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--title);
  margin-bottom: 6px;
  background: transparent;
  border-color: #CFDBED; }

.form-control, .wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  height: 55px;
  border: 1px solid #CECECE;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--title);
  transition: all 0.3s ease-in-out;
  background: var(--input-bg);
  border-radius: var(--border-radius-base); }
  .form-control .select, .wp-block-categories-dropdown select .select, .wp-block-archives-dropdown select .select {
    padding: 12px 0; }
  .form-control span, .wp-block-categories-dropdown select span, .wp-block-archives-dropdown select span {
    margin-top: 0; }
  .form-control.md, .wp-block-categories-dropdown select.md,
  .wp-block-archives-dropdown select.md {
    height: 48px;
    border-radius: 10px; }
  .form-control::-webkit-input-placeholder, .wp-block-categories-dropdown select::-webkit-input-placeholder,
  .wp-block-archives-dropdown select::-webkit-input-placeholder {
    color: #4D4A85; }
  .form-control:-ms-input-placeholder, .wp-block-categories-dropdown select:-ms-input-placeholder,
  .wp-block-archives-dropdown select:-ms-input-placeholder {
    color: #4D4A85; }
  .form-control::placeholder, .wp-block-categories-dropdown select::placeholder,
  .wp-block-archives-dropdown select::placeholder {
    color: var(--body-color); }
  .form-control:focus, .wp-block-categories-dropdown select:focus,
  .wp-block-archives-dropdown select:focus, .form-control:active, .wp-block-categories-dropdown select:active,
  .wp-block-archives-dropdown select:active, .form-control.active, .wp-block-categories-dropdown select.active,
  .wp-block-archives-dropdown select.active {
    border-color: var(--primary);
    background: #F0EAFF; }
  @media only screen and (max-width: 575px) {
    .form-control, .wp-block-categories-dropdown select,
    .wp-block-archives-dropdown select {
      height: 50px;
      font-size: 14px; } }

textarea.form-control {
  min-height: auto;
  height: unset; }

textarea {
  height: 150px;
  resize: none; }

input:-internal-autofill-selected {
  background: #fafcff !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important; }

.input-group-text {
  padding: 5px 15px;
  background: #F0F5FF;
  margin-bottom: 0px !important;
  color: #7E87BF; }

.toggle {
  cursor: pointer;
  display: block; }

.toggle-switch {
  display: inline-block;
  background: #e1ebff;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s; }
  .toggle-switch:before, .toggle-switch:after {
    content: ""; }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #ffffff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s; }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%); }
  .toggle-checkbox:checked + .toggle-switch {
    background: var(--primary); }
    .toggle-checkbox:checked + .toggle-switch:before {
      left: 26px; }

.toggle-checkbox {
  position: absolute;
  visibility: hidden; }

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400; }

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #CFDBED;
  border-radius: 5px;
  color: rgba(151, 151, 159, 0.39); }
  .file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #F0F5FF;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden; }
  .file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: var(--primary);
    color: #ffffff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0; }
  .file-upload-wrapper:hover:before {
    background: #7034fe; }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

#ui-datepicker-div {
  display: none;
  background: #e6eeff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #7E87BF; }

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0; }
  .ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #7E87BF;
    font-size: 0.875rem;
    text-decoration: none; }
    .ui-datepicker-calendar tbody td a:hover {
      background-color: var(--primary);
      color: white; }
    .ui-datepicker-calendar tbody td a.ui-state-active {
      background-color: var(--primary);
      color: white; }

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all; }
  .ui-datepicker-header a.ui-corner-all:hover {
    background-color: white;
    color: #ffffff; }

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg); }

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%; }

.ui-datepicker-header a > span {
  display: none; }

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem; }

.ui-datepicker-week-col {
  color: #7E87BF;
  font-weight: 400;
  font-size: 0.75rem; }

label.error {
  color: #EB5757;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400; }

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }
  .nice-select ul {
    width: 100% !important; }

.nice-select .list {
  background: #212529; }

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #212529;
  color: var(--primary); }

.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn), select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.label-title {
  font-weight: 600;
  color: var(--secondary);
  margin-bottom: 5px;
  font-size: 14px; }

.form-select, select {
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #1C2E9E;
  transition: all 0.3s ease-in-out;
  background-color: #F0EAFF; }
  .form-select .dropdown-toggle, select .dropdown-toggle {
    border: 0 !important;
    padding: 0;
    font-weight: normal;
    text-transform: unset;
    outline: none; }
    .form-select .dropdown-toggle:focus, select .dropdown-toggle:focus {
      outline: 0 !important;
      outline-offset: 0; }
    .form-select .dropdown-toggle:after, select .dropdown-toggle:after {
      content: none; }
  .form-select.show, select.show {
    box-shadow: none !important;
    outline: 0px !important; }
    .form-select.show .dropdown-toggle, select.show .dropdown-toggle {
      box-shadow: none !important;
      outline: 0px !important; }
    .form-select.show.btn-group .dropdown-menu.inner, select.show.btn-group .dropdown-menu.inner {
      display: block; }
  .form-select .dropdown-menu, select .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 10px 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px; }
    .form-select .dropdown-menu ul li, select .dropdown-menu ul li {
      padding: 0; }
      .form-select .dropdown-menu ul li a, select .dropdown-menu ul li a {
        display: block;
        padding: 8px 20px;
        clear: both;
        font-weight: 400;
        color: #333;
        white-space: nowrap; }
        .form-select .dropdown-menu ul li a img, select .dropdown-menu ul li a img {
          border-radius: 100%;
          -webkit-border-radius: 100%;
          margin-right: 5px; }
        .form-select .dropdown-menu ul li a:hover, select .dropdown-menu ul li a:hover {
          background-color: #f2f2f4; }
      .form-select .dropdown-menu ul li.selected a, select .dropdown-menu ul li.selected a {
        background-color: var(--primary);
        color: #ffffff; }
  .form-select:focus, select:focus, .form-select:active, select:active, .form-select.active, select.active {
    border-color: var(--primary); }

.form-select.btn-group, select.btn-group {
  padding: 10px 20px;
  border-width: 1px; }
  .form-select.btn-group.show, select.btn-group.show {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
    border-color: var(--primary); }
  .form-select.btn-group .form-control .dropdown-toggle.btn-default, select.btn-group .form-control .dropdown-toggle.btn-default, .form-select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default, .wp-block-categories-dropdown .form-select.btn-group select .dropdown-toggle.btn-default, select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default, .wp-block-categories-dropdown select.btn-group select .dropdown-toggle.btn-default, .form-select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default, .wp-block-archives-dropdown .form-select.btn-group select .dropdown-toggle.btn-default, select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default, .wp-block-archives-dropdown select.btn-group select .dropdown-toggle.btn-default {
    padding: 0; }
  .form-select.btn-group .dropdown-toggle .caret, select.btn-group .dropdown-toggle .caret {
    font-size: 10px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); }
    .form-select.btn-group .dropdown-toggle .caret:before, select.btn-group .dropdown-toggle .caret:before {
      content: "\f078"; }

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent; }
  .custom-file-label:after {
    content: none !important; }

.dz-form.radius-no .form-control, .dz-form.radius-no .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dz-form.radius-no select,
.dz-form.radius-no .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dz-form.radius-no select {
  border-radius: 0; }

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px; }

.form-check-input {
  width: 1.125em;
  height: 1.125em;
  border: 2px solid var(--primary); }

.form-check-label {
  font-size: 15px;
  color: var(--title); }

.input-group {
  margin-bottom: 20px; }

.wpcf7-form .row {
  --bs-gutter-x: 20px; }

.checkout-form {
  margin-bottom: 40px; }
  .checkout-form .form-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary); }
  .checkout-form .form-control, .checkout-form .wp-block-categories-dropdown select, .wp-block-categories-dropdown .checkout-form select,
  .checkout-form .wp-block-archives-dropdown select, .wp-block-archives-dropdown .checkout-form select {
    background: none;
    border: 1px solid transparent;
    border-bottom: 2px solid #E1E1E1;
    border-radius: 0;
    padding: 10px 0; }
    .checkout-form .form-control::placeholder, .checkout-form .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .checkout-form select::placeholder,
    .checkout-form .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .checkout-form select::placeholder {
      font-weight: 600;
      font-size: 16px;
      color: var(--seconday); }

.pay-bottom {
  padding-bottom: 55px;
  border-bottom: 2px dashed #AAAAAA; }
  .pay-bottom .money-type {
    position: relative;
    text-align: center; }
    .pay-bottom .money-type .form-check {
      border: 1px solid #F0F0F0;
      border-radius: var(--border-radius-base);
      padding-left: 0; }
      .pay-bottom .money-type .form-check .form-check-input {
        position: absolute;
        right: 15px;
        top: 15px; }
        .pay-bottom .money-type .form-check .form-check-input:checked + .form-check-label {
          background-color: #FFF0DF;
          border: 1px solid var(--primary);
          border-radius: var(--border-radius-base); }
          .pay-bottom .money-type .form-check .form-check-input:checked + .form-check-label i {
            color: var(--primary); }
      .pay-bottom .money-type .form-check .form-check-label {
        width: 100%;
        margin: 0 !important;
        padding: 10px 10px; }
      .pay-bottom .money-type .form-check .payment-check i {
        font-size: 68px; }

.input-group-text.checkout-text {
  background: none;
  border-color: transparent;
  position: relative;
  right: 60px; }
  .input-group-text.checkout-text i {
    font-size: 20px;
    font-weight: 600; }

.form-control.default-select .btn, .wp-block-categories-dropdown select.default-select .btn, .wp-block-archives-dropdown select.default-select .btn {
  background: transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 15px 10px !important;
  border-color: #E1E1E1;
  padding: 10px 20px; }
  .form-control.default-select .btn:focus, .wp-block-categories-dropdown select.default-select .btn:focus, .wp-block-archives-dropdown select.default-select .btn:focus {
    outline: 0 !important; }

.form-control.default-select .filter-option-inner-inner, .wp-block-categories-dropdown select.default-select .filter-option-inner-inner, .wp-block-archives-dropdown select.default-select .filter-option-inner-inner {
  font-weight: 600;
  font-size: 16px; }

.shop-form .form-group {
  margin-bottom: 25px; }
  .shop-form .form-group .bootstrap-select {
    width: 100% !important; }
    .shop-form .form-group .bootstrap-select .btn {
      font-weight: 300;
      color: #000; }
      .shop-form .form-group .bootstrap-select .btn.btn-light {
        border: 1px solid rgba(0, 0, 0, 0.125); }
        .shop-form .form-group .bootstrap-select .btn.btn-light:hover {
          background-color: whitesmoke;
          border-color: 1px solid rgba(0, 0, 0, 0.125); }
        .shop-form .form-group .bootstrap-select .btn.btn-light:focus {
          outline: 0 !important; }

@media only screen and (max-width: 991px) {
  .shop-form {
    margin-top: 25px; } }

.login-area {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
  height: 100%;
  border-radius: 6px; }

.dz-ajax-overlay {
  position: relative; }
  .dz-ajax-overlay:after {
    content: "Loading...";
    height: 100%;
    width: 100%;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    border-radius: 5px;
    opacity: 0.8;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 14px;
    font-style: italic; }

.custom-radio {
  min-height: auto; }
  .custom-radio .form-check-label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 0 0 0 3px; }
  .custom-radio .form-check-input {
    height: 20px;
    width: 20px;
    margin-top: 3px; }
    @media only screen and (max-width: 575px) {
      .custom-radio .form-check-input {
        margin-top: 1px; } }
  .custom-radio .form-check-input[type="radio"] {
    width: 20px;
    min-width: 20px;
    height: 20px;
    position: relative;
    border-color: var(--primary);
    border-width: 1px;
    border-style: solid; }
  .custom-radio .form-check-input:checked[type="radio"] {
    background-image: none;
    background-color: white;
    border-color: var(--primary); }
    .custom-radio .form-check-input:checked[type="radio"]:after {
      content: "";
      height: 12px;
      width: 12px;
      background-color: var(--primary);
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 50%; }

button:hover,
button:active,
button:focus {
  outline: 0; }

.btn {
  font-size: 15px;
  padding: 14px 30px;
  line-height: 1.2;
  display: inline-flex;
  border-radius: var(--border-radius-base);
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  justify-content: center; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 16px;
    padding: 18px 30px;
    line-height: 1.4; }
    @media only screen and (max-width: 991px) {
      .btn.btn-lg, .btn-group-lg > .btn {
        font-size: 14px;
        padding: 15px 25px; } }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 13px;
    padding: 8px 18px; }
  .btn.btn-xs {
    font-size: 12px;
    padding: 11px 25px; }
  .btn.btn-primary {
    color: #ffffff; }
    .btn.btn-primary:hover {
      color: #ffffff; }
  .btn.text-lg {
    font-size: 18px;
    padding: 10px 20px; }
  .btn.space-lg {
    padding: 20px 50px; }
    @media only screen and (max-width: 991px) {
      .btn.space-lg {
        padding: 18px 30px; } }
  @media only screen and (max-width: 575px) {
    .btn {
      padding: 10px 15px; } }

.btn-primary.btn-gradient {
  background: linear-gradient(90deg, #9568FF 0%, #9568FF 99.31%);
  border: 0; }
  .btn-primary.btn-gradient:hover {
    background-position: right center; }

.btn-primary.btn-shadow {
  box-shadow: 0px 10px 25px rgba(243, 145, 0, 0.15); }

.btn.outline-2 {
  border-width: 2px; }

.btn-success,
.btn-danger,
.btn-warning {
  color: #fff; }
  .btn-success:hover,
  .btn-danger:hover,
  .btn-warning:hover {
    color: #fff; }

.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-warning:hover {
  color: #fff; }

.btn-success.effect:after,
.btn-info.effect:after,
.btn-danger.effect:after,
.btn-warning.effect:after {
  background: var(--secondary); }

.btn-primary.btn-shadow,
.btn-outline-primary.btn-shadow {
  box-shadow: 0px 4px 10px var(--rgba-primary-2); }

.btn-secondary.btn-shadow {
  box-shadow: 0px 4px 10px white; }

.btn-danger.btn-shadow {
  box-shadow: 0px 4px 10px #fad7d7; }

.btn-danger.light {
  background-color: rgba(235, 87, 87, 0.1);
  color: #EB5757;
  border-color: transparent; }
  .btn-danger.light:hover {
    background-color: #EB5757;
    color: #ffffff;
    box-shadow: 0px 4px 10px #fad7d7; }

.btn-rounded {
  border-radius: 30px !important; }

.btn-link {
  text-transform: uppercase;
  font-family: var(--font-family-base);
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  padding: 5px 0 5px 0; }

.shadow-primary {
  box-shadow: 0px 19px 29px rgba(220, 181, 145, 0.34); }

.btn-info {
  color: #fff; }
  .btn-info:hover {
    color: #fff; }

.btn-white {
  background-color: #fff;
  color: var(--primary); }
  .btn-white:hover {
    color: var(--primary); }

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary); }
  .btn-secondary:after {
    background-color: var(--primary); }

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary); }
  .btn-primary.disabled, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover); }
  .btn-primary:after {
    background-color: var(--secondary); }
  .btn-primary.light {
    background-color: var(--rgba-primary-1);
    color: var(--primary);
    border-color: var(--rgba-primary-1); }
    .btn-primary.light:hover {
      background-color: var(--primary);
      color: #ffffff; }
      .btn-primary.light:hover svg path {
        fill: #ffffff; }

.btn-link {
  color: var(--primary);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500; }
  .btn-link:hover {
    color: var(--primary-hover); }

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover {
    border-color: var(--primary);
    background-color: var(--primary);
    color: #ffffff; }
  .btn-outline-primary.text-primary:hover {
    color: #fff !important; }

.btn-light {
  background-color: #8EA5C8;
  color: #ffffff; }

.show.dropdown .dropdown-btn.btn-primary svg rect,
.show.dropdown .dropdown-btn.btn-primary svg path {
  fill: #fff; }

.action-dropdown .dropdown-menu .dropdown-item {
  font-size: 15px; }

.dropdown-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  border: 0; }
  .dropdown-btn.btn-primary svg rect,
  .dropdown-btn.btn-primary svg path {
    fill: var(--primary); }
  .dropdown-btn.btn-primary:hover svg rect,
  .dropdown-btn.btn-primary:hover svg path {
    fill: #fff; }

.badge {
  padding: 6px;
  font-weight: var(--headings-font-weight);
  background-color: var(--primary);
  color: white;
  border-radius: 4px;
  align-self: center;
  font-family: var(--font-family-title);
  font-size: 11px;
  min-width: 22px;
  height: 22px;
  text-align: center; }
  .badge:hover {
    color: #fff; }
  .badge.badge-primary {
    background-color: var(--primary); }
  .badge.badge-secondary {
    background-color: #362465; }
  .badge.badge-info {
    background-color: #00aeff; }
  .badge.badge-success {
    background-color: #11B011; }
  .badge.badge-warning {
    background-color: #FFBB69; }
  .badge.badge-danger {
    background-color: #EB5757; }
  .badge.badge-light {
    background-color: #F0EAFF; }

.table-responsive-sm {
  min-width: 48rem; }

.table {
  margin-bottom: 0px;
  font-weight: 400; }
  .table tr td,
  .table tr th {
    padding: 10px 20px; }
    @media only screen and (max-width: 575px) {
      .table tr td,
      .table tr th {
        padding: 5px; } }

.table.book-overview tr th {
  color: #1C2E9E;
  font-size: 16px;
  font-weight: 500;
  width: 200px; }

.table-striped thead tr th {
  padding-bottom: 15px !important; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F0F5FF; }

.table-striped tfoot {
  border-top: 2px solid #CFDBED; }
  .table-striped tfoot tr th {
    padding-top: 10px; }

.table > :not(:first-child) {
  border-top: 1px solid #dee2e6; }

.check-tbl {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: middle; }
  .check-tbl thead th {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 15px;
    color: #fff;
    background: var(--secondary); }
  .check-tbl tbody td {
    padding: 15px;
    font-weight: 500;
    color: var(--secondary); }
    .check-tbl tbody td img {
      width: 100px;
      border-radius: 6px; }
  .check-tbl .product-item-close {
    text-align: right; }
    .check-tbl .product-item-close a {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background: var(--bs-danger);
      display: inline-block;
      color: #fff;
      text-align: center;
      line-height: 40px; }
  .check-tbl .product-item-totle {
    min-width: 180px; }

.product-item-img {
  width: 100px;
  height: 100px; }

.table-style-1 {
  border-collapse: separate; }
  .table-style-1 thead th {
    font-size: 15px;
    padding: 10px 25px;
    color: var(--primary);
    font-weight: 500;
    white-space: nowrap;
    border: 0; }
    @media only screen and (max-width: 575px) {
      .table-style-1 thead th {
        padding: 10px 20px; } }
  .table-style-1 tbody tr {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .table-style-1 tbody tr:hover {
      background-color: rgba(124, 165, 200, 0.1); }
  .table-style-1 tbody td {
    padding: 10px 25px;
    vertical-align: middle;
    font-size: 13px;
    color: var(--title);
    border: 0; }
    @media only screen and (max-width: 575px) {
      .table-style-1 tbody td {
        padding: 10px 20px; } }
  .table-style-1.border-table tbody tr:not(:last-child) td {
    border-bottom: 1px solid rgba(142, 165, 200, 0.3); }
  .table-style-1.data-end-right thead th:last-child,
  .table-style-1.data-end-right tbody td:last-child {
    text-align: right; }

.table-notifications td.data {
  width: 250px; }
  @media only screen and (max-width: 767px) {
    .table-notifications td.data {
      width: 180px; } }

.trade-history-table .date {
  width: 180px;
  min-width: 180px; }
  @media only screen and (max-width: 575px) {
    .trade-history-table .date {
      width: 150px;
      min-width: 150px; } }

.accordion .accordion-item {
  border: 1px solid rgba(142, 165, 200, 0.5);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px; }
  @media only screen and (max-width: 1480px) {
    .accordion .accordion-item {
      margin-bottom: 15px; } }
  .accordion .accordion-item:last-child {
    margin-bottom: 0; }
  .accordion .accordion-item .accordion-header .accordion-button {
    padding: 18px 25px;
    color: var(--title);
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px;
    border-color: var(--primary);
    background-color: var(--card-bg); }
    .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
      box-shadow: none;
      background-color: var(--primary);
      color: #fff;
      border-radius: 0; }
      .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
        color: #fff; }
    .accordion .accordion-item .accordion-header .accordion-button:after {
      background-image: none;
      content: "\f107";
      font-family: "Font Awesome 6 free";
      font-weight: 900;
      color: var(--primary);
      width: auto;
      height: auto; }
    @media only screen and (max-width: 1480px) {
      .accordion .accordion-item .accordion-header .accordion-button {
        padding: 12px 20px; } }
    @media only screen and (max-width: 575px) {
      .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 16px; } }
  .accordion .accordion-item .accordion-body {
    background-color: var(--card-bg);
    color: var(--title);
    padding: 18px 25px; }
    @media only screen and (max-width: 1480px) {
      .accordion .accordion-item .accordion-body {
        padding: 12px 20px; } }

.accordion.style-1 .accordion-item {
  border: 2px solid var(--primary); }
  .accordion.style-1 .accordion-item .accordion-header .accordion-button {
    padding: 18px 30px; }
    @media only screen and (max-width: 767px) {
      .accordion.style-1 .accordion-item .accordion-header .accordion-button {
        padding: 8px 10px 10px 15px; } }
    .accordion.style-1 .accordion-item .accordion-header .accordion-button ul {
      width: 60%; }
      .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li span {
        font-size: 16px;
        font-weight: 400;
        color: var(--body-color); }
      .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li h6, .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li .h6 {
        font-weight: 500; }
      @media only screen and (max-width: 767px) {
        .accordion.style-1 .accordion-item .accordion-header .accordion-button ul {
          width: 80%; }
          .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li span {
            font-size: 12px; }
          .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li h6, .accordion.style-1 .accordion-item .accordion-header .accordion-button ul li .h6 {
            font-size: 13px; } }
    .accordion.style-1 .accordion-item .accordion-header .accordion-button:not(.collapsed) {
      color: #fff; }
      .accordion.style-1 .accordion-item .accordion-header .accordion-button:not(.collapsed) ul li h6, .accordion.style-1 .accordion-item .accordion-header .accordion-button:not(.collapsed) ul li .h6, .accordion.style-1 .accordion-item .accordion-header .accordion-button:not(.collapsed) ul li span {
        color: #fff; }
      .accordion.style-1 .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
        color: #fff;
        transform: unset;
        content: "\f068";
        background-color: var(--card-bg);
        color: var(--primary); }
    .accordion.style-1 .accordion-item .accordion-header .accordion-button:after {
      content: "\2b";
      font-family: "Font Awesome 6 free";
      font-weight: 900;
      background-color: var(--primary);
      color: #fff;
      text-align: center;
      width: 40px;
      border-radius: var(--border-radius-base);
      height: 40px;
      line-height: 40px; }
      @media only screen and (max-width: 767px) {
        .accordion.style-1 .accordion-item .accordion-header .accordion-button:after {
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          border-radius: 6px; } }

.dz-seprator {
  border-bottom: 1px solid #D9D9D9;
  margin: 80px 0; }
  @media only screen and (max-width: 1280px) {
    .dz-seprator {
      margin: 40px 0px 40px 0; } }
  @media only screen and (max-width: 991px) {
    .dz-seprator {
      margin: 30px 0px 30px 0; } }
  @media only screen and (max-width: 575px) {
    .dz-seprator {
      margin: 30px 0px 30px 0; } }

.pagination {
  margin: 0 -5px; }
  .pagination .page-link {
    height: 45px;
    width: 45px;
    border-radius: var(--border-radius-base) !important;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    color: var(--title);
    font-weight: 500;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    .pagination .page-link svg path {
      fill: var(--title); }
    .pagination .page-link:hover, .pagination .page-link:focus, .pagination .page-link.active {
      background-color: var(--primary);
      color: #ffffff; }
      .pagination .page-link:hover svg path, .pagination .page-link:focus svg path, .pagination .page-link.active svg path {
        fill: #ffffff; }
  .pagination .page-item {
    padding: 0 5px; }
  .pagination.text-center {
    justify-content: center; }

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li,
.list-check-circle li,
.list-check li,
.list-plus-circle li,
.list-arrow-left-circle li,
.list-arrow-right-circle li {
  position: relative;
  padding: 5px 5px 5px 30px;
  font-family: var(--font-family-title);
  font-size: 16px; }
  .list-circle li:before,
  .list-angle-double li:before,
  .list-square li:before,
  .list-square-check li:before,
  .list-check-circle li:before,
  .list-check li:before,
  .list-plus-circle li:before,
  .list-arrow-left-circle li:before,
  .list-arrow-right-circle li:before {
    position: absolute;
    left: 0;
    top: 8px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900; }

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before,
.list-check-circle.primary li:before,
.list-check.primary li:before,
.list-plus-circle.primary li:before,
.list-arrow-left-circle.primary li:before,
.list-arrow-right-circle.primary li:before {
  color: var(--primary); }

.list-circle.white li:before,
.list-angle-double.white li:before,
.list-square.white li:before,
.list-square-check.white li:before,
.list-check-circle.white li:before,
.list-check.white li:before,
.list-plus-circle.white li:before,
.list-arrow-left-circle.white li:before,
.list-arrow-right-circle.white li:before {
  color: #fff; }

.list-circle.gray li:before,
.list-angle-double.gray li:before,
.list-square.gray li:before,
.list-square-check.gray li:before,
.list-check-circle.gray li:before,
.list-check.gray li:before,
.list-plus-circle.gray li:before,
.list-arrow-left-circle.gray li:before,
.list-arrow-right-circle.gray li:before {
  color: #cbcbcb; }

ul.list-circle li {
  padding: 5px 5px 5px 22px; }
  ul.list-circle li:before {
    content: "\f111";
    font-size: 6px;
    top: 15px; }

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px; }

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%); }

ul.list-check li:before {
  content: "\f00c";
  font-size: 16px; }

ul.list-check-circle li:before {
  content: "\f058";
  font-size: 18px;
  font-weight: 500;
  top: 5px; }

ul.list-plus-circle li:before {
  content: "\f055";
  font-size: 18px;
  top: 5px; }

ul.list-arrow-left-circle li:before, ul.list-arrow-right-circle li:before {
  font-size: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: #888888;
  border-radius: 50px;
  text-align: center;
  line-height: 21px; }

ul.list-arrow-right-circle li:before {
  content: "\f054";
  padding-left: 1px; }

ul.list-arrow-left-circle li:before {
  content: "\f053";
  padding-right: 1px; }

ul.grid-2 {
  display: flex;
  flex-wrap: wrap; }
  ul.grid-2 li {
    width: 50%; }
  @media only screen and (max-width: 575px) {
    ul.grid-2 li {
      width: 100%; } }

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px; }
  .list-square-check li:before {
    content: "\f00c";
    top: 50%;
    height: 45px;
    width: 45px;
    line-height: 45px;
    background-color: var(--rgba-primary-1);
    color: #000;
    text-align: center;
    padding: 0 0 0 0;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    transform: translateY(-50%); }
  .list-square-check li:hover:before {
    background-color: var(--primary);
    color: #fff; }

.list-number-circle li {
  padding: 0px 15px 0px 70px;
  position: relative;
  margin-bottom: 30px; }
  .list-number-circle li:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    background: var(--primary);
    color: #fff;
    font-family: var(--font-family-title);
    font-size: 24px;
    font-weight: 600; }
  .list-number-circle li p {
    margin-bottom: 0; }

.list-square-box li {
  padding: 0px 0 0px 25px;
  position: relative;
  margin-bottom: 30px; }
  .list-square-box li:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    height: 12px;
    width: 12px;
    background: var(--primary); }
  .list-square-box li p {
    margin-bottom: 0;
    font-size: 17px; }

.list-check-box li {
  padding: 20px 10px 20px 70px;
  position: relative;
  margin-bottom: 10px;
  color: #fff;
  background: var(--secondary);
  font-size: 24px;
  font-family: var(--font-family-title);
  border-radius: var(--border-radius-base);
  text-transform: uppercase;
  margin-right: 40px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  font-weight: 500; }
  .list-check-box li:after {
    content: "\f058";
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    left: 30px;
    color: #fff;
    font-family: "Font Awesome 5 Free";
    top: 50%;
    transform: translateY(-50%); }
  .list-check-box li:hover {
    background: var(--primary);
    margin-right: 0; }
  @media only screen and (max-width: 1200px) {
    .list-check-box li {
      font-size: 20px; } }
  @media only screen and (max-width: 575px) {
    .list-check-box li {
      font-size: 18px;
      padding: 20px 10px 20px 55px; }
      .list-check-box li:after {
        left: 20px; } }

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent; }

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9); }

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #ffffff; }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: var(--primary); }

.lightimg {
  cursor: pointer; }

.progress-bx {
  overflow: hidden; }
  .progress-bx .progress-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; }
    .progress-bx .progress-info .title {
      margin-bottom: 0; }
    .progress-bx .progress-info .progress-value {
      margin-bottom: 0; }
  .progress-bx .progress {
    overflow: unset; }
    .progress-bx .progress .progress-bar {
      position: relative;
      overflow: unset; }

.scroltop {
  background: var(--primary);
  border-color: var(--primary);
  border-radius: 50px;
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff !important;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all .8s;
  -moz-transition: all .8s;
  -webkit-transition: all .8s;
  -ms-transition: all .8s;
  -o-transition: all .8s; }
  .scroltop:after {
    border: 1px dashed var(--primary);
    transform: scale(1.2);
    transition: all .5s;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: '';
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    -webkit-transition: -webkit-transform .2s,opacity .3s;
    -moz-transition: -moz-transform .2s,opacity .3s;
    transition: transform .2s,opacity .3s; }
  .scroltop:hover:after {
    -webkit-animation: spinAround 9s linear infinite;
    -moz-animation: spinAround 9s linear infinite;
    animation: spinAround 9s linear infinite; }
  @media only screen and (max-width: 575px) {
    .scroltop {
      height: 40px;
      width: 40px;
      line-height: 40px; } }

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg) scale(1.2); }
  to {
    -webkit-transform: rotate(360deg) scale(1.2); } }

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg) scale(1.2); }
  to {
    -moz-transform: rotate(360deg) scale(1.2); } }

@keyframes spinAround {
  from {
    transform: rotate(0deg) scale(1.2); }
  to {
    transform: rotate(360deg) scale(1.2); } }

.btn-next,
.btn-prev {
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 60px;
  height: 60px;
  color: var(--primary);
  background: #fff;
  font-size: 15px;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  line-height: 60px;
  border-radius: var(--border-radius-base);
  margin: 0 10px;
  display: inline-block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  @media only screen and (max-width: 767px) {
    .btn-next,
    .btn-prev {
      width: 45px;
      height: 45px;
      line-height: 45px; } }
  .btn-next:hover,
  .btn-prev:hover {
    background: var(--primary);
    color: #fff;
    box-shadow: 0px 12px 20px 0px var(--rgba-primary-3); }
  .btn-next:after,
  .btn-prev:after {
    content: none; }
  .btn-next i,
  .btn-prev i {
    font-size: 28px;
    line-height: inherit; }
    @media only screen and (max-width: 767px) {
      .btn-next i,
      .btn-prev i {
        font-size: 24px; } }

.swiper-btn-center-lr {
  position: relative; }
  .swiper-btn-center-lr .btn-next,
  .swiper-btn-center-lr .btn-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .swiper-btn-center-lr .btn-prev {
    left: -100px; }
  .swiper-btn-center-lr .btn-next {
    right: -100px; }
  .swiper-btn-center-lr:hover .btn-prev {
    left: 0px; }
  .swiper-btn-center-lr:hover .btn-next {
    right: 0px; }
  @media only screen and (max-width: 1400px) {
    .swiper-btn-center-lr .btn-prev {
      left: 0px; }
    .swiper-btn-center-lr .btn-next {
      right: 0px; } }

.widget {
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .widget {
      margin-bottom: 30px; } }

.widget-title {
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative; }
  @media only screen and (max-width: 1199px) {
    .widget-title {
      margin-bottom: 20px; } }
  .widget-title:after, .widget-title:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 5px;
    background: var(--primary); }
    @media only screen and (max-width: 1480px) {
      .widget-title:after, .widget-title:before {
        height: 4px; } }
  .widget-title:after {
    left: 0;
    width: 55px;
    border-radius: 0.6rem; }
  .widget-title:before {
    display: none;
    left: 60px;
    width: 7px; }
  .widget-title .title {
    position: relative;
    margin-bottom: 0px; }
    @media only screen and (max-width: 1199px) {
      .widget-title .title {
        font-size: 21px; } }
    @media only screen and (max-width: 767px) {
      .widget-title .title {
        font-size: 18px; } }

.search-bx .input-group {
  background: var(--rgba-primary-1);
  border-radius: 0.6rem; }

.search-bx form {
  margin-bottom: 10px; }

.search-bx .form-control, .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown .search-bx select {
  border-radius: 0;
  background-color: transparent;
  border: 0;
  z-index: 0 !important;
  padding: 6px 50px 6px 20px; }

.search-bx .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center; }

.search-bx button {
  height: 100%;
  width: 60px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: 0 0.6rem 0.6rem 0; }
  .search-bx button i {
    font-size: 12px; }

.download-file {
  background-image: var(--gradient);
  color: #ffffff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px; }
  .download-file .title {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600; }
  .download-file ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .download-file ul li a {
      background-color: #ffffff;
      color: #000;
      display: block;
      margin-bottom: 10px;
      border-radius: 4px;
      width: 100%;
      padding: 15px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .download-file ul li a i {
        width: 18px;
        height: 18px;
        position: relative; }
        .download-file ul li a i:after, .download-file ul li a i:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          left: 0;
          top: 0; }
        .download-file ul li a i:before {
          -webkit-animation: download1 2s ease infinite;
          animation: download1 2s ease infinite; }
      .download-file ul li a .text {
        display: inline;
        position: relative; }
      .download-file ul li a:hover .text {
        color: var(--primary); }

@-webkit-keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

@keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

.widget_contact {
  position: relative;
  padding: 50px 45px 45px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  overflow: hidden; }
  .widget_contact:before {
    content: '';
    position: absolute;
    width: 45%;
    height: 65%;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat; }
  .widget_contact .icon-bx i {
    font-size: 100px;
    color: var(--theme-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100px;
    margin-bottom: 15px; }
  .widget_contact img {
    margin-bottom: 20px; }
  .widget_contact h4, .widget_contact .h4, .widget_contact .comment-reply-title,
  .widget_contact .wp-block-search .wp-block-search__label, .wp-block-search .widget_contact .wp-block-search__label {
    font-size: 20px;
    font-weight: 600;
    color: var(--theme-text-color);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px; }
  .widget_contact .phone-number {
    position: relative;
    font-size: 28px;
    color: var(--theme-text-color);
    font-weight: 600;
    margin-bottom: 5px; }
    .widget_contact .phone-number a {
      color: var(--theme-text-color); }
  .widget_contact .email {
    position: relative;
    color: var(--theme-text-color);
    font-weight: 600;
    margin-bottom: 30px; }
    .widget_contact .email a {
      color: var(--theme-text-color); }

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px; }

.list-2 {
  display: table; }
  .list-2 li {
    width: 50% !important;
    float: left; }
    @media only screen and (max-width: 1200px) {
      .list-2 li {
        width: 100% !important; } }
    @media only screen and (max-width: 991px) {
      .list-2 li {
        width: 50% !important; } }
    @media only screen and (max-width: 575px) {
      .list-2 li {
        width: 100% !important; } }

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .wp-block-latest-posts li,
  .wp-block-categories-list li,
  .wp-block-archives-list li,
  .widget_categories ul li,
  .widget_archive ul li,
  .widget_meta ul li,
  .widget_pages ul li,
  .widget_recent_comments ul li,
  .widget_nav_menu ul li,
  .widget_recent_entries ul li,
  .widget_services ul li {
    padding-bottom: 1.125rem;
    margin-bottom: 0.8125rem;
    position: relative;
    padding: 0.5rem 0rem 0.5rem 0.9375rem;
    margin-bottom: 0;
    line-height: 1.25rem; }
    .wp-block-latest-posts li a,
    .wp-block-categories-list li a,
    .wp-block-archives-list li a,
    .widget_categories ul li a,
    .widget_archive ul li a,
    .widget_meta ul li a,
    .widget_pages ul li a,
    .widget_recent_comments ul li a,
    .widget_nav_menu ul li a,
    .widget_recent_entries ul li a,
    .widget_services ul li a {
      color: inherit;
      text-transform: capitalize;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
      .wp-block-latest-posts li a:before,
      .wp-block-categories-list li a:before,
      .wp-block-archives-list li a:before,
      .widget_categories ul li a:before,
      .widget_archive ul li a:before,
      .widget_meta ul li a:before,
      .widget_pages ul li a:before,
      .widget_recent_comments ul li a:before,
      .widget_nav_menu ul li a:before,
      .widget_recent_entries ul li a:before,
      .widget_services ul li a:before {
        content: "\f0da";
        font-family: "Font Awesome 5 Free";
        width: auto;
        height: auto;
        font-size: 14px;
        font-weight: 700;
        left: 0;
        top: 8px;
        color: var(--primary);
        position: absolute;
        background: transparent; }
      .wp-block-latest-posts li a:hover,
      .wp-block-categories-list li a:hover,
      .wp-block-archives-list li a:hover,
      .widget_categories ul li a:hover,
      .widget_archive ul li a:hover,
      .widget_meta ul li a:hover,
      .widget_pages ul li a:hover,
      .widget_recent_comments ul li a:hover,
      .widget_nav_menu ul li a:hover,
      .widget_recent_entries ul li a:hover,
      .widget_services ul li a:hover {
        color: var(--primary); }
    .wp-block-latest-posts li li,
    .wp-block-categories-list li li,
    .wp-block-archives-list li li,
    .widget_categories ul li li,
    .widget_archive ul li li,
    .widget_meta ul li li,
    .widget_pages ul li li,
    .widget_recent_comments ul li li,
    .widget_nav_menu ul li li,
    .widget_recent_entries ul li li,
    .widget_services ul li li {
      border-bottom: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      line-height: 1.375rem; }
      .wp-block-latest-posts li li:last-child,
      .wp-block-categories-list li li:last-child,
      .wp-block-archives-list li li:last-child,
      .widget_categories ul li li:last-child,
      .widget_archive ul li li:last-child,
      .widget_meta ul li li:last-child,
      .widget_pages ul li li:last-child,
      .widget_recent_comments ul li li:last-child,
      .widget_nav_menu ul li li:last-child,
      .widget_recent_entries ul li li:last-child,
      .widget_services ul li li:last-child {
        border-bottom: 0;
        padding-bottom: 0; }
      .wp-block-latest-posts li li:before,
      .wp-block-categories-list li li:before,
      .wp-block-archives-list li li:before,
      .widget_categories ul li li:before,
      .widget_archive ul li li:before,
      .widget_meta ul li li:before,
      .widget_pages ul li li:before,
      .widget_recent_comments ul li li:before,
      .widget_nav_menu ul li li:before,
      .widget_recent_entries ul li li:before,
      .widget_services ul li li:before {
        top: 0.5rem;
        left: 0; }
  .wp-block-latest-posts ul,
  .wp-block-categories-list ul,
  .wp-block-archives-list ul,
  .widget_categories ul ul,
  .widget_archive ul ul,
  .widget_meta ul ul,
  .widget_pages ul ul,
  .widget_recent_comments ul ul,
  .widget_nav_menu ul ul,
  .widget_recent_entries ul ul,
  .widget_services ul ul {
    padding-left: 1.125rem;
    margin-top: 0.5rem;
    margin-left: -0.9375rem; }

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem; }

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.2rem !important;
  line-height: 1.3 !important; }

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important; }

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left; }

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important; }

.widget_recent_comments ul {
  margin-top: -0.625rem; }
  .widget_recent_comments ul li {
    padding-left: 1.875rem;
    line-height: 1.625rem; }
    .widget_recent_comments ul li a:hover {
      color: inherit; }
    .widget_recent_comments ul li a:before {
      content: none; }
    .widget_recent_comments ul li:before {
      content: "\f27a";
      font-family: "Font Awesome 5 Free";
      background: transparent;
      width: auto;
      height: auto;
      position: absolute;
      left: 0;
      top: 0.625rem;
      font-size: 1.125rem;
      font-weight: 500; }

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none; }

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em; }

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05); }
  .widget_calendar th:last-child,
  .widget_calendar tfoot td:last-child {
    border: 0; }

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1); }
  .widget_calendar tr:nth-child(2n+2) {
    background-color: var(--rgba-primary-05); }

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%; }

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary); }

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit; }

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem; }

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #fff; }

.wp-block-calendar table caption {
  color: #fff; }

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1); }

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1); }
  footer .widget_calendar tr:nth-child(2n+2) {
    background-color: rgba(255, 255, 255, 0.05); }

.wp-calendar-nav {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center; }
  .wp-calendar-nav span {
    padding: 0.3125rem 1.25rem;
    width: 44%;
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2); }
    .wp-calendar-nav span a {
      color: #fff; }

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #CCC; }

.list-2-column ul {
  display: table; }
  .list-2-column ul li {
    width: 50%;
    float: left; }

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #CCC; }

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #CCC; }
  .widget_text select option {
    width: 100%; }

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem; }

/*widget rss  */
.rsswidget img {
  display: inherit; }

.widget-title .rsswidget {
  display: inline-block; }

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title); }

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  ul a.rsswidget:hover {
    color: var(--primary); }

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px; }

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400; }

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0; }
  .widget_rss ul li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; }

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  margin-left: -0.3rem;
  margin-right: -0.3rem; }

.widget_gallery.gallery-grid-2 li {
  width: 50%; }

.widget_gallery.gallery-grid-4 li {
  width: 25%; }

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  padding: 5px; }
  .widget_gallery li img {
    display: inline-block;
    width: 100%; }
  .widget_gallery li:nth-child(3n-3) {
    margin-right: 0; }
  .widget_gallery li:hover {
    opacity: 0.7; }
  .widget_gallery li a {
    display: inline-block; }

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table; }
  .widget_tag_cloud .tagcloud a {
    position: relative;
    padding: 8px 15px;
    display: inline-block;
    margin: 0 10px 10px 0;
    font-size: 14px;
    color: var(--primary);
    line-height: 1.4;
    border: 0;
    background: var(--rgba-primary-1);
    border-radius: 0.6rem; }
    @media only screen and (max-width: 1480px) {
      .widget_tag_cloud .tagcloud a {
        padding: 6px 18px;
        margin: 0 10px 10px 0; } }
    @media only screen and (max-width: 1199px) {
      .widget_tag_cloud .tagcloud a {
        padding: 6px 14px;
        margin: 0 8px 8px 0; } }
    .widget_tag_cloud .tagcloud a:hover {
      box-shadow: 0px 5px 12px var(--rgba-primary-4);
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
      color: #fff; }
    .widget_tag_cloud .tagcloud a span {
      display: inline-block; }

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  margin-bottom: 20px;
  transition: all 0.5s; }
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 92px;
    min-width: 92px;
    margin-right: 15px;
    overflow: hidden;
    border-radius: var(--border-radius-base); }
    @media only screen and (max-width: 1199px) {
      .recent-posts-entry .widget-post-bx .widget-post .dz-media {
        width: 75px;
        min-width: 75px; } }
  .recent-posts-entry .widget-post-bx .widget-post .dz-meta {
    margin-bottom: 0; }
    .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
      line-height: 1; }
      .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
        font-size: 15px; }
  .recent-posts-entry .widget-post-bx .widget-post .title {
    line-height: 1.3;
    font-family: 'Poppins';
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
    font-size: 16px; }
  .recent-posts-entry .widget-post-bx .widget-post .post-date {
    font-size: 14px; }
  .recent-posts-entry .widget-post-bx .widget-post:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.service_menu_nav {
  background: #222222;
  position: relative;
  padding: 40px 45px;
  z-index: 1;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 767px) {
    .service_menu_nav {
      padding: 40px; } }
  .service_menu_nav:after, .service_menu_nav:before {
    content: '';
    position: absolute;
    height: 50%;
    z-index: -1;
    bottom: 0;
    right: 0;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background-size: cover;
    background-repeat: no-repeat; }
  .service_menu_nav:after {
    width: 17%; }
  .service_menu_nav:before {
    width: 40%;
    opacity: 0.2; }
  .service_menu_nav ul li {
    padding: 0; }
    .service_menu_nav ul li a {
      display: block;
      position: relative;
      padding: 15px 0;
      margin-bottom: 10px;
      color: #fff;
      font-size: 18px;
      z-index: 1;
      font-weight: 500;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
      @media only screen and (max-width: 767px) {
        .service_menu_nav ul li a {
          padding: 12px 0;
          margin-bottom: 5px;
          font-size: 16px; } }
      .service_menu_nav ul li a:before, .service_menu_nav ul li a:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        z-index: -1;
        background-color: var(--primary);
        opacity: 0;
        transform: translateX(15px);
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
      .service_menu_nav ul li a:after {
        width: 100%;
        width: calc( 100% + 70px);
        right: 0px; }
        @media only screen and (max-width: 767px) {
          .service_menu_nav ul li a:after {
            width: calc( 100% + 50px); } }
      .service_menu_nav ul li a:before {
        width: 7px;
        right: -15px; }
    .service_menu_nav ul li:hover a, .service_menu_nav ul li.current-menu-item a, .service_menu_nav ul li.active a {
      color: var(--theme-text-color); }
      .service_menu_nav ul li:hover a:after, .service_menu_nav ul li:hover a:before, .service_menu_nav ul li.current-menu-item a:after, .service_menu_nav ul li.current-menu-item a:before, .service_menu_nav ul li.active a:after, .service_menu_nav ul li.active a:before {
        transform: translateX(0px);
        opacity: 1; }
  .service_menu_nav svg {
    position: absolute;
    bottom: -23px;
    left: -20px;
    transform: rotateY(180deg);
    height: 50px;
    width: 175px; }

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px; }
  .wp-block-categories-dropdown select:after,
  .wp-block-archives-dropdown select:after {
    content: "\f078";
    font-weight: 900;
    font-family: 'Line Awesome Free';
    position: absolute;
    right: 50px;
    left: auto;
    top: 50%; }

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px; }

.post-tags a {
  margin-right: 10px;
  display: inline-block; }

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%; }
  .widget_profile .dz-media img {
    border-radius: var(--border-radius-base); }

.widget_profile p {
  font-size: 16px; }

.widget-newslatter .form-control, .widget-newslatter .wp-block-categories-dropdown select, .wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select, .wp-block-archives-dropdown .widget-newslatter select {
  background-color: #f3f4f6;
  border-radius: var(--border-radius);
  border: 0; }

.widget-newslatter p {
  font-size: 16px; }

.widget-newslatter button {
  width: 60px;
  height: 60px;
  padding: 0;
  justify-content: center; }

.widget-newslatter .input-group {
  margin-bottom: 0; }

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: var(--primary); }
  .shop-widget .search-bx,
  .widget.style-1 .search-bx {
    margin-bottom: 0; }
    .shop-widget .search-bx .form-control, .shop-widget .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown .shop-widget .search-bx select,
    .shop-widget .search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown .shop-widget .search-bx select,
    .widget.style-1 .search-bx .form-control,
    .widget.style-1 .search-bx .wp-block-categories-dropdown select,
    .wp-block-categories-dropdown .widget.style-1 .search-bx select,
    .widget.style-1 .search-bx .wp-block-archives-dropdown select,
    .wp-block-archives-dropdown .widget.style-1 .search-bx select {
      background-color: #f3f4f6;
      box-shadow: unset; }
    .shop-widget .search-bx .input-group,
    .widget.style-1 .search-bx .input-group {
      margin-bottom: 0; }
  @media only screen and (max-width: 1280px) {
    .shop-widget,
    .widget.style-1 {
      padding: 25px; } }

.side-bar h1, .side-bar .h1 {
  font-size: 2rem; }

.side-bar h2, .side-bar .h2 {
  font-size: 1.625rem; }

.side-bar h3, .side-bar .h3 {
  font-size: 1.5rem; }

.side-bar h4, .side-bar .h4, .side-bar .comment-reply-title,
.side-bar .wp-block-search .wp-block-search__label, .wp-block-search .side-bar .wp-block-search__label {
  font-size: 1.25rem; }

.side-bar h5, .side-bar .h5 {
  font-size: 1rem; }

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1, .sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget .wp-block-group__inner-container > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-group__inner-container > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget .wp-block-group__inner-container > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-group__inner-container > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px; }

.comment-reply-title,
.wp-block-search .wp-block-search__label {
  color: var(--title);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px; }

.wp-block-group:where(.has-background) {
  padding: 1.25em 2.375em; }

.widget_working ul li {
  display: block;
  margin-bottom: 15px; }
  .widget_working ul li .time {
    font-weight: 600;
    color: var(--primary);
    display: block; }

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 45px; }
  @media only screen and (max-width: 1280px) {
    .widget_getintuch ul li {
      padding-left: 35px; } }
  @media only screen and (max-width: 1199px) {
    .widget_getintuch ul li {
      margin-bottom: 25px;
      padding-left: 35px; } }
  .widget_getintuch ul li span {
    font-size: 18px;
    color: var(--title);
    font-weight: 500; }
  .widget_getintuch ul li h5, .widget_getintuch ul li .h5 {
    font-size: 20px;
    line-height: 1.33;
    margin-bottom: 5px; }
  .widget_getintuch ul li i {
    position: absolute;
    font-size: 24px;
    line-height: 1;
    left: 0;
    top: 2px;
    color: var(--primary); }
    @media only screen and (max-width: 575px) {
      .widget_getintuch ul li i {
        font-size: 20px; } }
  @media only screen and (max-width: 991px) {
    .widget_getintuch ul li p {
      font-size: 14px; } }

.modal .close-btn {
  height: 40px;
  width: 40px;
  background-color: #EB5757;
  color: white;
  text-align: center;
  display: block;
  line-height: 40px;
  border-radius: 10px;
  position: fixed;
  top: 1rem;
  right: 15px;
  left: auto;
  cursor: pointer;
  font-size: 20px;
  opacity: 0;
  transition: opacity .3s ease-in-out .3s; }

.modal.show .close-btn {
  opacity: 1; }

.modal .modal-content {
  border: 0;
  border-radius: 10px; }

.modal .modal-header {
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 0;
  padding-top: 30px; }
  @media only screen and (max-width: 575px) {
    .modal .modal-header {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 20px; } }
  .modal .modal-header .modal-title {
    font-size: 18px; }

.modal .modal-header + .modal-body {
  padding-top: 20px; }

.modal .modal-footer {
  border-top: 0; }

.modal .modal-body {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  padding-top: 30px; }
  @media only screen and (max-width: 575px) {
    .modal .modal-body {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 20px;
      padding-bottom: 20px; } }

.modal-backdrop {
  background-color: #1A2943; }
  .modal-backdrop.show {
    opacity: 0.7; }

.modal-content {
  background-color: var(--card-bg); }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 412px; } }

.modal-dialog .modal-body .form-group {
  margin-bottom: 20px; }

.modal-dialog .modal-body .form-select, .modal-dialog .modal-body select {
  border-color: var(--border-color);
  border-radius: var(--border-radius-base);
  background-color: var(--input-bg);
  margin-bottom: 20px;
  box-shadow: 0px 7px 15px 0 rgba(61, 70, 83, 0.1);
  font-size: 16px;
  color: var(--title); }

.modal-dialog .modal-body .fixed-text .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  color: #000;
  z-index: 9; }

.modal-dialog .modal-body .fixed-text .form-control, .modal-dialog .modal-body .fixed-text .wp-block-categories-dropdown select, .wp-block-categories-dropdown .modal-dialog .modal-body .fixed-text select,
.modal-dialog .modal-body .fixed-text .wp-block-archives-dropdown select, .wp-block-archives-dropdown .modal-dialog .modal-body .fixed-text select {
  border-radius: var(--border-radius-base) !important; }

.modal-dialog .modal-body .form-control, .modal-dialog .modal-body .wp-block-categories-dropdown select, .wp-block-categories-dropdown .modal-dialog .modal-body select,
.modal-dialog .modal-body .wp-block-archives-dropdown select, .wp-block-archives-dropdown .modal-dialog .modal-body select {
  font-size: 16px;
  font-weight: 400;
  height: 48px; }
  .modal-dialog .modal-body .form-control::placeholder, .modal-dialog .modal-body .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .modal-dialog .modal-body select::placeholder,
  .modal-dialog .modal-body .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .modal-dialog .modal-body select::placeholder {
    color: #8EA5C8; }
  @media only screen and (max-width: 575px) {
    .modal-dialog .modal-body .form-control, .modal-dialog .modal-body .wp-block-categories-dropdown select, .wp-block-categories-dropdown .modal-dialog .modal-body select,
    .modal-dialog .modal-body .wp-block-archives-dropdown select, .wp-block-archives-dropdown .modal-dialog .modal-body select {
      font-size: 15px; } }

.modal-dialog .modal-body .custom-nouislider {
  margin-bottom: 60px;
  margin-top: 30px; }

.modal-dialog .modal-body .amount-list {
  margin-top: 30px; }
  .modal-dialog .modal-body .amount-list ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; }
    .modal-dialog .modal-body .amount-list ul li span {
      font-size: 14px; }
    .modal-dialog .modal-body .amount-list ul li h5, .modal-dialog .modal-body .amount-list ul li .h5 {
      font-size: 14px;
      font-weight: 500;
      color: #000; }
    .modal-dialog .modal-body .amount-list ul li:last-child {
      margin-bottom: 0; }

.modal-dialog .modal-body .count-down .text-dashed {
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  text-decoration: 1px underline dashed;
  text-underline-offset: 3px; }

.modal-dialog .modal-body .count-down h5, .modal-dialog .modal-body .count-down .h5 {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary); }

.modal-dialog .modal-body .countdown-radio {
  display: flex;
  margin-top: 1rem; }
  .modal-dialog .modal-body .countdown-radio .countdown-check {
    display: flex;
    justify-content: space-between; }
  .modal-dialog .modal-body .countdown-radio form {
    width: 70%; }

.modal-dialog .btn {
  font-size: 18px;
  font-weight: 600;
  padding: 9.5px 20px; }
  @media only screen and (max-width: 575px) {
    .modal-dialog .btn {
      font-size: 16px; } }

.modal-dialog .wallet-transfer .spot-wallet {
  border: 1px solid var(--border-color);
  box-shadow: 0px 7px 15px rgba(61, 70, 83, 0.1);
  border-radius: var(--border-radius-base);
  padding: 12px 20px; }
  .modal-dialog .wallet-transfer .spot-wallet label {
    color: var(--primary);
    font-size: 12px; }
  .modal-dialog .wallet-transfer .spot-wallet .form-control, .modal-dialog .wallet-transfer .spot-wallet .wp-block-categories-dropdown select, .wp-block-categories-dropdown .modal-dialog .wallet-transfer .spot-wallet select,
  .modal-dialog .wallet-transfer .spot-wallet .wp-block-archives-dropdown select, .wp-block-archives-dropdown .modal-dialog .wallet-transfer .spot-wallet select {
    box-shadow: none;
    border: 0;
    font-weight: 500;
    color: #000;
    padding: 0;
    background: transparent;
    height: 22px; }

.modal-dialog .wallet-transfer .exchange-icon {
  height: 43px;
  width: 43px;
  background-color: var(--primary);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  line-height: 43px;
  position: relative;
  z-index: 1; }
  .modal-dialog .wallet-transfer .exchange-icon:after {
    content: "";
    position: absolute;
    top: 22px;
    left: -97px;
    height: 2px;
    width: 227px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, #18A594 50.08%, rgba(255, 255, 255, 0) 100%);
    z-index: -1; }

.modal-dialog .wallet-transfer .available {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .modal-dialog .wallet-transfer .available span {
    color: var(--primary); }

.deposit-crypto-modal .modal-svg {
  margin: 28px 0px 40px 0px; }

.deposit-crypto-modal .modal-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 301px;
  border: 0; }
  .deposit-crypto-modal .modal-content .modal-content-title {
    font-size: 24px; }

.deposit-crypto-scanner .deposit-crypto-scanner-body .modal-svg {
  position: absolute;
  right: 14px;
  top: 11px;
  margin: 0 -7px; }
  .deposit-crypto-scanner .deposit-crypto-scanner-body .modal-svg svg {
    margin: 0 7px; }

.profile .profile-body .modal-svg {
  position: absolute;
  right: 14px;
  top: 11px;
  margin: 0 -7px; }
  .profile .profile-body .modal-svg svg {
    margin: 0 7px; }

.profile h4, .profile .h4, .profile .comment-reply-title,
.profile .wp-block-search .wp-block-search__label, .wp-block-search .profile .wp-block-search__label {
  font-size: 28px;
  font-weight: 600; }

.profile-image-modal .profile {
  position: relative;
  width: 160px;
  height: 160px;
  margin: 0 auto 30px;
  padding: 0; }
  .profile-image-modal .profile .profile-progress {
    width: 100%;
    height: 100%; }
  .profile-image-modal .profile .profile-blank {
    background: #DCDCDC;
    width: 135px;
    height: 135px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.profile-image-modal .upload-file {
  border: 2px dashed #CFDBED;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8EA5C8;
  font-size: 15px;
  height: 60px;
  margin-bottom: 40px; }

.profile-image-modal .modal-body {
  padding-bottom: 40px; }

@media only screen and (max-width: 575px) {
  .profile-image-modal .profile {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px; }
    .profile-image-modal .profile .profile-blank {
      width: 100px;
      height: 100px; }
  .profile-image-modal .upload-file {
    margin-bottom: 20px; }
  .profile-image-modal .modal-body {
    padding-bottom: 30px; } }

@media (min-width: 767px) {
  .megamodal .modal-dialog {
    max-width: 650px; } }

.megamodal .modal-dialog > .modal-content {
  background: transparent; }

.megamodal .modal-body {
  padding: 0; }

.megamodal .modal-svg {
  margin: 0 auto 40px; }
  @media only screen and (max-width: 575px) {
    .megamodal .modal-svg {
      margin: 0 auto 25px; } }

.megamodal .modal-content {
  padding: 0; }
  .megamodal .modal-content .modal-content {
    padding: 40px 20px; }
    @media only screen and (max-width: 575px) {
      .megamodal .modal-content .modal-content {
        padding: 30px 20px; } }

.language-modal .modal-dialog {
  max-width: 500px; }

.language-modal .modal-body {
  padding-left: 10px;
  padding-right: 10px; }
  .language-modal .modal-body .modal-title {
    margin-left: 26px; }
    @media only screen and (max-width: 575px) {
      .language-modal .modal-body .modal-title {
        margin-left: 20px; } }

.language-list {
  display: flex;
  flex-wrap: wrap; }
  .language-list li {
    width: calc(50% - 20px);
    padding: 12px 18px;
    margin: 0 10px;
    border-width: 1px;
    border-color: transparent transparent var(--border-color) transparent;
    border-style: solid; }
    @media only screen and (max-width: 575px) {
      .language-list li {
        padding: 8px 10px; } }
    .language-list li:last-child {
      border-color: transparent transparent transparent transparent; }
    .language-list li a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--title);
      font-size: 15px; }
      @media only screen and (max-width: 575px) {
        .language-list li a {
          font-size: 13px; }
          .language-list li a svg {
            width: 15px;
            height: 15px; } }
      .language-list li a svg path {
        fill: var(--body-color); }
    .language-list li.active {
      border-color: var(--primary);
      border-radius: 12px; }
      .language-list li.active svg path {
        fill: var(--primary); }

.nav-tabs.card-tabs {
  border: 0;
  margin: 0 -5px; }
  .nav-tabs.card-tabs .nav-item {
    flex: 1;
    padding: 0 5px; }
  .nav-tabs.card-tabs .nav-link {
    text-align: center;
    padding: 12px 20px;
    border: 0;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 500; }
    .nav-tabs.card-tabs .nav-link.primary {
      background-color: var(--rgba-primary-1);
      color: var(--primary); }
    .nav-tabs.card-tabs .nav-link.danger {
      background-color: rgba(235, 87, 87, 0.1);
      color: #EB5757; }
    .nav-tabs.card-tabs .nav-link.active.primary {
      background-color: var(--primary);
      color: #ffffff;
      box-shadow: 0px 4px 10px var(--rgba-primary-2); }
    .nav-tabs.card-tabs .nav-link.active.danger {
      background-color: #EB5757;
      box-shadow: 0px 4px 10px #fad7d7;
      color: #ffffff; }

.nav-tabs.style-1 {
  background-color: var(--rgba-primary-1);
  border-radius: 10px;
  border: 0;
  padding: 5px; }
  .nav-tabs.style-1 .nav-item {
    flex: 1; }
  .nav-tabs.style-1 .nav-link {
    border: 0;
    border-radius: 7px;
    font-size: 13px;
    color: var(--title);
    text-transform: uppercase;
    padding: 5px 12px;
    text-align: center;
    font-weight: 500; }
    @media only screen and (max-width: 1400px) {
      .nav-tabs.style-1 .nav-link {
        font-size: 11px; } }
    .nav-tabs.style-1 .nav-link.active {
      color: var(--primary);
      background-color: var(--card-bg); }

.nav-tabs.border-tabs {
  border-bottom: 1px solid var(--border-color); }
  .nav-tabs.border-tabs .nav-link {
    border: 0;
    font-size: 15px;
    color: var(--body-color);
    margin-bottom: 0;
    padding: 15px 25px;
    position: relative;
    background-color: transparent;
    text-align: center;
    font-weight: 500; }
    .nav-tabs.border-tabs .nav-link:after {
      content: "";
      height: 4px;
      position: absolute;
      bottom: 0;
      width: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--primary);
      -webkit-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s; }
    .nav-tabs.border-tabs .nav-link.active {
      color: var(--primary); }
      .nav-tabs.border-tabs .nav-link.active:after {
        width: 100%; }
  .nav-tabs.border-tabs.wide .nav-item {
    flex: 1; }
  @media only screen and (max-width: 767px) {
    .nav-tabs.border-tabs .nav-link {
      padding: 10px 14px; } }
  @media only screen and (max-width: 767px) {
    .nav-tabs.border-tabs .nav-link {
      font-size: 12px;
      padding: 10px 9px; }
      .nav-tabs.border-tabs .nav-link::after {
        height: 2px; } }

.nav-tabs.setting-tabs {
  border: 0;
  display: block; }
  .nav-tabs.setting-tabs label {
    font-size: 14px;
    color: var(--primary);
    text-transform: uppercase;
    border-bottom: 1px solid var(--border-color);
    display: block;
    padding: 10px 0;
    font-weight: 500;
    margin-bottom: 5px; }
  .nav-tabs.setting-tabs .nav-item {
    margin-bottom: 15px; }
  .nav-tabs.setting-tabs .nav-link {
    border: 0;
    border-radius: 10px;
    display: flex;
    margin: 0 -5px;
    font-weight: 500;
    color: var(--title);
    font-size: 1rem;
    align-items: center;
    position: relative;
    padding: 10px 10px; }
    .nav-tabs.setting-tabs .nav-link:after {
      content: "\f054";
      position: absolute;
      top: 50%;
      right: 0;
      right: 15px;
      transform: translateY(-50%);
      font-family: 'FontAwesome';
      color: var(--title); }
    .nav-tabs.setting-tabs .nav-link .tb-icon {
      height: 35px;
      width: 35px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: var(--rgba-primary-1); }
      .nav-tabs.setting-tabs .nav-link .tb-icon svg path {
        fill: var(--primary); }
    .nav-tabs.setting-tabs .nav-link.active {
      background-color: var(--primary);
      color: #ffffff;
      box-shadow: 0px 4px 10px var(--rgba-primary-2); }
      .nav-tabs.setting-tabs .nav-link.active::after {
        color: #ffffff; }
      .nav-tabs.setting-tabs .nav-link.active .tb-icon {
        background-color: #ffffff; }

.nav-tabs.style-2 {
  border: 0; }
  .nav-tabs.style-2 .nav-item {
    margin: 0 4px; }
  .nav-tabs.style-2 .nav-link {
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: var(--title);
    background-color: #F4F6FA; }
    .nav-tabs.style-2 .nav-link.active {
      color: #ffffff;
      background-color: var(--primary); }
  @media only screen and (max-width: 575px) {
    .nav-tabs.style-2 .nav-item {
      margin: 0 3px; }
    .nav-tabs.style-2 .nav-link {
      padding: 4px 8px;
      font-size: 12px;
      border-radius: 5px; } }

.nav-tabs.style-3 {
  border: 0;
  margin: 0 -10px; }
  .nav-tabs.style-3 .nav-item {
    padding: 0 10px; }
  .nav-tabs.style-3 .nav-link {
    border: 2px solid var(--primary);
    font-size: 18px;
    border-radius: 10px;
    font-weight: 600;
    color: var(--title);
    margin-bottom: 0;
    padding: 14px 24px;
    box-shadow: 0px 15px 15px 0px #18A5941A; }
    .nav-tabs.style-3 .nav-link.active {
      background-color: var(--primary);
      color: #ffffff; }
  .nav-tabs.style-3.tab-sm .nav-link {
    font-size: 15px;
    padding: 10px 20px;
    border-width: 1px;
    border-radius: 8px;
    box-shadow: none; }
  @media only screen and (max-width: 991px) {
    .nav-tabs.style-3 .nav-link {
      padding: 8px 15px;
      font-size: 15px; } }
  @media only screen and (max-width: 767px) {
    .nav-tabs.style-3 {
      margin: 0 -5px; }
      .nav-tabs.style-3 .nav-item {
        padding: 0 5px; }
      .nav-tabs.style-3 .nav-link {
        padding: 8px 12px;
        font-size: 13px;
        border-radius: 8px; }
      .nav-tabs.style-3.tab-sm .nav-link {
        font-size: 13px;
        padding: 6px 15px; } }
  @media only screen and (max-width: 575px) {
    .nav-tabs.style-3 .nav-link {
      padding: 8px 8px;
      font-size: 12px; } }

.nav-tabs-card .nav-tabs.border-tabs {
  margin-top: -10px; }
  @media only screen and (max-width: 575px) {
    .nav-tabs-card .nav-tabs.border-tabs {
      margin-left: -15px;
      margin-right: -15px; } }
  .nav-tabs-card .nav-tabs.border-tabs .nav-link {
    padding: 25px 20px; }
    @media only screen and (max-width: 1280px) {
      .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        padding: 20px 15px; } }
    @media only screen and (max-width: 767px) {
      .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        font-size: 14px;
        padding: 15px 12px; } }
    @media only screen and (max-width: 575px) {
      .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        font-size: 13px;
        padding: 12px 10px; } }

@media only screen and (min-width: 991px) {
  .order-history-tabs.nav-tabs.tab-sm .nav-link {
    padding: 12px 25px; } }

.dz-social-icon li {
  display: inline-block; }
  .dz-social-icon li a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: var(--border-radius-base); }

.dz-social-icon.space-10 ul {
  margin-left: -5px;
  margin-right: -5px; }
  .dz-social-icon.space-10 ul li {
    padding-left: 5px;
    padding-right: 5px; }

.dz-social-icon.transparent li a {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff; }
  .dz-social-icon.transparent li a:hover {
    background-color: var(--primary); }

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@-webkit-keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes upToRight {
  49% {
    -moz-transform: translateX(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@-webkit-keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes leftToRight {
  49% {
    -moz-transform: translateX(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px); }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px); }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px); }
  to {
    -webkit-transform: translate(0);
    transform: translate(0); } }

@keyframes movedelement2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px); }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px); }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px); }
  to {
    -webkit-transform: translate(0);
    transform: translate(0); } }

@keyframes movedelement3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px); }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px); }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px); }
  to {
    -webkit-transform: translate(0);
    transform: translate(0); } }

@keyframes movedelement4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px); }
  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px); }
  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px); }
  to {
    -webkit-transform: translate(0);
    transform: translate(0); } }

@keyframes movedelement5 {
  0% {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%); }
  25% {
    -webkit-transform: translate(10px, calc(-50% + 10px));
    transform: translate(10px, calc(-50% + 10px)); }
  50% {
    -webkit-transform: translate(5px, calc(-50% + 5px));
    transform: translate(5px, calc(-50% + 5px)); }
  75% {
    -webkit-transform: translate(10px, calc(-50% + -5px));
    transform: translate(10px, calc(-50% + -5px)); }
  to {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%); } }

@-moz-keyframes spin {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(-45deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg); } }

@keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg); } }

@-moz-keyframes spin2 {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(45deg); } }

@-webkit-keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg); } }

@keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg); } }

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg); }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg); }
  20% {
    transform: translate(-3px, 0px) rotate(1deg); }
  30% {
    transform: translate(3px, 2px) rotate(0deg); }
  40% {
    transform: translate(1px, -1px) rotate(1deg); }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg); }
  60% {
    transform: translate(-3px, 1px) rotate(0deg); }
  70% {
    transform: translate(3px, 1px) rotate(-1deg); }
  80% {
    transform: translate(-1px, -1px) rotate(1deg); }
  90% {
    transform: translate(1px, 2px) rotate(0deg); }
  100% {
    transform: translate(1px, -2px) rotate(-1deg); } }

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0; }
  .dz-divider.divider-2px {
    height: 2px; }
  .dz-divider.divider-3px {
    height: 2px; }
  .dz-divider.divider-4px {
    height: 2px; }
  .dz-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .dz-divider.icon-left {
    margin-left: 40px; }
    .dz-divider.icon-left i {
      left: -40px; }
  .dz-divider.icon-right {
    margin-right: 40px; }
    .dz-divider.icon-right i {
      right: -40px; }
  .dz-divider.icon-center i {
    left: 50%;
    margin-left: -5px; }

.bg-gray-dark {
  background-color: #d3d3d3; }

.range-slider.style-1 {
  padding: 10px 15px 40px; }
  .range-slider.style-1 .noUi-horizontal {
    height: 8px; }
    .range-slider.style-1 .noUi-horizontal .noUi-handle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 0;
      background-color: var(--secondary);
      box-shadow: none;
      top: -9px; }
      .range-slider.style-1 .noUi-horizontal .noUi-handle:before, .range-slider.style-1 .noUi-horizontal .noUi-handle:after {
        content: none; }
      .range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip {
        border: 0px;
        background: var(--primary);
        color: white;
        border-radius: 4px;
        font-size: 13px;
        bottom: -35px;
        font-weight: 600;
        padding: 2px 5px; }
        .range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip:after {
          content: "";
          position: absolute;
          background-color: inherit;
          width: 10px;
          height: 10px;
          top: 4px;
          margin-left: -4px;
          left: 50%;
          transform: rotate(45deg) translate(-50%, -50%); }
  .range-slider.style-1 .noUi-target {
    background: #F0EEFF;
    border-radius: 4px;
    border: 0;
    box-shadow: none; }
    .range-slider.style-1 .noUi-target .noUi-connect {
      background: var(--secondary); }

.custom-nouislider .noUi-marker-horizontal.noUi-marker {
  display: none; }

.custom-nouislider .noUi-handle.noUi-handle-lower:after, .custom-nouislider .noUi-handle.noUi-handle-lower:before {
  display: none; }

.custom-nouislider .noUi-target {
  border-radius: 4px;
  border: 0;
  box-shadow: none; }

.custom-nouislider .noUi-connect {
  background: var(--primary);
  border-radius: 4px; }

.custom-nouislider .noUi-pips-horizontal {
  font-size: 11px;
  font-weight: 500;
  color: #7E87BF;
  padding: 0;
  height: 30px;
  left: 2px;
  width: calc(100% - 10px); }

.custom-nouislider .noUi-connects {
  border-radius: 4px;
  background-color: #dde4ee; }

.custom-nouislider .noUi-horizontal {
  height: 4px; }
  .custom-nouislider .noUi-horizontal .noUi-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid var(--primary);
    right: -7px; }

.custom-nouislider .noUi-value:after {
  content: 'x';
  position: absolute;
  font-size: 13px;
  color: var(--body-color);
  top: 50%;
  right: -7px;
  transform: translateY(-50%); }

.dropdown {
  cursor: pointer; }
  .dropdown.show .dropdown-toggle {
    background-color: var(--primary); }
    .dropdown.show .dropdown-toggle svg path {
      fill: #fff; }
  .dropdown.profile-dropdown .dropdown-menu {
    margin-top: 15px;
    width: 200px; }

.dropdown-menu {
  box-shadow: 0px 10px 40px rgba(21, 50, 93, 0.1);
  background-color: var(--card-bg);
  border: 2px solid var(--primary);
  border-radius: 10px; }
  .dropdown-menu .dropdown-item {
    padding: 8px 25px;
    font-size: 16px;
    color: var(--title); }
    @media only screen and (max-width: 991px) {
      .dropdown-menu .dropdown-item {
        padding: 8px 20px;
        font-size: 15px; } }
    .dropdown-menu .dropdown-item a {
      color: var(--title); }
    .dropdown-menu .dropdown-item:hover {
      background-color: var(--rgba-primary-1); }
      .dropdown-menu .dropdown-item:hover a {
        color: var(--primary); }
    .dropdown-menu .dropdown-item:active a {
      color: var(--primary); }
    .dropdown-menu .dropdown-item:active svg path {
      color: var(--primary); }
  .dropdown-menu .dropdown-footer {
    padding: 10px 20px 10px; }
  .dropdown-menu .dropdown-header {
    padding: 10px 20px 10px; }

.dropdown-trade .dropdown-toggle {
  background-color: transparent !important;
  font-size: 20px;
  color: var(--primary) !important;
  font-weight: 500;
  border: 0;
  width: 100%;
  justify-content: space-between;
  border-right: 1px solid var(--border-color);
  border-radius: 0;
  padding: 10px 15px 10px 5px; }
  @media only screen and (max-width: 1191px) {
    .dropdown-trade .dropdown-toggle {
      border-right: 0;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 20px; } }
  @media only screen and (max-width: 575px) {
    .dropdown-trade .dropdown-toggle {
      font-size: 16px;
      padding: 10px 0 10px 0; } }
  .dropdown-trade .dropdown-toggle:hover, .dropdown-trade .dropdown-toggle:focus, .dropdown-trade .dropdown-toggle:active {
    background-color: transparent !important;
    color: var(--primary); }
  .dropdown-trade .dropdown-toggle::after {
    background-color: transparent;
    border-top: 0.4em solid var(--body-color);
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent; }

.dropdown-trade .dropdown-menu {
  width: 590px;
  padding: 15px;
  border: 0;
  margin-top: 18px;
  left: -30px !important; }
  @media only screen and (max-width: 1191px) {
    .dropdown-trade .dropdown-menu {
      margin-top: 0;
      left: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .dropdown-trade .dropdown-menu {
      width: 450px; } }
  @media only screen and (max-width: 575px) {
    .dropdown-trade .dropdown-menu {
      width: 330px;
      left: -15px !important; } }

.dropdown-trade .table-style-1 tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

.dropdown-trade .table-style-1 tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }

.dropdown-trade .table-style-1 tbody td {
  padding: 7px 15px;
  font-size: 14px; }
  @media only screen and (max-width: 575px) {
    .dropdown-trade .table-style-1 tbody td {
      padding: 7px 8px;
      font-size: 12px; } }

.dropdown-trade .table-style-1 thead th {
  font-size: 14px;
  padding: 7px 15px;
  color: #ffffff;
  background-color: var(--primary); }
  .dropdown-trade .table-style-1 thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .dropdown-trade .table-style-1 thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }

.notifications-dropdown .notify {
  width: 50px;
  min-width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background-color: var(--rgba-primary-1);
  border-radius: 10px;
  margin-right: 12px; }
  .notifications-dropdown .notify svg path {
    fill: var(--primary); }

.notifications-dropdown .dropdown-menu {
  width: 400px;
  margin-top: 15px; }
  .notifications-dropdown .dropdown-menu .dropdown-item {
    padding: 8px 30px;
    white-space: unset; }
    .notifications-dropdown .dropdown-menu .dropdown-item a {
      word-break: break-all; }
  .notifications-dropdown .dropdown-menu .dropdown-footer {
    padding: 10px 30px 20px; }
  .notifications-dropdown .dropdown-menu .dropdown-header {
    padding: 20px 30px 10px; }

@media only screen and (max-width: 991px) {
  .notifications-dropdown .dropdown-menu {
    width: 320px; }
    .notifications-dropdown .dropdown-menu .dropdown-item {
      padding: 8px 15px; }
    .notifications-dropdown .dropdown-menu .dropdown-footer,
    .notifications-dropdown .dropdown-menu .dropdown-header {
      padding: 10px 15px; } }

.drop-button .dropdown-toggle.btn {
  background-color: var(--card-bg) !important;
  color: var(--body-color);
  border-color: var(--border-color);
  padding: 12px 10px;
  box-shadow: 0px 7px 15px 0px rgba(61, 70, 83, 0.1); }
  @media only screen and (max-width: 575px) {
    .drop-button .dropdown-toggle.btn {
      padding: 6px 8px; } }
  .drop-button .dropdown-toggle.btn i {
    margin-left: 20px;
    color: var(--title); }
  .drop-button .dropdown-toggle.btn:after {
    content: none; }

.custom-tooltip {
  display: inline-block;
  position: relative; }
  .custom-tooltip .tooltip-content {
    position: absolute;
    width: 230px;
    background-color: #fff;
    border-radius: var(--border-radius-base);
    border: 1px solid var(--border-color);
    padding: 15px;
    font-size: 12px;
    margin-bottom: 0;
    right: 0;
    z-index: 2;
    box-shadow: 0px 7px 15px rgba(61, 70, 83, 0.1);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    .custom-tooltip .tooltip-content p {
      margin-bottom: 0; }
  .custom-tooltip:hover .tooltip-content {
    opacity: 1;
    visibility: visible; }

.icon-bx-wraper.style-1 {
  box-shadow: 0px 15px 40px rgba(1, 18, 111, 0.15);
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 25px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .icon-bx-wraper.style-1:hover {
    transform: translateY(-8px);
    background-color: #edf5ff; }
  .icon-bx-wraper.style-1 .title {
    font-size: 20px;
    margin-bottom: 12px; }
  .icon-bx-wraper.style-1 .icon-media {
    margin-bottom: 15px;
    display: flex;
    align-items: center; }
    .icon-bx-wraper.style-1 .icon-media img {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 14px; }
    .icon-bx-wraper.style-1 .icon-media .icon-info .title {
      margin-bottom: 0;
      font-family: var(--font-family-base); }
    .icon-bx-wraper.style-1 .icon-media .icon-info span {
      font-size: 14px; }
  .icon-bx-wraper.style-1 .icon-content .price li {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .icon-bx-wraper.style-1 .icon-content .price li span {
      font-weight: 400; }
    .icon-bx-wraper.style-1 .icon-content .price li .amount {
      font-family: var(--font-family-base); }
    .icon-bx-wraper.style-1 .icon-content .price li .percentage {
      font-size: 15px;
      font-weight: 500; }
  .icon-bx-wraper.style-1 .icon-content span {
    font-size: 14px;
    margin-bottom: 0; }

.icon-bx-wraper.style-2 {
  box-shadow: 0px 25px 50px rgba(28, 46, 158, 0.3);
  height: 100%;
  border-radius: 10px;
  padding: 32px 30px;
  background-color: var(--secondary);
  display: flex;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .icon-bx-wraper.style-2 .title {
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 700;
    color: #fff; }
  .icon-bx-wraper.style-2 .icon-media {
    margin-right: 20px;
    width: 110px;
    height: 110px;
    min-width: 110px; }
    .icon-bx-wraper.style-2 .icon-media img {
      width: 100%;
      height: 100%; }
  .icon-bx-wraper.style-2 .icon-content h4, .icon-bx-wraper.style-2 .icon-content .h4, .icon-bx-wraper.style-2 .icon-content .comment-reply-title,
  .icon-bx-wraper.style-2 .icon-content .wp-block-search .wp-block-search__label, .wp-block-search .icon-bx-wraper.style-2 .icon-content .wp-block-search__label {
    font-weight: 500; }
  .icon-bx-wraper.style-2 .icon-content p {
    font-size: 15px;
    margin-bottom: 0;
    color: #C5CDFF;
    line-height: 1.6;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .icon-bx-wraper.style-2 .icon-content a {
    margin-top: 1.5rem; }
  .icon-bx-wraper.style-2:hover .icon-media img {
    -webkit-animation: toTopFromBottom 0.5s forwards;
    -moz-animation: toTopFromBottom 0.5s forwards;
    animation: toTopFromBottom 0.5s forwards; }
  .icon-bx-wraper.style-2:hover {
    transform: translateY(-8px);
    background-color: #2e42bd; }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-2 {
      display: block; }
      .icon-bx-wraper.style-2 .icon-media {
        width: 80px;
        height: 80px;
        min-width: 80px;
        margin-bottom: 20px; }
      .icon-bx-wraper.style-2 .title {
        font-size: 20px; } }

.icon-bx-wraper.style-3 {
  box-shadow: 0px 25px 40px rgba(28, 46, 158, 0.1);
  height: 100%;
  border-radius: 20px;
  padding: 25px 45px 40px;
  background-blend-mode: color;
  background: white;
  margin-top: 10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  transition: 0.5s;
  flex-direction: column;
  row-gap: 0rem; }
  .icon-bx-wraper.style-3 .title {
    font-size: 24px;
    margin-bottom: 12px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .icon-bx-wraper.style-3 .icon-media {
    position: relative;
    width: 142px;
    height: 142px;
    margin: -35px auto 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background: #9467FE;
    box-shadow: 0px 30px 45px rgba(148, 103, 254, 0.3);
    transform: rotate(-45deg);
    transition: 0.5s;
    border-radius: 0.6rem 2rem 0.6rem 4rem; }
    .icon-bx-wraper.style-3 .icon-media img {
      position: relative;
      z-index: 1;
      transform: rotate(45deg); }
  .icon-bx-wraper.style-3 .icon-content {
    transition: 0.5s; }
    .icon-bx-wraper.style-3 .icon-content p {
      font-size: 15px;
      margin-bottom: 0; }
  .icon-bx-wraper.style-3:hover {
    transform: translateY(-8px);
    background-color: #362465;
    background-blend-mode: inherit; }
    .icon-bx-wraper.style-3:hover .title {
      color: #fff; }
    .icon-bx-wraper.style-3:hover .icon-content p {
      color: #fff;
      opacity: 0.75; }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-3 {
      padding: 20px 30px 25px; }
      .icon-bx-wraper.style-3 .icon-media {
        width: 100px;
        height: 100px;
        margin: -30px auto 15px;
        border-radius: 0.6rem 1rem 0.6rem 2rem; }
        .icon-bx-wraper.style-3 .icon-media img {
          width: 50px; } }

.icon-bx-wraper.style-4 {
  box-shadow: 0px 25px 40px rgba(28, 46, 158, 0.1);
  height: 100%;
  border-radius: 20px;
  padding: 40px;
  margin-top: 10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  min-height: 15rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center; }
  .icon-bx-wraper.style-4:after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #362465;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    opacity: 0.8; }
  .icon-bx-wraper.style-4 .icon-media {
    background: none;
    box-shadow: none;
    z-index: 1;
    position: relative; }
  .icon-bx-wraper.style-4 .title {
    font-size: 24px;
    margin-bottom: 12px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    z-index: 1;
    position: relative; }
  .icon-bx-wraper.style-4:hover {
    transform: translateY(-8px); }
    .icon-bx-wraper.style-4:hover .title {
      color: #fff; }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-4 {
      padding: 30px; }
      .icon-bx-wraper.style-4 .icon-media img {
        width: 80px; } }

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-50%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(50%); }
  51% {
    opacity: 1; } }

@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-50%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(50%); }
  51% {
    opacity: 1; } }

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-50%); }
  50% {
    opacity: 0;
    transform: translateY(50%); }
  51% {
    opacity: 1; } }

.dz-card {
  position: relative;
  background-color: #fff; }
  .dz-card .dz-info {
    padding: 30px;
    position: relative; }
  .dz-card .dz-title {
    margin-bottom: 15px;
    word-break: break-word; }
    @media only screen and (max-width: 575px) {
      .dz-card .dz-title {
        margin-bottom: 15px; } }
  .dz-card h1.dz-title, .dz-card .dz-title.h1 {
    font-size: 2.25rem;
    font-weight: 700; }
  .dz-card.blog-half {
    display: flex; }
    @media only screen and (max-width: 575px) {
      .dz-card.blog-half {
        display: block; } }
    .dz-card.blog-half .dz-info {
      padding: 30px;
      position: relative;
      flex: 1; }
    .dz-card.blog-half .dz-media {
      margin-bottom: 0;
      max-width: 300px;
      min-width: 300px; }
      .dz-card.blog-half .dz-media + a {
        display: block;
        height: 100%; }
      .dz-card.blog-half .dz-media img {
        height: 100%;
        object-fit: cover;
        min-height: 215px; }
        @media only screen and (max-width: 575px) {
          .dz-card.blog-half .dz-media img {
            min-height: auto; } }
    .dz-card.blog-half.post-video .post-video-icon {
      width: 60px;
      height: 60px;
      font-size: 20px; }
    .dz-card.blog-half .swiper {
      height: 100%; }

.dz-media.height-sm {
  height: 220px; }
  .dz-media.height-sm img {
    height: 100%;
    object-fit: cover; }

.dz-media.height-md {
  height: 265px; }
  .dz-media.height-md img {
    height: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 1199px) {
    .dz-media.height-md {
      height: 220px; } }
  @media only screen and (max-width: 767px) {
    .dz-media.height-md {
      height: 200px; } }
  .dz-media.height-md .post-swiper {
    height: 100%; }

.dz-media.height-lg {
  height: 350px; }
  @media only screen and (max-width: 1199px) {
    .dz-media.height-lg {
      height: 300px; } }
  @media only screen and (max-width: 767px) {
    .dz-media.height-lg {
      height: 220px; }
      .dz-media.height-lg img {
        height: 100%;
        object-fit: cover; } }

.dz-meta {
  margin-bottom: 10px; }
  .dz-meta ul {
    margin: 0;
    padding: 0; }
    .dz-meta ul li {
      display: inline-block;
      position: relative;
      font-size: 15px;
      margin-right: 30px; }
      .dz-meta ul li:after {
        content: "";
        height: 6px;
        width: 6px;
        background-color: var(--primary);
        position: absolute;
        top: 50%;
        right: -20px;
        border-radius: 50%;
        transform: translateY(-50%); }
      .dz-meta ul li:last-child {
        margin-right: 0;
        padding-right: 0; }
        .dz-meta ul li:last-child:after {
          content: none; }
      .dz-meta ul li i {
        font-size: 16px;
        margin-right: 2px;
        color: var(--primary);
        transform: scale(1.3);
        position: relative;
        top: 2px;
        padding-right: 0.2rem; }
      .dz-meta ul li a {
        color: inherit; }
      @media only screen and (max-width: 575px) {
        .dz-meta ul li {
          margin-right: 10px;
          padding-right: 10px; } }

.dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 15px; }
  .dz-badge:hover {
    color: white; }

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px; }
  @media only screen and (max-width: 575px) {
    .post-video .post-video-icon {
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 14px; } }
  .post-video .post-video-icon:before, .post-video .post-video-icon:after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #fff;
    z-index: -1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transition: all 0.5;
    -o-transition: all 0.5;
    -webkit-transition: all 0.5;
    -ms-transition: all 0.5;
    transition: all 0.5;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s; }
  .post-video .post-video-icon:before {
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    -webkit-animation: animationSignal1; }
  .post-video .post-video-icon:after {
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    animation: animationSignal2;
    animation-iteration-count: infinite;
    -webkit-animation: animationSignal2; }

.dz-card.blog-half.sticky {
  position: relative;
  border: 0.375rem solid var(--primary);
  overflow: hidden; }
  .dz-card.blog-half.sticky .sticky-icon {
    background: var(--primary);
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    transform: rotate(45deg);
    top: -1.563rem;
    right: -1.563rem;
    z-index: 1;
    color: #fff; }
    .dz-card.blog-half.sticky .sticky-icon i {
      position: absolute;
      bottom: 0.1875rem;
      right: 1.125rem;
      transform: rotate(5deg);
      color: #fff;
      font-size: 1rem; }

@media (max-width: 575px) {
  .content-inner.blog-sec {
    padding-top: 0 !important; } }

.dz-card.style-1.blog-half {
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.8s;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: flex;
  position: relative;
  background: #F8F5FF; }
  @media only screen and (max-width: 767px) {
    .dz-card.style-1.blog-half {
      display: block; } }
  .dz-card.style-1.blog-half .dz-meta {
    margin-bottom: 10px; }
    .dz-card.style-1.blog-half .dz-meta ul li {
      display: inline-block;
      position: relative;
      font-size: 15px;
      margin-right: 12px;
      padding-right: 12px; }
      .dz-card.style-1.blog-half .dz-meta ul li a {
        color: inherit; }
        .dz-card.style-1.blog-half .dz-meta ul li a img {
          height: 35px;
          width: 35px;
          border-radius: 100%;
          margin-right: 5px; }
      .dz-card.style-1.blog-half .dz-meta ul li:last-child {
        padding-right: 0;
        margin-right: 0; }
    .dz-card.style-1.blog-half .dz-meta ul .post-author::after {
      content: "";
      height: 6px;
      width: 6px;
      background-color: var(--primary);
      position: absolute;
      top: 50%;
      right: -5px;
      border-radius: 50%;
      transform: translateY(-50%); }
  .dz-card.style-1.blog-half .dz-media {
    overflow: hidden;
    position: relative;
    border-radius: unset;
    margin-bottom: 0;
    max-width: 300px;
    min-width: 300px; }
    .dz-card.style-1.blog-half .dz-media img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .dz-card.style-1.blog-half .dz-media .dz-badge-list {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1; }
      .dz-card.style-1.blog-half .dz-media .dz-badge-list a {
        background-color: #9467FE;
        color: #ffffff;
        border-radius: var(--border-radius-base);
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        padding: 10px 25px;
        -webkit-transition: all 0.7s;
        -ms-transition: all 0.7s;
        transition: all 0.7s; }
    .dz-card.style-1.blog-half .dz-media::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -100%;
      left: 0;
      background-color: var(--primary);
      opacity: 0.9;
      -webkit-transition: all 0.7s;
      -ms-transition: all 0.7s;
      transition: all 0.7s; }
    @media only screen and (max-width: 767px) {
      .dz-card.style-1.blog-half .dz-media {
        max-width: 100%;
        min-width: 100%; } }
  .dz-card.style-1.blog-half .dz-info {
    padding: 30px 25px; }
    @media only screen and (max-width: 767px) {
      .dz-card.style-1.blog-half .dz-info {
        padding: 20px; } }
    .dz-card.style-1.blog-half .dz-info .dz-title {
      margin-bottom: 10px;
      word-break: break-word;
      color: #362465; }
      .dz-card.style-1.blog-half .dz-info .dz-title a {
        color: #362465; }
  .dz-card.style-1.blog-half .dz-media .dz-badge-list {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1; }
  .dz-card.style-1.blog-half .dz-media .dz-badge {
    background-color: var(--secondary);
    color: var(--title);
    border-radius: var(--border-radius-base);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    -webkit-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s; }
  .dz-card.style-1.blog-half .dz-media .dz-badge:hover {
    background-color: white;
    color: #ffffff; }
  .dz-card.style-1.blog-half .dz-media .btn,
  .dz-card.style-1.blog-half .dz-media .wp-block-button__link {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
    z-index: 1; }
  .dz-card.style-1.blog-half:hover .dz-media:after {
    bottom: 0; }
  .dz-card.style-1.blog-half:hover .dz-media:after {
    bottom: 0; }
  .dz-card.style-1.blog-half:hover .btn,
  .dz-card.style-1.blog-half:hover .wp-block-button__link {
    top: 50%; }
  .dz-card.style-1.blog-half:hover .dz-badge {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: #fff; }

.dz-card.style-1.blog-lg {
  background: white;
  box-shadow: 0px 20px 40px rgba(1, 18, 111, 0.1);
  border-radius: var(--border-radius-base); }
  .dz-card.style-1.blog-lg .dz-media {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    position: relative;
    overflow: hidden;
    transition: 1s; }
    .dz-card.style-1.blog-lg .dz-media::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      z-index: 2;
      display: block;
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      -webkit-transition: all 1s;
      -ms-transition: all 1s;
      transition: all 1s; }
    .dz-card.style-1.blog-lg .dz-media img {
      border-radius: var(--border-radius-base) var(--border-radius-base) 0 0; }
  .dz-card.style-1.blog-lg:hover .dz-media::before {
    left: 100%;
    right: 0; }
  .dz-card.style-1.blog-lg .dz-info {
    padding: 30px; }
    @media only screen and (max-width: 575px) {
      .dz-card.style-1.blog-lg .dz-info {
        padding: 20px; } }
    .dz-card.style-1.blog-lg .dz-info .dz-meta {
      margin-bottom: 10px; }
      .dz-card.style-1.blog-lg .dz-info .dz-meta ul li {
        display: inline-block;
        position: relative;
        font-size: 15px;
        margin-right: 12px;
        padding-right: 12px; }
        .dz-card.style-1.blog-lg .dz-info .dz-meta ul li a {
          color: inherit; }
          .dz-card.style-1.blog-lg .dz-info .dz-meta ul li a img {
            height: 35px;
            width: 35px;
            border-radius: 100%;
            margin-right: 5px; }
        .dz-card.style-1.blog-lg .dz-info .dz-meta ul li:last-child {
          padding-right: 0;
          margin-right: 0; }
      .dz-card.style-1.blog-lg .dz-info .dz-meta ul .post-author::after {
        content: "";
        height: 6px;
        width: 6px;
        background-color: var(--primary);
        position: absolute;
        top: 50%;
        right: -5px;
        border-radius: 50%;
        transform: translateY(-50%); }
    .dz-card.style-1.blog-lg .dz-info .dz-title {
      margin-bottom: 5px; }
    .dz-card.style-1.blog-lg .dz-info p {
      margin-bottom: 20px; }

.dz-card.style-2 {
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  background-color: #362465;
  height: 100%;
  background-blend-mode: luminosity;
  min-height: 600px;
  background-size: cover;
  object-fit: cover;
  border-radius: 1.5rem; }
  @media only screen and (max-width: 767px) {
    .dz-card.style-2 {
      min-height: 450px; } }
  .dz-card.style-2:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(359.53deg, #362465 0.4%, rgba(54, 36, 101, 0) 55.3%);
    border-radius: 20; }
  .dz-card.style-2 .dz-media {
    height: 100%;
    position: relative; }
  .dz-card.style-2 .dz-media img {
    filter: grayscale(0.1);
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s; }
  .dz-card.style-2 .dz-info {
    padding: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }
    .dz-card.style-2 .dz-info .dz-meta {
      margin-bottom: 0; }
      .dz-card.style-2 .dz-info .dz-meta ul li {
        position: relative;
        display: inline-block; }
        .dz-card.style-2 .dz-info .dz-meta ul li.dz-user {
          color: var(--title);
          font-weight: 500;
          color: #fff; }
        .dz-card.style-2 .dz-info .dz-meta ul li a {
          color: #fff; }
    @media only screen and (max-width: 767px) {
      .dz-card.style-2 .dz-info {
        padding: 25px; } }
  .dz-card.style-2 .dz-info .dz-meta ul li.dz-user span {
    color: var(--primary); }
  .dz-card.style-2 .dz-info .dz-meta ul li.dz-user i {
    color: #9467FE; }
  .dz-card.style-2 .dz-info .dz-meta ul li.dz-date {
    color: var(--title);
    font-weight: 500;
    color: #fff; }
  .dz-card.style-2 .dz-info .dz-meta ul li.dz-date i {
    color: #9467FE; }
  .dz-card.style-2 .dz-info .post-author img {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    margin-right: 5px; }
  .dz-card.style-2 .dz-info .dz-meta ul li:not(:last-child):after {
    content: "";
    height: 6px;
    width: 6px;
    position: absolute;
    right: -15%;
    top: 50%;
    border-radius: 50%;
    background-color: var(--primary);
    transform: translate(0%, -50%); }
  .dz-card.style-2 .dz-title {
    font-size: 35px; }
    @media only screen and (max-width: 767px) {
      .dz-card.style-2 .dz-title {
        font-size: 24px; } }
  .dz-card.style-2 .dz-category {
    margin-bottom: 15px;
    display: inline-flex; }
    .dz-card.style-2 .dz-category .dz-badge-list {
      position: absolute;
      top: 40px;
      left: 40px;
      z-index: 1; }
      .dz-card.style-2 .dz-category .dz-badge-list li .dz-badge {
        background-color: var(--primary);
        color: #ffffff;
        font-weight: 500;
        border-radius: var(--border-radius-base);
        font-size: 15px;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        display: block; }
        .dz-card.style-2 .dz-category .dz-badge-list li .dz-badge:hover {
          background-color: white;
          color: var(--primary); }
      @media only screen and (max-width: 767px) {
        .dz-card.style-2 .dz-category .dz-badge-list {
          top: 25px;
          left: 25px; } }
  .dz-card.style-2.overlay {
    background-color: #000;
    opacity: 0.9;
    z-index: 1; }
    .dz-card.style-2.overlay .dz-meta li .post-date {
      color: rgba(255, 255, 255, 0.8); }
  .dz-card.style-2:hover .dz-media img {
    transform: rotate(5deg) scale(1.2); }

.pricingtable-wrapper.style-1 {
  background: #fff;
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  box-shadow: 0px 20px 40px rgba(1, 18, 111, 0.1);
  border-radius: var(--border-radius-base) var(--border-radius-base); }
  .pricingtable-wrapper.style-1 .pricingtable-inner {
    padding: 40px;
    position: relative; }
    .pricingtable-wrapper.style-1 .pricingtable-inner .tagline {
      position: absolute;
      border-radius: 6px;
      background: #D1FFEE;
      color: #05A467;
      top: 20px;
      right: 20px;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 500; }
  .pricingtable-wrapper.style-1 .days-label {
    text-align: center;
    color: #fff;
    border-radius: var(--border-radius-base);
    padding: 10px;
    margin-bottom: 45px; }
  .pricingtable-wrapper.style-1 .pricingtable-title {
    margin-bottom: 5px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .pricingtable-wrapper.style-1 .pricingtable-title svg {
      margin-right: 5px; }
  .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
    font-size: 75px;
    font-weight: 400;
    color: #362465 !important;
    line-height: 1.2;
    margin-bottom: 15px; }
    .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx span {
      color: #9467FE;
      opacity: 0.7;
      font-weight: 400; }
    .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx small, .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx .small {
      font-size: 16px;
      font-weight: 500; }
  .pricingtable-wrapper.style-1 .pricingtable-features li {
    display: flex;
    align-items: center;
    margin: 15px 0;
    color: #7462A1; }
    .pricingtable-wrapper.style-1 .pricingtable-features li svg {
      margin-right: 10px; }
  .pricingtable-wrapper.style-1 .pricingtable-footer .btn {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    width: 100%;
    display: block;
    background: #F0EAFF;
    color: #362465;
    font-weight: 400;
    font-size: 16px;
    border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    padding: 15px 30px; }
  @media only screen and (max-width: 575px) {
    .pricingtable-wrapper.style-1 .pricingtable-inner {
      padding: 30px 30px 20px; }
    .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
      font-size: 55px; }
    .pricingtable-wrapper.style-1 .days-label {
      margin-bottom: 35px; } }
  .pricingtable-wrapper.style-1:hover, .pricingtable-wrapper.style-1.active {
    background: #362465; }
    .pricingtable-wrapper.style-1:hover .pricingtable-price h6, .pricingtable-wrapper.style-1:hover .pricingtable-price .h6, .pricingtable-wrapper.style-1.active .pricingtable-price h6, .pricingtable-wrapper.style-1.active .pricingtable-price .h6 {
      color: white; }
    .pricingtable-wrapper.style-1:hover .pricingtable-title, .pricingtable-wrapper.style-1.active .pricingtable-title {
      color: #fff; }
    .pricingtable-wrapper.style-1:hover .pricingtable-inner .tagline, .pricingtable-wrapper.style-1.active .pricingtable-inner .tagline {
      background: rgba(255, 255, 255, 0.1);
      color: #fff; }
    .pricingtable-wrapper.style-1:hover .pricingtable-bx, .pricingtable-wrapper.style-1.active .pricingtable-bx {
      color: #ffffff !important; }
    .pricingtable-wrapper.style-1:hover .pricingtable-features span, .pricingtable-wrapper.style-1.active .pricingtable-features span {
      color: #ffffff; }
    .pricingtable-wrapper.style-1:hover .pricingtable-features li, .pricingtable-wrapper.style-1.active .pricingtable-features li {
      color: white;
      opacity: 0.8; }
    .pricingtable-wrapper.style-1:hover .pricingtable-footer .btn, .pricingtable-wrapper.style-1.active .pricingtable-footer .btn {
      color: #ffffff;
      background: rgba(148, 103, 254, 0.1); }

.page-wraper {
  overflow: hidden; }

.bg-primary-light {
  background-color: #F0EAFF; }

.main-bnr {
  height: 920px;
  position: relative;
  background-image: url(../images/home-banner/bg.jpg);
  background-color: #261355;
  display: flex;
  background-size: cover;
  overflow: hidden;
  padding-top: 250px;
  padding-bottom: 165px;
  background-position: center; }
  .main-bnr .container,
  .main-bnr .container-fluid,
  .main-bnr .container-sm,
  .main-bnr .container-md,
  .main-bnr .container-lg,
  .main-bnr .container-xl {
    position: relative;
    z-index: 3; }
  .main-bnr h1, .main-bnr .h1 {
    color: #fff;
    font-size: 70px;
    line-height: 1.2;
    margin-bottom: 32px;
    position: relative; }
  .main-bnr .image-before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .main-bnr .image-before .left-img {
      position: absolute;
      bottom: -100px;
      left: -180px;
      animation: dZwobble2 4s infinite;
      animation-delay: 1s;
      width: 550px; }
    .main-bnr .image-before .right-img {
      position: absolute;
      right: -280px;
      top: -180px;
      animation: dZwobble2 4s infinite;
      width: 600px; }
  .main-bnr .text {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 70px;
    line-height: 1.7; }
  .main-bnr .bg-shape1,
  .main-bnr .bg-shape2,
  .main-bnr .bg-shape3,
  .main-bnr .bg-shape4 {
    position: absolute; }
  .main-bnr .bg-shape1 {
    top: 20%;
    left: 8%;
    -webkit-animation: aniRotate 10s infinite linear;
    animation: aniRotate 10s infinite linear; }
  .main-bnr .bg-shape2 {
    right: 4%;
    bottom: 8%;
    -webkit-animation: aniRotate 10s infinite linear;
    animation: aniRotate 10s infinite linear; }
  .main-bnr .bg-shape3 {
    top: 8%;
    right: 3%;
    -webkit-animation: dZwobble 4s infinite;
    animation: dZwobble 4s infinite; }
  .main-bnr .bg-shape4 {
    bottom: 18%;
    left: 3%;
    -webkit-animation: dZwobble2 4s infinite;
    animation: dZwobble2 4s infinite; }
  @media only screen and (max-width: 1480px) {
    .main-bnr .bg-shape1 {
      top: 15%;
      left: 1%;
      width: 80px; }
    .main-bnr .bg-shape2 {
      width: 60px; } }
  @media only screen and (max-width: 1280px) {
    .main-bnr {
      height: 680px;
      padding-top: 200px;
      padding-bottom: 100px; }
      .main-bnr h1, .main-bnr .h1 {
        font-size: 48px;
        margin-bottom: 20px; }
      .main-bnr .text {
        font-size: 18px;
        margin-bottom: 40px; }
      .main-bnr .image-before .right-img {
        right: -100px;
        top: -180px;
        width: 400px; }
      .main-bnr .image-before .left-img {
        bottom: -50px;
        left: -50px;
        width: 400px; } }
  @media only screen and (max-width: 991px) {
    .main-bnr {
      height: 580px; }
      .main-bnr .image-before {
        display: none; }
      .main-bnr h1, .main-bnr .h1 {
        font-size: 36px; }
      .main-bnr .bg-shape1,
      .main-bnr .bg-shape2,
      .main-bnr .bg-shape3,
      .main-bnr .bg-shape4 {
        display: none; } }
  @media only screen and (max-width: 575px) {
    .main-bnr {
      padding-top: 150px;
      padding-bottom: 50px;
      height: 520px; }
      .main-bnr h1, .main-bnr .h1 {
        font-size: 32px; }
      .main-bnr .text {
        margin-bottom: 30px; } }

.banner-media {
  position: relative;
  width: 565px;
  min-width: 565px;
  height: 565px;
  -webkit-animation: aniBnrMedia 5s infinite;
  animation: aniBnrMedia 5s infinite; }
  @media only screen and (max-width: 1480px) {
    .banner-media {
      width: 465px;
      min-width: 465px;
      height: 465px; } }
  @media only screen and (max-width: 1191px) {
    .banner-media {
      width: 365px;
      min-width: 365px;
      height: 365px;
      margin-left: auto;
      margin-right: auto; } }
  @media only screen and (max-width: 991px) {
    .banner-media {
      width: 300px;
      min-width: 300px;
      height: 300px; } }
  @media only screen and (max-width: 767px) {
    .banner-media {
      margin-top: 30px; } }
  @media only screen and (max-width: 575px) {
    .banner-media {
      margin-left: 30px; } }
  .banner-media .media {
    height: 100%; }
    .banner-media .media img {
      height: 100%;
      filter: drop-shadow(60px 0px 100px rgba(0, 0, 0, 0.35)); }
  .banner-media [class*="shape"] {
    position: absolute; }
  .banner-media .shape1 {
    top: 40px;
    right: 15px;
    left: auto;
    -webkit-animation: dZwobble2 4s infinite;
    animation: dZwobble2 4s infinite; }
    .banner-media .shape1 img {
      filter: drop-shadow(40px 0px 50px rgba(0, 0, 0, 0.2)); }
  .banner-media .shape2 {
    right: 12%;
    bottom: 20%;
    -webkit-animation: dZwobble 4s infinite;
    animation: dZwobble 4s infinite; }
    .banner-media .shape2 img {
      filter: drop-shadow(30px 4px 50px rgba(0, 0, 0, 0.25)); }
  .banner-media .shape3 {
    left: 0;
    bottom: 18%;
    -webkit-animation: dZwobble 5s infinite;
    animation: dZwobble 5s infinite; }
    .banner-media .shape3 img {
      filter: drop-shadow(30px 4px 50px rgba(0, 0, 0, 0.3)); }
  @media only screen and (max-width: 1480px) {
    .banner-media .shape1 {
      width: 100px; }
    .banner-media .shape2 {
      width: 65px; }
    .banner-media .shape3 {
      width: 70px; } }
  @media only screen and (max-width: 1191px) {
    .banner-media .shape1 {
      width: 80px; }
    .banner-media .shape2 {
      width: 45px; }
    .banner-media .shape3 {
      width: 50px; } }
  @media only screen and (max-width: 991px) {
    .banner-media .shape1 {
      width: 50px; }
    .banner-media .shape2 {
      width: 35px; }
    .banner-media .shape3 {
      width: 40px; } }

@keyframes dZwobble2 {
  0% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); }
  100% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px); } }

@keyframes dZwobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); }
  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); } }

@keyframes aniBnrShape2 {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg); } }

@keyframes aniRotate {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@keyframes aniBnrMedia {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); } }

.form-wrapper-box.style-1 {
  height: 100%;
  position: unset;
  padding: 40px 50px;
  margin-left: -65px;
  margin-right: -65px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 30px 45px rgba(28, 46, 158, 0.15); }
  .form-wrapper-box.style-1 .section-head {
    margin-bottom: 30px; }
    .form-wrapper-box.style-1 .section-head .title {
      font-size: 36px;
      margin-bottom: 5px;
      color: var(--secondary); }
    .form-wrapper-box.style-1 .section-head p {
      font-weight: 400;
      font-size: 18px;
      color: #7E87BF; }
    @media only screen and (max-width: 767px) {
      .form-wrapper-box.style-1 .section-head .title {
        font-size: 28px; }
      .form-wrapper-box.style-1 .section-head p {
        font-size: 16px; } }
  .form-wrapper-box.style-1 .form-control, .form-wrapper-box.style-1 .wp-block-categories-dropdown select, .wp-block-categories-dropdown .form-wrapper-box.style-1 select,
  .form-wrapper-box.style-1 .wp-block-archives-dropdown select, .wp-block-archives-dropdown .form-wrapper-box.style-1 select {
    z-index: 1 !important;
    background-color: #F0EAFF;
    font-size: 16px;
    color: #000;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    border: 0; }
    .form-wrapper-box.style-1 .form-control:placeholder, .form-wrapper-box.style-1 .wp-block-categories-dropdown select:placeholder, .wp-block-categories-dropdown .form-wrapper-box.style-1 select:placeholder,
    .form-wrapper-box.style-1 .wp-block-archives-dropdown select:placeholder, .wp-block-archives-dropdown .form-wrapper-box.style-1 select:placeholder {
      color: #8B97E4; }
    @media only screen and (max-width: 575px) {
      .form-wrapper-box.style-1 .form-control, .form-wrapper-box.style-1 .wp-block-categories-dropdown select, .wp-block-categories-dropdown .form-wrapper-box.style-1 select,
      .form-wrapper-box.style-1 .wp-block-archives-dropdown select, .wp-block-archives-dropdown .form-wrapper-box.style-1 select {
        font-size: 15px; } }
  .form-wrapper-box.style-1 .form-wrapper {
    display: flex;
    align-items: center; }
    .form-wrapper-box.style-1 .form-wrapper .flex-1 {
      flex: 1;
      padding-right: 15px;
      position: relative;
      z-index: 2; }
    .form-wrapper-box.style-1 .form-wrapper .btn {
      font-size: 18px;
      font-weight: 500;
      padding: 15px 30px; }
      @media only screen and (max-width: 575px) {
        .form-wrapper-box.style-1 .form-wrapper .btn {
          font-size: 16px; } }
  .form-wrapper-box.style-1 textarea.form-control {
    height: 150px;
    padding: 20px 20px; }
  .form-wrapper-box.style-1 .input-group {
    margin-bottom: 30px; }
  @media only screen and (max-width: 1280px) {
    .form-wrapper-box.style-1 {
      margin-left: 0;
      margin-right: 0; } }
  @media only screen and (max-width: 1191px) {
    .form-wrapper-box.style-1 .form-wrapper {
      display: block; }
      .form-wrapper-box.style-1 .form-wrapper .flex-1 {
        padding-right: 0;
        margin-bottom: 20px; }
      .form-wrapper-box.style-1 .form-wrapper .btn {
        width: 100%; } }
  @media only screen and (max-width: 575px) {
    .form-wrapper-box.style-1 {
      padding: 30px 30px; }
      .form-wrapper-box.style-1 .section-head {
        padding-bottom: 0; } }

.form-wrapper-box.style-2 {
  box-shadow: 0px 25px 50px rgba(9, 9, 21, 0.1);
  border-radius: 20px 20px 0 0;
  padding: 50px 40px 75px 40px;
  background-color: var(--secondary);
  width: 483px;
  position: relative;
  z-index: 1;
  margin-left: 20px; }
  .form-wrapper-box.style-2 .section-head {
    margin-bottom: 30px; }
    .form-wrapper-box.style-2 .section-head .title {
      font-size: 30px;
      margin-bottom: 5px;
      color: #fff; }
    .form-wrapper-box.style-2 .section-head p {
      font-weight: 400;
      font-size: 14px;
      color: #95A3FF;
      font-family: var(--font-family-title); }
  .form-wrapper-box.style-2 .form-control, .form-wrapper-box.style-2 .wp-block-categories-dropdown select, .wp-block-categories-dropdown .form-wrapper-box.style-2 select,
  .form-wrapper-box.style-2 .wp-block-archives-dropdown select, .wp-block-archives-dropdown .form-wrapper-box.style-2 select {
    background-color: #00127F;
    z-index: 1 !important;
    font-size: 16px;
    border: 0;
    color: #fff;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .form-wrapper-box.style-2 .form-control:placeholder, .form-wrapper-box.style-2 .wp-block-categories-dropdown select:placeholder, .wp-block-categories-dropdown .form-wrapper-box.style-2 select:placeholder,
    .form-wrapper-box.style-2 .wp-block-archives-dropdown select:placeholder, .wp-block-archives-dropdown .form-wrapper-box.style-2 select:placeholder {
      color: #8B97E4; }
  .form-wrapper-box.style-2 textarea.form-control {
    height: 150px;
    padding: 20px 20px; }
  .form-wrapper-box.style-2 .input-group {
    margin-bottom: 25px; }
  .form-wrapper-box.style-2 .input-area {
    position: relative; }
  @media only screen and (max-width: 1480px) {
    .form-wrapper-box.style-2 {
      width: 430px;
      padding: 40px 30px 50px 30px; } }
  @media only screen and (max-width: 1280px) {
    .form-wrapper-box.style-2 {
      width: 100%; } }
  @media only screen and (max-width: 1191px) {
    .form-wrapper-box.style-2 {
      margin-top: 0;
      margin-left: 0;
      border-radius: 0 0 20px 20px;
      padding: 40px 30px 30px 30px; } }

.form-control.custom-image-select-1, .wp-block-categories-dropdown select.custom-image-select-1,
.wp-block-archives-dropdown select.custom-image-select-1 {
  border-radius: var(--border-radius-base); }
  .form-control.custom-image-select-1 img, .wp-block-categories-dropdown select.custom-image-select-1 img, .wp-block-archives-dropdown select.custom-image-select-1 img {
    width: 24px;
    min-width: 24px;
    margin-right: 10px; }
  .form-control.custom-image-select-1 .dropdown-menu, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-menu, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-menu {
    width: 100%;
    border: 0;
    box-shadow: 0px 25px 40px 0px rgba(28, 46, 158, 0.1); }
    .form-control.custom-image-select-1 .dropdown-menu.inner .dropdown-item, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-menu.inner .dropdown-item, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-menu.inner .dropdown-item {
      color: #000; }
      .form-control.custom-image-select-1 .dropdown-menu.inner .dropdown-item.active, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-menu.inner .dropdown-item.active, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-menu.inner .dropdown-item.active {
        background-color: #F0EAFF; }
  .form-control.custom-image-select-1 .dropdown-toggle, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-toggle, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-toggle {
    position: relative;
    background-color: #F0EAFF;
    color: #7E87BF !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    padding: 16px 15px !important;
    border: 0;
    height: 55px; }
    @media only screen and (max-width: 575px) {
      .form-control.custom-image-select-1 .dropdown-toggle, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-toggle, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-toggle {
        font-size: 15px !important; } }
    .form-control.custom-image-select-1 .dropdown-toggle:focus, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-toggle:focus, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-toggle:focus {
      outline: none !important;
      outline-offset: none !important; }
    .form-control.custom-image-select-1 .dropdown-toggle:after, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-toggle:after, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-toggle:after {
      content: '\f107';
      position: absolute;
      font-weight: 900;
      top: 50%;
      font-size: 14px;
      right: 20px;
      color: #1C2E9E;
      font-family: 'Font Awesome 6 Free';
      border: 0 !important;
      transform: translateY(-50%); }
  .form-control.custom-image-select-1 .filter-option-inner-inner, .wp-block-categories-dropdown select.custom-image-select-1 .filter-option-inner-inner, .wp-block-archives-dropdown select.custom-image-select-1 .filter-option-inner-inner {
    display: flex;
    align-items: center; }
  .form-control.custom-image-select-1 .dropdown-menu .dropdown-item, .wp-block-categories-dropdown select.custom-image-select-1 .dropdown-menu .dropdown-item, .wp-block-archives-dropdown select.custom-image-select-1 .dropdown-menu .dropdown-item {
    padding: 10px 15px 10px 15px;
    text-align: left; }

.currancy-wrapper {
  position: relative;
  padding: 0 75px;
  margin-top: -116px;
  z-index: 3; }
  .currancy-wrapper:after {
    content: "";
    height: 58px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 70px;
    background-color: #9467FE;
    border-radius: 20px 20px 0 0;
    z-index: -1; }
  @media only screen and (max-width: 1280px) {
    .currancy-wrapper {
      margin-top: -113px; } }
  @media only screen and (max-width: 1191px) {
    .currancy-wrapper {
      padding: 0 35px; } }
  @media only screen and (max-width: 991px) {
    .currancy-wrapper {
      padding: 0;
      margin-top: 50px; }
      .currancy-wrapper:after {
        display: none; } }

.section-wrapper1 {
  z-index: 2; }
  .section-wrapper1:after {
    content: "";
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: #f8f5ff;
    height: 70px;
    z-index: -1; }
  .section-wrapper1 .bg-shape1 {
    position: absolute;
    bottom: 15%;
    left: 6%;
    -webkit-animation: aniRotate 10s infinite linear;
    animation: aniRotate 10s infinite linear; }
  @media only screen and (max-width: 1680px) {
    .section-wrapper1 .bg-shape1 {
      left: 5%; } }
  @media only screen and (max-width: 1480px) {
    .section-wrapper1 .bg-shape1 {
      display: none; } }

.section-wrapper2 .bg-shape1 {
  position: absolute;
  right: 3%;
  top: 25%;
  -webkit-animation: aniRotate 10s infinite linear;
  animation: aniRotate 10s infinite linear; }

@media only screen and (max-width: 1280px) {
  .section-wrapper2 .bg-shape1 {
    display: none; } }

@media only screen and (max-width: 575px) {
  .section-wrapper2 .m-b60 {
    margin-bottom: 40px; } }

.section-wrapper3 .bg-shape1 {
  position: absolute;
  left: 2%;
  bottom: 5%;
  -webkit-animation: aniRotate 10s infinite linear;
  animation: aniRotate 10s infinite linear; }

.about-sec.content-inner {
  padding-bottom: 30px; }
  .about-sec.content-inner .bg-shape1,
  .about-sec.content-inner .bg-shape2,
  .about-sec.content-inner .bg-shape3,
  .about-sec.content-inner .bg-shape4 {
    position: absolute; }
    @media only screen and (max-width: 1480px) {
      .about-sec.content-inner .bg-shape1,
      .about-sec.content-inner .bg-shape2,
      .about-sec.content-inner .bg-shape3,
      .about-sec.content-inner .bg-shape4 {
        display: none; } }
  .about-sec.content-inner .bg-shape1 {
    top: 20%;
    left: 6%;
    animation: aniRotate 10s infinite linear;
    width: 70px; }
  .about-sec.content-inner .bg-shape2 {
    right: 8%;
    bottom: 2%;
    animation: aniRotate 10s infinite linear; }
  .about-sec.content-inner .bg-shape3 {
    bottom: 10%;
    left: 2%;
    animation: aniRotate 10s infinite linear; }
  .about-sec.content-inner .bg-shape4 {
    top: 20%;
    right: 2%;
    animation: aniRotate 10s infinite linear; }

.about-bx2.style-1 .dz-media::after {
  display: none; }

.about-bx2.style-1 .dz-media .image-box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  margin-bottom: 30px; }
  .about-bx2.style-1 .dz-media .image-box img {
    width: 100%;
    height: 100%; }
  .about-bx2.style-1 .dz-media .image-box.image-box-1 {
    width: 80%;
    float: right; }
  .about-bx2.style-1 .dz-media .image-box.image-box-4 {
    width: 80%; }

.about-bx2.style-1 .about-content .section-head h2, .about-bx2.style-1 .about-content .section-head .h2 {
  font-weight: 600; }

.about-bx2.style-1 .about-content .section-head p {
  margin-top: 0.8rem; }

.video-bx.style-1 {
  width: 100%;
  position: relative;
  z-index: 100;
  padding: 1px; }
  .video-bx.style-1::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 5rem;
    background: #9467FE;
    border-radius: 2rem 2rem 0 0;
    bottom: 0; }
  .video-bx.style-1 .video-media {
    margin: auto;
    width: 850px;
    height: 100%;
    border: 1rem solid white;
    border-radius: 2rem;
    margin-top: 65px;
    margin-bottom: -50px;
    position: relative;
    z-index: 880;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 15px 40px rgba(1, 18, 111, 0.15); }
    .video-bx.style-1 .video-media::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #362465;
      border-radius: 1.5rem;
      opacity: .6; }
    .video-bx.style-1 .video-media .play-icon {
      position: absolute;
      background: white;
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.9rem; }
      .video-bx.style-1 .video-media .play-icon img {
        width: 1.5rem; }
    .video-bx.style-1 .video-media img {
      width: 100%; }
  @media only screen and (max-width: 1280px) {
    .video-bx.style-1 .video-media {
      margin-top: 25px; } }
  @media only screen and (max-width: 991px) {
    .video-bx.style-1 .video-media {
      width: 600px !important;
      margin-top: 0; }
      .video-bx.style-1 .video-media .play-icon {
        width: 48px;
        height: 48px; }
        .video-bx.style-1 .video-media .play-icon img {
          width: 1rem; }
      .video-bx.style-1 .video-media > img {
        border-radius: 1.5rem; } }
  @media only screen and (max-width: 767px) {
    .video-bx.style-1 .video-media {
      width: 450px !important;
      border: 1rem solid white; } }
  @media only screen and (max-width: 575px) {
    .video-bx.style-1::after {
      display: none; }
    .video-bx.style-1 .video-media {
      width: 100% !important;
      border: 0.6rem solid white;
      margin-top: -10px;
      margin-bottom: 30px; } }

.form-wrapper1.style-1 {
  margin: 0 -6rem;
  border-radius: 2rem;
  padding: 50px 80px 20px; }
  .form-wrapper1.style-1 button {
    background: #362465;
    width: 100%; }
  @media only screen and (max-width: 1480px) {
    .form-wrapper1.style-1 {
      padding: 30px 40px 0;
      margin: 0; } }
  @media only screen and (max-width: 575px) {
    .form-wrapper1.style-1 {
      padding: 30px 30px 15px;
      border-radius: 6px; }
      .form-wrapper1.style-1 .m-b30 {
        margin-bottom: 15px; }
      .form-wrapper1.style-1 button {
        padidng: 14px 15px; } }

.contact-form-wraper.style-1 {
  position: relative; }
  .contact-form-wraper.style-1 .info-box {
    margin-right: 30px; }
    .contact-form-wraper.style-1 .info-box .link {
      display: block;
      margin-bottom: 40px; }
    .contact-form-wraper.style-1 .info-box .info {
      margin-bottom: 30px; }
      .contact-form-wraper.style-1 .info-box .info h2, .contact-form-wraper.style-1 .info-box .info .h2 {
        color: #362465; }
      .contact-form-wraper.style-1 .info-box .info p {
        font-size: 1rem; }
    .contact-form-wraper.style-1 .info-box .social-box ul {
      display: flex;
      align-items: center; }
      .contact-form-wraper.style-1 .info-box .social-box ul li {
        padding: 0 6px; }
        .contact-form-wraper.style-1 .info-box .social-box ul li .social-btn {
          width: 40px;
          height: 40px;
          background-color: #18a594;
          background-color: var(--primary);
          border-radius: 4px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .contact-form-wraper.style-1 .info-box .social-box ul li:nth-child(1) {
          padding-left: 0; }
  .contact-form-wraper.style-1 .contact-box {
    position: relative;
    z-index: 99; }
    .contact-form-wraper.style-1 .contact-box .card .card-body {
      padding: 50px 60px; }
      .contact-form-wraper.style-1 .contact-box .card .card-body h2, .contact-form-wraper.style-1 .contact-box .card .card-body .h2 {
        color: #362465; }
      .contact-form-wraper.style-1 .contact-box .card .card-body .btn {
        text-transform: uppercase;
        letter-spacing: 1px; }
      .contact-form-wraper.style-1 .contact-box .card .card-body textarea.form-control {
        min-height: 120px;
        height: unset; }
    @media only screen and (max-width: 991px) {
      .contact-form-wraper.style-1 .contact-box {
        margin: 0; }
        .contact-form-wraper.style-1 .contact-box .card .card-body {
          padding: 30px 40px; }
          .contact-form-wraper.style-1 .contact-box .card .card-body .btn {
            width: 100% !important; } }
    @media only screen and (max-width: 575px) {
      .contact-form-wraper.style-1 .contact-box .card .card-body {
        padding: 30px; } }

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  display: flex;
  background-color: #fff; }

.form-sec:after {
  content: "";
  height: 115px;
  background-color: #fff;
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; }

.form-sec .bg-shape2 {
  position: absolute;
  right: 5%;
  bottom: 90px;
  width: 60px; }

@media only screen and (max-width: 991px) {
  .form-sec .bg-shape2 {
    display: none; } }

.blog-wrapper .bg-shape1 {
  position: absolute;
  left: 5%;
  bottom: 5px; }

@media only screen and (max-width: 1480px) {
  .blog-wrapper .bg-shape1 {
    display: none; } }

.pricing-plan-wrapper .bg-shape1,
.pricing-plan-wrapper .bg-shape2,
.pricing-plan-wrapper .bg-shape3,
.pricing-plan-wrapper .bg-shape4,
.pricing-plan-wrapper .bg-shape5 {
  position: absolute; }
  @media only screen and (max-width: 1480px) {
    .pricing-plan-wrapper .bg-shape1,
    .pricing-plan-wrapper .bg-shape2,
    .pricing-plan-wrapper .bg-shape3,
    .pricing-plan-wrapper .bg-shape4,
    .pricing-plan-wrapper .bg-shape5 {
      display: none; } }

.pricing-plan-wrapper .bg-shape1 {
  top: 60%;
  left: 10%;
  width: 24px; }

.pricing-plan-wrapper .bg-shape2 {
  left: 12%;
  top: 10%;
  width: 80px; }

.pricing-plan-wrapper .bg-shape3 {
  bottom: 5%;
  left: 5%;
  width: 80px; }

.pricing-plan-wrapper .bg-shape4 {
  right: 15%;
  top: 50%;
  width: 35px; }

.pricing-plan-wrapper .bg-shape5 {
  top: 5%;
  right: 5%;
  width: 18px; }

.pricing-plan-wrapper2 .bg-shape2 {
  position: absolute;
  right: 30px;
  width: 60px;
  top: 35%; }

@media only screen and (max-width: 1480px) {
  .pricing-plan-wrapper2 .bg-shape2 {
    display: none; } }

.video-bx-wrapper .bg-shape1 {
  position: absolute;
  left: 10%;
  bottom: 25%;
  width: 70px; }

@media only screen and (max-width: 1480px) {
  .video-bx-wrapper .bg-shape1 {
    display: none; } }

/* Google Recaptcha */
.g-recaptcha,
#rc-imageselect {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }
  @media only screen and (max-width: 1280px) {
    .g-recaptcha,
    #rc-imageselect {
      transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      -webkit-transform: scale(0.85);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      -webkit-transform-origin: 0 0; } }
  @media only screen and (max-width: 575px) {
    .g-recaptcha,
    #rc-imageselect {
      transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      -webkit-transform: scale(0.85);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      -webkit-transform-origin: 0 0; } }

.recaptcha-box {
  height: 60px; }

/* Google Recaptcha */
.i-false:after {
  content: none; }

.select-drop .select-drop-toggle {
  background-color: #F0EAFF;
  color: #7E87BF;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 15px;
  border: 0;
  height: 55px;
  border-radius: var(--border-radius-base);
  text-align: left; }
  .select-drop .select-drop-toggle i {
    position: absolute;
    right: 20px;
    align-items: center;
    top: 36%;
    color: #1C2E9E; }
  .select-drop .select-drop-toggle img {
    width: 24px;
    min-width: 24px;
    margin-right: 10px; }

.select-drop .dropdown-menu {
  border: none; }
  .select-drop .dropdown-menu img {
    width: 24px;
    min-width: 24px;
    margin-right: 10px; }

.select-drop.show .dropdown-toggle {
  background-color: #F0EAFF; }

.blog-single {
  margin-bottom: 3.75rem; }
  @media only screen and (max-width: 575px) {
    .blog-single {
      margin-bottom: 2.5rem; } }
  .blog-single .dz-post-meta {
    margin-bottom: 1.25rem; }
  .blog-single .post-author img {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    margin-right: 10px; }
  .blog-single .dz-post-text {
    padding-bottom: 5px; }
    .blog-single .dz-post-text:after {
      content: "";
      display: block;
      width: 100%;
      clear: both; }
  .blog-single .dz-post-tags {
    margin-top: 1.25rem; }
  .blog-single .dz-social-icon {
    display: flex;
    align-items: center; }
    .blog-single .dz-social-icon .title {
      margin-right: 18px;
      margin-bottom: 0;
      line-height: 1.35; }
    .blog-single .dz-social-icon ul li {
      display: inline-block; }
    .blog-single .dz-social-icon ul li a {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      text-align: center;
      background-color: var(--rgba-primary-1);
      color: var(--primary); }
      .blog-single .dz-social-icon ul li a:hover {
        background-color: var(--primary);
        color: #fff; }
  .blog-single .dz-info {
    padding: 0; }
  .blog-single .dz-post-media {
    border-radius: 0.25rem; }
  .blog-single .dz-media,
  .blog-single .dz-post-media {
    text-align: center; }
  .blog-single.dz-card .post-tags {
    margin-left: -3px;
    margin-right: -3px; }
  .blog-single.style-1 .dz-media + .dz-info {
    padding: 25px 0 0; }
  .blog-single.style-1 .dz-info {
    padding: 0 0 30px; }
    .blog-single.style-1 .dz-info .blockquote p {
      font-size: 20px;
      font-weight: 600; }
    @media only screen and (max-width: 575px) {
      .blog-single.style-1 .dz-info .post-comment {
        display: none; } }
    .blog-single.style-1 .dz-info .post-author img {
      height: 35px;
      width: 35px;
      border-radius: 100%;
      margin-right: 10px; }
      @media only screen and (max-width: 767px) {
        .blog-single.style-1 .dz-info .post-author img {
          height: 27px;
          width: 27px;
          margin-right: 5px; } }

.wp-block-code > code {
  color: #fff; }

.dz-media-rounded img {
  border-radius: var(--border-radius-base); }

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none; }

.dz-post-text iframe {
  max-width: 100%; }

.post-header {
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 575px) {
    .post-header {
      margin-bottom: 1.25rem; } }
  .post-header .dz-media img {
    min-height: 250px;
    object-fit: cover;
    width: 100%; }
  .post-header .dz-info {
    position: absolute;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 100px 30px 30px 30px !important;
    width: 100%; }
    @media only screen and (max-width: 575px) {
      .post-header .dz-info {
        padding: 40px 15px 15px 15px !important; } }
    .post-header .dz-info .dz-title {
      color: #fff; }
      @media only screen and (max-width: 575px) {
        .post-header .dz-info .dz-title {
          font-size: 1.125rem; } }
    .post-header .dz-info .dz-meta ul li:after {
      background: #fff; }
    .post-header .dz-info .dz-meta ul li strong,
    .post-header .dz-info .dz-meta ul li span,
    .post-header .dz-info .dz-meta ul li a {
      color: #fff; }

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .post-link-in:hover {
    background: var(--primary);
    color: #fff; }

.side-bar {
  padding-left: 20px;
  padding-bottom: 1px; }
  .side-bar .wp-block-search,
  .side-bar .wp-block-archives,
  .side-bar .wp-block-latest-posts,
  .side-bar .wp-block-latest-comments,
  .side-bar .wp-block-categories,
  .side-bar .wp-block-calendar {
    margin-bottom: 0; }
  @media only screen and (max-width: 991px) {
    .side-bar {
      padding-left: 0;
      padding-right: 0; } }
  .side-bar.left {
    padding-left: 0;
    padding-right: 20px; }
    @media only screen and (max-width: 991px) {
      .side-bar.left {
        padding-right: 0;
        padding-left: 0; } }

.alignnone {
  margin: 0.3125rem 0 1.563rem 0; }
  .alignnoneimg, .alignnone.wp-caption, .alignnone.is-resized {
    margin: 0.3125rem 0 1.563rem 0; }

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center; }
  .aligncenterimg, .aligncenter.wp-caption, .aligncenter.is-resized {
    display: block;
    margin: 0.3125rem auto 0.938rem;
    text-align: center; }

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem; }
  .alignrightimg, .alignright.wp-caption, .alignright.is-resized {
    margin: 0.3125rem 0 1.563rem 1.563rem;
    float: right; }

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0; }
  .alignleftimg, .alignleft.wp-caption, .alignleft.is-resized {
    margin: 0.3125rem 1.563rem 1.563rem 0;
    float: left; }

.wp-caption {
  max-width: 100%;
  text-align: center; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin: 0; }
  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto; }
  .wp-caption p.wp-caption-text {
    font-size: 0.813rem;
    line-height: 1.125rem;
    margin: 0;
    padding: 0.625rem 0;
    text-align: left; }

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.5rem 2rem 1.5rem 2.5rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
  background-color: var(--rgba-primary-1); }
  @media only screen and (max-width: 991px) {
    .wp-block-quote.is-large,
    .wp-block-quote.is-style-large,
    blockquote.wp-block-quote,
    blockquote.wp-block-pullquote,
    blockquote.wp-block-pullquote.alignright,
    blockquote.wp-block-pullquote.alignleft,
    blockquote {
      padding: 1.25rem 1.25rem 1.25rem 2.25rem;
      font-size: 0.813rem; } }
  @media only screen and (max-width: 575px) {
    .wp-block-quote.is-large,
    .wp-block-quote.is-style-large,
    blockquote.wp-block-quote,
    blockquote.wp-block-pullquote,
    blockquote.wp-block-pullquote.alignright,
    blockquote.wp-block-pullquote.alignleft,
    blockquote {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; } }
  .wp-block-quote.is-large cite,
  .wp-block-quote.is-style-large cite,
  blockquote.wp-block-quote cite,
  blockquote.wp-block-pullquote cite,
  blockquote.wp-block-pullquote.alignright cite,
  blockquote.wp-block-pullquote.alignleft cite,
  blockquote cite {
    font-style: normal;
    position: relative;
    display: block;
    margin-top: -0.3125rem;
    font-weight: 600;
    color: var(--primary);
    line-height: 1.3;
    font-size: 14px; }
    .wp-block-quote.is-large cite:before,
    .wp-block-quote.is-style-large cite:before,
    blockquote.wp-block-quote cite:before,
    blockquote.wp-block-pullquote cite:before,
    blockquote.wp-block-pullquote.alignright cite:before,
    blockquote.wp-block-pullquote.alignleft cite:before,
    blockquote cite:before {
      content: "";
      margin-right: 0.625rem;
      width: 15px;
      height: 2px;
      background: var(--primary);
      display: inline-block;
      vertical-align: middle; }
  .wp-block-quote.is-large b, .wp-block-quote.is-large strong, .wp-block-quote.is-large .strong,
  .wp-block-quote.is-style-large b,
  .wp-block-quote.is-style-large strong,
  .wp-block-quote.is-style-large .strong,
  blockquote.wp-block-quote b,
  blockquote.wp-block-quote strong,
  blockquote.wp-block-quote .strong,
  blockquote.wp-block-pullquote b,
  blockquote.wp-block-pullquote strong,
  blockquote.wp-block-pullquote .strong,
  blockquote.wp-block-pullquote.alignright b,
  blockquote.wp-block-pullquote.alignright strong,
  blockquote.wp-block-pullquote.alignright .strong,
  blockquote.wp-block-pullquote.alignleft b,
  blockquote.wp-block-pullquote.alignleft strong,
  blockquote.wp-block-pullquote.alignleft .strong,
  blockquote b,
  blockquote strong,
  blockquote .strong {
    color: inherit; }
  .wp-block-quote.is-large:after,
  .wp-block-quote.is-style-large:after,
  blockquote.wp-block-quote:after,
  blockquote.wp-block-pullquote:after,
  blockquote.wp-block-pullquote.alignright:after,
  blockquote.wp-block-pullquote.alignleft:after,
  blockquote:after {
    content: "";
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--primary);
    position: absolute;
    box-shadow: 0 0 30px 0 var(--rgba-primary-2); }
  .wp-block-quote.is-large:before,
  .wp-block-quote.is-style-large:before,
  blockquote.wp-block-quote:before,
  blockquote.wp-block-pullquote:before,
  blockquote.wp-block-pullquote.alignright:before,
  blockquote.wp-block-pullquote.alignleft:before,
  blockquote:before {
    content: "";
    z-index: -1;
    background-size: cover;
    left: 20px;
    background-image: var(--quote);
    background-position: center right;
    width: 90px;
    padding: 0;
    opacity: 0.1;
    background-repeat: no-repeat;
    height: 90px;
    top: 0px;
    position: absolute; }
  .wp-block-quote.is-large.wp-block-pullquote.alignleft,
  .wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
  blockquote.wp-block-quote.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignleft {
    margin: 0rem 1.563rem 1.563rem 0rem; }
  .wp-block-quote.is-large.wp-block-pullquote.alignright,
  .wp-block-quote.is-style-large.wp-block-pullquote.alignright,
  blockquote.wp-block-quote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignright {
    margin: 0rem 0 1.563rem 1.563rem; }
  .wp-block-quote.is-large.style-1,
  .wp-block-quote.is-style-large.style-1,
  blockquote.wp-block-quote.style-1,
  blockquote.wp-block-pullquote.style-1,
  blockquote.wp-block-pullquote.alignright.style-1,
  blockquote.wp-block-pullquote.alignleft.style-1,
  blockquote.style-1 {
    background: #fff;
    color: var(--primary);
    padding: 25px 40px;
    background-color: var(--rgba-primary-1); }
    .wp-block-quote.is-large.style-1 p,
    .wp-block-quote.is-style-large.style-1 p,
    blockquote.wp-block-quote.style-1 p,
    blockquote.wp-block-pullquote.style-1 p,
    blockquote.wp-block-pullquote.alignright.style-1 p,
    blockquote.wp-block-pullquote.alignleft.style-1 p,
    blockquote.style-1 p {
      font-size: 24px;
      line-height: 1.5;
      color: var(--title); }
    .wp-block-quote.is-large.style-1:before,
    .wp-block-quote.is-style-large.style-1:before,
    blockquote.wp-block-quote.style-1:before,
    blockquote.wp-block-pullquote.style-1:before,
    blockquote.wp-block-pullquote.alignright.style-1:before,
    blockquote.wp-block-pullquote.alignleft.style-1:before,
    blockquote.style-1:before {
      left: 20px;
      background-size: contain;
      width: 80px;
      top: 0;
      transform: rotate(180deg);
      background-image: var(--quote-2); }
    .wp-block-quote.is-large.style-1 cite,
    .wp-block-quote.is-style-large.style-1 cite,
    blockquote.wp-block-quote.style-1 cite,
    blockquote.wp-block-pullquote.style-1 cite,
    blockquote.wp-block-pullquote.alignright.style-1 cite,
    blockquote.wp-block-pullquote.alignleft.style-1 cite,
    blockquote.style-1 cite {
      color: var(--primary); }
      .wp-block-quote.is-large.style-1 cite:before,
      .wp-block-quote.is-style-large.style-1 cite:before,
      blockquote.wp-block-quote.style-1 cite:before,
      blockquote.wp-block-pullquote.style-1 cite:before,
      blockquote.wp-block-pullquote.alignright.style-1 cite:before,
      blockquote.wp-block-pullquote.alignleft.style-1 cite:before,
      blockquote.style-1 cite:before {
        background: var(--primary); }

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0; }

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3; }

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-style: italic; }
  .dz-card.blog-single .dz-post-text blockquote p cite,
  .dz-page-text blockquote p cite,
  blockquote p cite {
    margin-top: 20px; }
  @media only screen and (max-width: 991px) {
    .dz-card.blog-single .dz-post-text blockquote p,
    .dz-page-text blockquote p,
    blockquote p {
      font-size: 1.125rem; } }
  @media only screen and (max-width: 575px) {
    .dz-card.blog-single .dz-post-text blockquote p,
    .dz-page-text blockquote p,
    blockquote p {
      font-size: 1rem;
      margin-bottom: 1rem; } }

.dz-page-text {
  padding-bottom: 30px !important; }

.dz-card.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  margin-bottom: 1.5rem; }

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto; }

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-card.blog-single,
.dz-card.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem; }

.dz-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%; }

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem; }

.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
  padding: 0; }

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%; }

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul {
  list-style: none;
  margin-bottom: 1.875rem; }

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 1.25rem; }
  .dz-page-text > ol ol,
  .dz-page-text > ol ul,
  .dz-page-text > ul ol,
  .dz-page-text > ul ul,
  .dz-card.blog-single .dz-post-text > ol ol,
  .dz-card.blog-single .dz-post-text > ol ul,
  .dz-card.blog-single .dz-post-text > ul ol,
  .dz-card.blog-single .dz-post-text > ul ul,
  .dz-card.blog-single .dz-post-text.text > ol ol,
  .dz-card.blog-single .dz-post-text.text > ol ul,
  .dz-card.blog-single .dz-post-text.text > ul ol,
  .dz-card.blog-single .dz-post-text.text > ul ul {
    padding-left: 1.563rem; }

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit; }

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none; }

.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem; }

.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before {
  content: none; }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0; }

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem; }

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem/2));
  margin-right: calc(-12.5rem - (0.313rem/2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial; }

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem/2));
  margin-right: calc(-6.25rem - (0.313rem/2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial; }

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box; }

.dz-page-text h1, .dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h2, .dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h3, .dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h4, .dz-page-text .h4, .dz-page-text .comment-reply-title,
.dz-page-text .wp-block-search .wp-block-search__label, .wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .comment-reply-title,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .comment-reply-title,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
  margin-bottom: 0.75rem;
  font-weight: 600; }

.dz-page-text h5, .dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem; }

.dz-page-text h6, .dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem; }

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem; }

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single .dz-card.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7; }

.wp-block-cover,
.wp-block-cover-image {
  color: #fff; }

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0; }

.wp-block-quote.has-text-align-right {
  border-right: 0; }

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative; }

.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #212529;
  left: -0.938rem;
  position: absolute;
  top: 1.063rem;
  border-radius: 4px; }

.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before {
  content: none; }

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
  margin-bottom: 1.65rem; }

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0; }
  .paginate-links > a,
  .paginate-links > span {
    margin: 0 0 0 0.625rem;
    position: relative;
    border: 0rem solid #6cc000;
    color: #777777;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 50px;
    min-width: 50px;
    height: 50px;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    background: #f3f4f6;
    border-radius: var(--border-radius-base); }
  .paginate-links .current {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0px 5px 12px var(--rgba-primary-4); }

.wp-block-columns {
  margin-bottom: 0; }

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0; }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative; }

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start; } }

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto; } }

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%; }

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline; }

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover; } }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem); }

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0; }

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%; }

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important; }
  .alignwide .alignleft img {
    width: 100%; }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none; } }

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem); }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem); }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem); }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem); }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem); }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem); }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0; } }

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0; }

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%; }

.wp-block-image.alignfullwide img {
  border-radius: 0; }

.wp-block-image img {
  border-radius: 0.625rem; }

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem; }

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%; }

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex; }

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center; }

/* .dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
    margin-left:0;
    margin-right:0;
    width: 100%;
    max-width: initial;
} */
.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem; }

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0; }

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem; }
  @media only screen and (max-width: 575px) {
    .dz-post-text table,
    .dz-page-text table,
    .wp-block-table {
      font-size: 0.875rem; } }

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  margin-top: 0.625rem; }

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa; }

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0; }
  @media only screen and (max-width: 575px) {
    .dz-post-text td,
    .dz-post-text th,
    .dz-page-text td,
    .dz-page-text th,
    .wp-block-table td,
    .wp-block-table th {
      padding: 0.5rem 0.5rem; } }

.wp-block-media-text {
  margin-bottom: 30px; }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0 1.875rem; }

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important; }

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0; }
  .wp-block-pullquote.alignright, .wp-block-pullquote.alignleft {
    padding: 0;
    border-top: 0;
    border-bottom: 0; }
    .wp-block-pullquote.alignright blockquote, .wp-block-pullquote.alignleft blockquote {
      margin: 0; }

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff; }

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif; }

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset; }

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem; }

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem; }

.wp-block-quote.is-style-large cite:before {
  display: none; }

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem; }

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none; }

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary); }

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da; }

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s; }

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none; }

.wp-block-tag-cloud a {
  position: relative;
  border: 2px solid;
  padding: 10px 20px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: inherit;
  line-height: 1.4;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base); }
  .wp-block-tag-cloud a:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: #fff; }

.wp-block-latest-comments {
  padding: 0 !important; }
  .wp-block-latest-comments .avatar {
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 3.4375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
    margin-left: 4.375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    color: inherit;
    font-size: 1.063rem;
    line-height: 1.7;
    margin-bottom: 0; }
    .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
      color: inherit;
      box-shadow: none;
      text-decoration: none; }
  .wp-block-latest-comments img + article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 4.375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
    display: block;
    width: 100%;
    color: #9fa1a4;
    font-size: 0.813rem;
    font-weight: 400; }
  .wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    margin-bottom: 0.625rem !important; }
  .wp-block-latest-comments li {
    padding: 0 !important;
    border-bottom: 0.0625rem solid #eee;
    padding-bottom: 0.625rem !important;
    margin-bottom: 0.625rem; }
    .wp-block-latest-comments li:before {
      content: none !important; }

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem; }

.comment-content.dz-page-text {
  max-width: 100%; }
  .comment-content.dz-page-text > :last-child {
    margin-bottom: 0; }

.wp-block-rss {
  padding: 0 !important; }
  .wp-block-rss .wp-block-rss__item {
    padding: 0.3125rem 0 !important;
    border-bottom: 0.0625rem solid #eee; }
    .wp-block-rss .wp-block-rss__item:before {
      content: none !important; }
    .wp-block-rss .wp-block-rss__item a {
      font-family: 'Poppins', sans-serif;
      font-size: 1.125rem;
      box-shadow: unset !important;
      font-weight: 600;
      color: var(--title);
      text-decoration: none; }

@media only screen and (max-width: 75rem) {
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem/2));
    margin-right: calc(-6.25rem - (0.625rem/2));
    width: calc(100% + 12.5rem + 0.625rem); } }

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important; }
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 1rem; } }
  @media only screen and (max-width: 61.9375rem) and (max-width: 575px) {
    .dz-page-text,
    .dz-page-text ul li,
    .dz-page-text ol li,
    .dz-page-text p,
    .dz-card.blog-single .dz-post-text,
    .dz-card.blog-single .dz-post-text ul li,
    .dz-card.blog-single .dz-post-text ol li,
    .dz-card.blog-single .dz-post-text p {
      font-size: 0.875rem; } }

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem; }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important; }
  .wp-block-media-text {
    display: block; }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem; }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0; } }

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0; }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important; }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important; } }

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0; }
  .post-footer .dz-meta .tag-list {
    padding-bottom: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: capitalize;
    display: inline; }
  .post-footer .dz-meta ul li {
    margin-right: 0.3125rem;
    padding: 0;
    display: inline-block;
    color: #333333;
    font-weight: 500;
    font-size: 0.938rem;
    font-style: italic; }
  .post-footer .post-tag a {
    text-transform: capitalize;
    font-size: 0.938rem;
    color: #999;
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 0.875rem;
    font-weight: 500; }

.share-post li {
  display: inline-block; }

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #212529; }

.extra-blog {
  margin-bottom: 60px; }

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px; } }

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800; }

@media only screen and (max-width: 991px) {
  .default-form {
    margin-bottom: 40px; } }

@media only screen and (max-width: 767px) {
  .default-form {
    margin-bottom: 30px; } }

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .comments-area .comment-list {
      margin-bottom: 40px; } }
  .comments-area .comment-list .default-form {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
    .comments-area .comment-list .default-form small, .comments-area .comment-list .default-form .small {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px; }
      .comments-area .comment-list .default-form small a, .comments-area .comment-list .default-form .small a {
        color: #e10000; }
  .comments-area .comment-list .dz-page-text {
    padding-bottom: 0 !important; }
  .comments-area .comment-list > .comment .comment-body {
    position: relative;
    padding: 0 0 20px 130px;
    margin-bottom: 30px;
    min-height: 125px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .comments-area .comment-list > .comment .comment-body {
        padding: 0 0 25px 75px; } }
    @media only screen and (max-width: 575px) {
      .comments-area .comment-list > .comment .comment-body {
        padding: 0 0 20px 75px; } }
    .comments-area .comment-list > .comment .comment-body .comment-author {
      position: absolute;
      left: 0;
      height: 105px;
      width: 105px;
      border-radius: var(--border-radius-base); }
      .comments-area .comment-list > .comment .comment-body .comment-author img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: var(--border-radius-base); }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body .comment-author {
          height: 60px;
          width: 60px; } }
    .comments-area .comment-list > .comment .comment-body .comment-info .title {
      margin-bottom: 8px; }
      .comments-area .comment-list > .comment .comment-body .comment-info .title span {
        font-size: 15px; }
    .comments-area .comment-list > .comment .comment-body .comment-info .fn {
      font-size: 18px;
      line-height: 1.3;
      color: var(--title);
      font-weight: 600;
      font-style: normal;
      margin-bottom: 3px;
      display: block;
      font-family: var(--font-family-title); }
    .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 12px;
      background: var(--primary);
      color: #fff;
      padding: 6px 10px 4px 10px;
      display: inline-block;
      line-height: 1.3;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      position: absolute;
      right: 0;
      top: 0; }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link span {
        display: inline-block; }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link:hover {
        background: var(--primary-hover); }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
        margin-right: 8px;
        font-size: 14px; }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
          position: unset; } }
  .comments-area .comment-list > .comment:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
  .comments-area .comment-list > .comment > .children {
    padding-left: 50px; }
    @media only screen and (max-width: 991px) {
      .comments-area .comment-list > .comment > .children {
        padding-left: 30px; } }
    @media only screen and (max-width: 575px) {
      .comments-area .comment-list > .comment > .children {
        padding-left: 15px; } }
  .comments-area .comment-list .comment-form {
    margin-bottom: 40px; }

.comment-reply-title a {
  font-size: 14px;
  font-weight: 400; }

.comment-respond small, .comment-respond .small {
  font-size: 14px;
  margin-left: 7px; }
  .comment-respond small a, .comment-respond .small a {
    color: #ef060f; }

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  .comment-respond .comment-form p {
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%; }
    @media only screen and (max-width: 575px) {
      .comment-respond .comment-form p {
        margin-bottom: 15px; } }
    .comment-respond .comment-form p label {
      display: none; }
    .comment-respond .comment-form p.comment-form-author input,
    .comment-respond .comment-form p.comment-form-email input,
    .comment-respond .comment-form p.comment-form-url input,
    .comment-respond .comment-form p textarea {
      height: 60px;
      font-size: 15px;
      font-weight: 400;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      background: transparent;
      width: 100%; }
      .comment-respond .comment-form p.comment-form-author input:focus, .comment-respond .comment-form p.comment-form-author input:active,
      .comment-respond .comment-form p.comment-form-email input:focus,
      .comment-respond .comment-form p.comment-form-email input:active,
      .comment-respond .comment-form p.comment-form-url input:focus,
      .comment-respond .comment-form p.comment-form-url input:active,
      .comment-respond .comment-form p textarea:focus,
      .comment-respond .comment-form p textarea:active {
        background: #fff;
        border-color: var(--primary); }
      @media only screen and (max-width: 575px) {
        .comment-respond .comment-form p.comment-form-author input,
        .comment-respond .comment-form p.comment-form-email input,
        .comment-respond .comment-form p.comment-form-url input,
        .comment-respond .comment-form p textarea {
          font-size: 16px; } }
    .comment-respond .comment-form p textarea {
      height: 120px !important; }
    .comment-respond .comment-form p.comment-form-url {
      width: 100%; }
  .comment-respond .comment-form .comment-form-comment {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important; } }

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden; }

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box; }
  .gallery .gallery-item img {
    float: left;
    padding: 0 0rem;
    width: 100%;
    border: none !important; }

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both; }

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none; }

.gallery-columns-2 .gallery-item {
  max-width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%; }

.gallery-columns-4 .gallery-item {
  max-width: 25%; }

.gallery-columns-5 .gallery-item {
  max-width: 20%; }

.gallery-columns-6 .gallery-item {
  max-width: 16.66%; }

.gallery-columns-7 .gallery-item {
  max-width: 14.28%; }

.gallery-columns-8 .gallery-item {
  max-width: 12.5%; }

.gallery-columns-9 .gallery-item {
  max-width: 11.11%; }

.gallery-icon img {
  margin: 0 auto; }

.post-password-form {
  position: relative;
  clear: both; }
  .post-password-form label {
    display: block;
    font-size: 1rem; }
  .post-password-form input[type="password"] {
    width: 100%;
    border: 0.0625rem solid #ebedf2;
    padding: 0.625rem 6.25rem 0.625rem 0.938rem;
    height: 2.8125rem;
    border: 0.0625rem solid #ced4da; }
  .post-password-form input[type="submit"] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.625rem 1.25rem;
    background: var(--primary);
    color: #FFF;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    outline: none;
    height: 2.8125rem; }
    .post-password-form input[type="submit"]:hover {
      background: var(--primary-hover); }

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset; } }

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px; }
  .blog-post-banner .dz-title {
    color: #fff;
    font-size: 32px;
    margin-bottom: 20px; }
  .blog-post-banner .dz-meta {
    color: #fff; }
    .blog-post-banner .dz-meta ul {
      display: flex;
      opacity: 0.7; }
      .blog-post-banner .dz-meta ul li {
        margin-right: 20px; }
        .blog-post-banner .dz-meta ul li a {
          color: #fff; }

.post-outside {
  margin-top: -120px; }

.single-post .main-bar {
  border-bottom: 1px solid #eee; }

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important; }

.dz-share-post {
  border-top: 1px solid #cccccc;
  border-top-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0; }
  .dz-share-post .post-tags a {
    position: relative;
    border: 1px solid;
    padding: 5px 12px;
    display: inline-block;
    margin: 3px;
    font-size: 13px;
    color: inherit;
    line-height: 1.4;
    border-color: rgba(175, 163, 163, 0.4);
    border-radius: var(--border-radius-base);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .dz-share-post .post-tags a span {
      display: inline-block; }
    .dz-share-post .post-tags a:hover {
      box-shadow: 0px 5px 12px var(--rgba-primary-4);
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
      color: #fff; }
  @media only screen and (max-width: 767px) {
    .dz-share-post {
      display: block; }
      .dz-share-post .post-tags {
        margin-bottom: 20px; } }
  @media only screen and (max-width: 575px) {
    .dz-share-post .post-tags a {
      margin-right: 5px; } }

#comment-list:empty + .paginate-links {
  margin-top: -30px; }

.post-swiper,
.post-swiper-thumb,
.service-swiper {
  position: relative; }
  .post-swiper .btn-prev,
  .post-swiper .btn-next,
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .next-post-swiper-btn,
  .post-swiper .prev-service-swiper-btn,
  .post-swiper .next-service-swiper-btn,
  .post-swiper-thumb .btn-prev,
  .post-swiper-thumb .btn-next,
  .post-swiper-thumb .prev-post-swiper-btn,
  .post-swiper-thumb .next-post-swiper-btn,
  .post-swiper-thumb .prev-service-swiper-btn,
  .post-swiper-thumb .next-service-swiper-btn,
  .service-swiper .btn-prev,
  .service-swiper .btn-next,
  .service-swiper .prev-post-swiper-btn,
  .service-swiper .next-post-swiper-btn,
  .service-swiper .prev-service-swiper-btn,
  .service-swiper .next-service-swiper-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 0;
    text-align: center;
    font-size: 20px;
    background-color: #fff;
    color: var(--primary);
    z-index: 1;
    margin: 0 10px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .post-swiper .btn-prev:hover,
    .post-swiper .btn-next:hover,
    .post-swiper .prev-post-swiper-btn:hover,
    .post-swiper .next-post-swiper-btn:hover,
    .post-swiper .prev-service-swiper-btn:hover,
    .post-swiper .next-service-swiper-btn:hover,
    .post-swiper-thumb .btn-prev:hover,
    .post-swiper-thumb .btn-next:hover,
    .post-swiper-thumb .prev-post-swiper-btn:hover,
    .post-swiper-thumb .next-post-swiper-btn:hover,
    .post-swiper-thumb .prev-service-swiper-btn:hover,
    .post-swiper-thumb .next-service-swiper-btn:hover,
    .service-swiper .btn-prev:hover,
    .service-swiper .btn-next:hover,
    .service-swiper .prev-post-swiper-btn:hover,
    .service-swiper .next-post-swiper-btn:hover,
    .service-swiper .prev-service-swiper-btn:hover,
    .service-swiper .next-service-swiper-btn:hover {
      background-color: var(--primary);
      color: #fff; }
  .post-swiper .btn-prev,
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .prev-service-swiper-btn,
  .post-swiper-thumb .btn-prev,
  .post-swiper-thumb .prev-post-swiper-btn,
  .post-swiper-thumb .prev-service-swiper-btn,
  .service-swiper .btn-prev,
  .service-swiper .prev-post-swiper-btn,
  .service-swiper .prev-service-swiper-btn {
    left: 0; }
  .post-swiper .btn-next,
  .post-swiper .next-post-swiper-btn,
  .post-swiper .next-service-swiper-btn,
  .post-swiper-thumb .btn-next,
  .post-swiper-thumb .next-post-swiper-btn,
  .post-swiper-thumb .next-service-swiper-btn,
  .service-swiper .btn-next,
  .service-swiper .next-post-swiper-btn,
  .service-swiper .next-service-swiper-btn {
    right: 0; }
  .post-swiper .swiper-slide img,
  .post-swiper-thumb .swiper-slide img,
  .service-swiper .swiper-slide img {
    width: 100%; }

.service-swiper img {
  border-radius: var(--border-radius-base); }

.blog-single .dz-media + .dz-info {
  padding: 30px 0 0; }

.author-box {
  padding: 35px;
  background: #f3f4f6;
  border-radius: var(--border-radius);
  margin-bottom: 60px; }
  .author-box .author-profile-info {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 575px) {
      .author-box .author-profile-info {
        display: block;
        text-align: center; } }
    .author-box .author-profile-info .author-profile-pic {
      width: 150px;
      height: 150px;
      overflow: hidden;
      min-width: 150px; }
      @media only screen and (max-width: 575px) {
        .author-box .author-profile-info .author-profile-pic {
          width: 80px;
          height: 80px;
          min-width: 80px;
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto; } }
      .author-box .author-profile-info .author-profile-pic img {
        width: 100%; }
    .author-box .author-profile-info .author-profile-content {
      padding-left: 25px; }
      @media only screen and (max-width: 575px) {
        .author-box .author-profile-info .author-profile-content {
          padding-left: 0;
          padding-right: 0; } }
      .author-box .author-profile-info .author-profile-content p {
        margin-bottom: 15px; }
      .author-box .author-profile-info .author-profile-content ul {
        padding: 0;
        margin: 0;
        margin-right: 5px;
        display: table;
        float: left; }
        @media only screen and (max-width: 575px) {
          .author-box .author-profile-info .author-profile-content ul {
            display: inline-block;
            float: none;
            margin-right: 0; } }
        .author-box .author-profile-info .author-profile-content ul li {
          padding: 0;
          margin: 0;
          margin-right: 10px;
          float: left; }
          @media only screen and (max-width: 575px) {
            .author-box .author-profile-info .author-profile-content ul li {
              margin-right: 5px;
              margin-left: 5px; } }
          .author-box .author-profile-info .author-profile-content ul li a {
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            background: var(--primary);
            color: #fff;
            vertical-align: middle;
            display: block;
            -webkit-transition: all 0.8s;
            -ms-transition: all 0.8s;
            transition: all 0.8s;
            border-radius: var(--border-radius-sm); }
            .author-box .author-profile-info .author-profile-content ul li a:hover {
              background: var(--primary-hover); }

#comment-list:empty + .paginate-links {
  margin-top: -30px; }

.bypostauthor {
  font-size: normal; }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }
  .screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; }

.wp-block-columns.alignfull,
.alignfull:not(.has-background) .wp-block-columns {
  padding-left: 1rem;
  padding-right: 1rem; }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

